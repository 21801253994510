import React, { type ReactElement, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Button, Menu, MenuItem } from '@mui/material'
import DropdownIcon from '../../../../images/icon_images/icon-dropdown.svg'
import EnglishFlagIcon from '../../../../images/icon_images/icon-flag-eng.svg'
import FrenchFlagIcon from '../../../../images/icon_images/icon-fr-flag.svg'
import GermanFlagIcon from '../../../../images/icon_images/ger-flag.svg'
import { appPatchRequest } from '../../../common/helper/httpRequests'
import { getTwoLetterLanguageBrowserCode } from '../../../common/helper/functions'
import { useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { createUserAuthSelector } from '../../../amplify/slices/authSlices'

interface ILanguageDropdown {
  textColor?: string
}
export const LanguageDropdown: React.FC<ILanguageDropdown> = ({
  textColor
}) => {
  const [languageButtonDrop, setLanguageButtonDrop] =
    useState<null | HTMLElement>(null)
  const openLanguage = Boolean(languageButtonDrop)
  const userData = useAppSelector(createUserAuthSelector)
  const showLanguageDropDown = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setLanguageButtonDrop(event.currentTarget)
  }
  const closeLanguageDropDown = (): void => {
    setLanguageButtonDrop(null)
  }
  const { i18n } = useTranslation()
  const [lang, setLang] = useState<string>(i18n.language)

  const getLang = (): ReactElement => {
    if (lang === 'en') {
      return (
        <>
          <img alt='' src={EnglishFlagIcon} className='pr-2' />
          ENG <img alt='' src={DropdownIcon} className='pl-2' />
        </>
      )
    } else if (lang === 'fr') {
      return (
        <>
          <img alt='' src={FrenchFlagIcon} className='pr-2' />
          FRE <img alt='' src={DropdownIcon} className='pl-2' />
        </>
      )
    } else if (lang === 'de') {
      return (
        <>
          <img alt='' src={GermanFlagIcon} className='pr-2 h-3' />
          GER <img alt='' src={DropdownIcon} className='pl-2' />
        </>
      )
    } else {
      return (
        <>
          <img alt='' src={EnglishFlagIcon} className='pr-2' />
          ENG <img alt='' src={DropdownIcon} className='pl-2' />
        </>
      )
    }
  }
  const getLangMobile = (): ReactElement => {
    if (lang === 'en') {
      return (
        <>
          <img alt='' src={EnglishFlagIcon} className='pr-1' />
          ENG <img alt='' src={DropdownIcon} className='pl-1' />
        </>
      )
    } else if (lang === 'fr') {
      return (
        <>
          <img alt='' src={FrenchFlagIcon} className='pr-1' />
          FRE <img alt='' src={DropdownIcon} className='pl-1' />
        </>
      )
    } else if (lang === 'de') {
      return (
        <>
          <img alt='' src={GermanFlagIcon} className='pr-1 h-3' />
          GER <img alt='' src={DropdownIcon} className='pl-1' />
        </>
      )
    } else {
      return (
        <>
          <img alt='' src={EnglishFlagIcon} className='pr-1' />
          ENG <img alt='' src={DropdownIcon} className='pl-1' />
        </>
      )
    }
  }
  const onClickLanguageChange = async (e: any): Promise<void> => {
    const language = e
    setLang(e)
    i18n
      .changeLanguage(language)
      .then(() => {})
      .catch(() => {})
    setLanguageButtonDrop(null)
    localStorage.setItem('njangee_lang', language)
    localStorage.setItem('njangee_has_set_lang', 'true')
    await appPatchRequest(`/users/${userData.user?.id as string}/update-lang`, {
      lang: getTwoLetterLanguageBrowserCode()
    })
  }
  useEffect(() => {
    if (localStorage.getItem('njangee_lang') === null) {
      localStorage.setItem('njangee_lang', 'en')
      setLang('en')
      i18n
        .changeLanguage('en')
        .then(() => {})
        .catch(() => {})
    } else {
      i18n
        .changeLanguage(localStorage.getItem('njangee_lang') as string)
        .then()
        .catch(() => {})
      setLang(localStorage.getItem('njangee_lang') as string)
    }
  }, [])
  return (
    <Stack direction='row' className='flex items-center nav-border-right'>
      <Button
        className={`text-white ${textColor as string} !hidden md:!flex`}
        id='language-button'
        variant='text'
        onClick={showLanguageDropDown}
        aria-controls={openLanguage ? 'language-drop' : undefined}
        aria-haspopup='true'
        aria-expanded={openLanguage ? 'true' : undefined}
      >
        {getLang()}
      </Button>
      <Button
        className={`!flex md:!hidden ${textColor as string}`}
        id='language-button'
        variant='text'
        onClick={showLanguageDropDown}
        aria-controls={openLanguage ? 'language-drop' : undefined}
        aria-haspopup='true'
        aria-expanded={openLanguage ? 'true' : undefined}
      >
        {getLangMobile()}
      </Button>
      <Menu
        id='language-drop'
        anchorEl={languageButtonDrop}
        open={openLanguage}
        MenuListProps={{
          'aria-labelledby': 'language-button'
        }}
        onClose={closeLanguageDropDown}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem
          onClick={() => {
            void onClickLanguageChange('en')
          }}
        >
          <img alt='' src={EnglishFlagIcon} className='pr-2' />
          ENG
        </MenuItem>
        <MenuItem
          onClick={() => {
            void onClickLanguageChange('fr')
          }}
        >
          <img alt='' src={FrenchFlagIcon} className='pr-2' />
          FRE
        </MenuItem>
        <MenuItem
          onClick={() => {
            void onClickLanguageChange('de')
          }}
        >
          <img alt='' src={GermanFlagIcon} className='pr-2 h-3' />
          GER
        </MenuItem>
      </Menu>
    </Stack>
  )
}
