import { Box, Button, Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { OnboardingSetupBox } from './setupBox'
import { ChevronLeft } from '@mui/icons-material'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import { useForm } from 'react-hook-form'
import { createCountriesSelector } from '../../common/slices/countrySlice'
import {
  createIdVerificationSelector,
  setIdVerification
} from '../../user/settings/slices/idVerificationSlice'

interface IStepValue {
  nextStep: (arg: number) => void
  skipOnboarding: () => void
}
export const HomeAddressSetUp: React.FC<IStepValue> = ({
  nextStep,
  skipOnboarding
}) => {
  const dispatch = useAppDispatch()
  const countries = useAppSelector(createCountriesSelector)
  const idVerification = useAppSelector(createIdVerificationSelector)
  const { t } = useTranslation(['dashboard'])
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const formValidation = {
    countryOfBirth: {
      required: true
    },
    stateOfBirth: {
      required: true
    },
    cityOfBirth: {
      required: true
    },
    currentCountry: {
      required: true
    },
    currentCity: {
      required: true
    },
    address: {
      required: true
    },
    gender: {
      required: true
    }
  }

  const onChangeHandler = (value: string, e: any): void => {
    switch (value) {
      case 'countryOfBirth':
        dispatch(
          setIdVerification({
            ...idVerification,
            countryOfBirth: e.target.value as string
          })
        )
        break
      case 'currentCountry':
        dispatch(
          setIdVerification({
            ...idVerification,
            currentCountry: e.target.value as string
          })
        )
        break

      case 'stateOfBirth':
        dispatch(
          setIdVerification({
            ...idVerification,
            stateOfBirth: e.target.value as string
          })
        )
        break
      case 'cityOfBirth':
        dispatch(
          setIdVerification({
            ...idVerification,
            cityOfBirth: e.target.value as string
          })
        )
        break
      case 'currentCity':
        dispatch(
          setIdVerification({
            ...idVerification,
            currentCity: e.target.value as string
          })
        )
        break
      case 'postcode':
        dispatch(
          setIdVerification({
            ...idVerification,
            post_code: e.target.value as number
          })
        )
        break
      case 'address':
        dispatch(
          setIdVerification({
            ...idVerification,
            address: e.target.value as string
          })
        )
        break
      case 'gender':
        dispatch(
          setIdVerification({
            ...idVerification,
            gender: e.target.value as string
          })
        )
        break
    }
  }

  return (
    <OnboardingSetupBox>
      <Stack
        direction='row'
        className='w-full flex-wrap items-center rounded-t-2xl bg-primary-bold gap-3 py-4 px-5 sm:px-7 lg:px-10 text-th-white'
      >
        <p>{t('onboarding.lets_setup')}</p>
        <p className='ml-auto'>
          {t('onboarding.step')} 2 {t('general.common.of')} 3
        </p>
      </Stack>
      <Stack
        direction='column'
        className='w-full h-full my-5 px-5 sm:px-10 lg:px-24'
      >
        <form
          onSubmit={handleSubmit(() => {
            nextStep(3)
          })}
        >
          <Stack
            direction='column'
            className='w-full border-b border-secondary-xlight py-1'
          >
            <h4>{t('onboarding.home_address')}</h4>
            <p className='text-secondary'>
              {t('onboarding.home_address_caption')}
            </p>
          </Stack>
          <Stack className='w-full mt-3 mb-6'>
            {/* <Stack className='w-full'>
                            <SelectCountryInput label='Country' anyValidation={register('country', formValidation.country)} countryValue='' onChange={(e: any) => { onChangeHandler('country', e) }} />
                            {errors.country?.type === 'required' && (
                                <small className='text-danger'>Country is required</small>
                            )}
                        </Stack> */}
            <Stack className='w-full gap-2'>
              <p className='font-semibold'>
                {t('onboarding.country_of_birth')}*
              </p>
              <select
                {...register('countryOfBirth', formValidation.countryOfBirth)}
                value={`${idVerification.countryOfBirth}`}
                onChange={(e: any) => {
                  onChangeHandler('countryOfBirth', e)
                }}
              >
                <option value=''>{t('settings.select_country')}</option>
                {countries.countries.length === 0 ? (
                  <option value='' className=''>
                    {t('onboarding.loading')}...
                  </option>
                ) : (
                  countries?.countries?.map((country: any) => {
                    return (
                      <option
                        key={country.id as string}
                        value={`${country.countryCode as string}`}
                        className=''
                      >
                        {country.name}
                      </option>
                    )
                  })
                )}
              </select>
              {errors.countryOfBirth?.type === 'required' && (
                <small className='text-danger'>
                  {t('onboarding.please_enter_country_of_birth')}
                </small>
              )}
            </Stack>

            <Stack
              direction='row'
              display='grid'
              className='grid-cols-1 md:grid-cols-2 w-full gap-4 my-3'
            >
              <Stack direction='column' className='w-full gap-2'>
                <p className='font-semibold'>
                  {t('general.common.region')} / {t('general.common.state')}*
                </p>
                <Stack className='w-full'>
                  <Box sx={{ width: '100%' }}>
                    <input
                      type='text'
                      {...register('stateOfBirth', formValidation.stateOfBirth)}
                      value={idVerification.stateOfBirth}
                      maxLength={100}
                      onChange={(e: any) => {
                        onChangeHandler('stateOfBirth', e)
                      }}
                      className={`!w-full text-input ${
                        errors.stateOfBirth?.type === 'required'
                          ? 'input-error'
                          : ''
                      }`}
                    />
                  </Box>
                  {errors.stateOfBirth?.type === 'required' && (
                    <small className='text-danger'>
                      {t('onboarding.please_enter_state_of_birth')}
                    </small>
                  )}
                </Stack>
              </Stack>

              <Stack className='w-full gap-2'>
                <p className='font-semibold'>
                  {t('onboarding.city_of_birth')}*
                </p>
                <input
                  type='text'
                  {...register('cityOfBirth', formValidation.cityOfBirth)}
                  value={idVerification.cityOfBirth}
                  maxLength={100}
                  onChange={(e: any) => {
                    onChangeHandler('cityOfBirth', e)
                  }}
                  className={`!w-full text-input ${
                    errors.cityOfBirth?.type === 'required' ? 'input-error' : ''
                  }`}
                />
                {errors.cityOfBirth?.type === 'required' && (
                  <small className='text-danger'>
                    {t('onboarding.please_enter_city_of_birth')}d
                  </small>
                )}
              </Stack>
            </Stack>
            <Stack
              direction='row'
              display='grid'
              className='grid-cols-1 md:grid-cols-2 w-full gap-4 my-3'
            >
              <Stack className='w-full gap-2'>
                <p className='font-semibold'>
                  {t('onboarding.current_country')}*
                </p>
                <select
                  {...register('currentCountry', formValidation.currentCountry)}
                  value={`${idVerification.currentCountry}`}
                  onChange={(e: any) => {
                    onChangeHandler('currentCountry', e)
                  }}
                >
                  <option value=''>{t('settings.select_country')}</option>
                  {countries.countries.length === 0 ? (
                    <option value='' className=''>
                      {t('onboarding.loading')}...
                    </option>
                  ) : (
                    countries?.countries?.map((country: any) => {
                      return (
                        <option
                          key={country.id as string}
                          value={`${country.countryCode as string}`}
                          className=''
                        >
                          {country.name}
                        </option>
                      )
                    })
                  )}
                </select>
                {errors.currentCountry?.type === 'required' && (
                  <small className='text-danger'>
                    {t('onboarding.please_enter_current_country')}
                  </small>
                )}
              </Stack>
              <Stack className='w-full gap-2'>
                <p className='font-semibold'>{t('onboarding.current_city')}*</p>
                <input
                  type='text'
                  {...register('currentCity', formValidation.currentCity)}
                  value={idVerification.currentCity}
                  maxLength={100}
                  onChange={(e: any) => {
                    onChangeHandler('currentCity', e)
                  }}
                  className={`!w-full text-input ${
                    errors.currentCity?.type === 'required' ? 'input-error' : ''
                  }`}
                />
                {errors.currentCity?.type === 'required' && (
                  <small className='text-danger'>
                    {t('onboarding.please_enter_current_city')}
                  </small>
                )}
              </Stack>
            </Stack>
            <Stack className='w-full gap-2 mt-2'>
              <p className='font-semibold'>{t('general.common.post_code')}</p>
              <input
                type='number'
                {...register('postcode')}
                // max={6}
                // maxLength={6}
                value={idVerification.post_code}
                onChange={(e: any) => {
                  onChangeHandler('postcode', e)
                }}
                className='!w-full text-input'
              />
            </Stack>
            <Stack className='w-full mt-4'>
              <p className='font-semibold'>{t('general.common.gender')}</p>
              <select
                value={`${idVerification.gender}`}
                {...register('gender', formValidation.gender)}
                onChange={(e: any) => {
                  onChangeHandler('gender', e)
                }}
              >
                <option value=''>{t('settings.select_gender')}</option>
                <option value='MALE'>{t('settings.male')}</option>
                <option value='FEMALE'>{t('settings.female')}</option>
              </select>
              {errors.gender?.type === 'required' && (
                <small className='text-danger'>
                  {t('onboarding.gender_is_required')}
                </small>
              )}
            </Stack>
            <Stack className='w-full gap-2 mt-5'>
              <p className='font-semibold'>{t('general.common.address')}*</p>
              <textarea
                rows={4}
                className={`w-full ${
                  errors.address?.type === 'required' ? '!input-error' : ''
                }`}
                value={idVerification.address}
                maxLength={1000}
                {...register('address', formValidation.address)}
                onChange={(e: any) => {
                  onChangeHandler('address', e)
                }}
              ></textarea>
              {errors.address?.type === 'required' && (
                <small className='text-danger'>
                  {t('onboarding.address_is_required')}
                </small>
              )}
            </Stack>
          </Stack>
          <Stack direction='row' className='w-full items-center mt-auto mb-2'>
            <Stack>
              <Button
                variant='text'
                type='button'
                className='!normal-case flex items-center text-primary font-extra !text-base'
                onClick={() => {
                  nextStep(1)
                }}
              >
                <ChevronLeft /> {t('general.common.back')}
              </Button>
            </Stack>
            <Stack direction='row' className='items-center gap-5 ml-auto'>
              <button
                type='button'
                className='border-0 bg-none font-semibold hover:underline !hidden sm:!block'
                onClick={() => {
                  skipOnboarding()
                }}
              >
                {t('onboarding.skip_for_now')}
              </button>
              <button className='btn btn-primary flex gap-3 justify-center'>
                {t('registration.continue')}
              </button>
            </Stack>
          </Stack>
          <Stack
            direction='row'
            className='!flex sm:!hidden items-center gap-3 mx-auto mt-2'
          >
            <button
              className='border-0 bg-none font-semibold hover:underline'
              onClick={() => {
                skipOnboarding()
              }}
            >
              {t('onboarding.skip_for_now')}
            </button>
          </Stack>
        </form>
      </Stack>
    </OnboardingSetupBox>
  )
}
