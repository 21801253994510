import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'
import { type AppMetaData } from '../../../common/globals/types'
import { APP_INITIAL_METADATA } from '../../../common/globals/initials'

export interface GetSentInvitations {
    sentInvitations: any[]
    meta: AppMetaData
}

const initialState: GetSentInvitations = {
    sentInvitations: [],
    meta: APP_INITIAL_METADATA
}

export const createGetSentInvitationsSlice = createSlice({
    name: 'get_sent_invitations',
    initialState,
    reducers: {
        setGetSentInvitations: (state, action: PayloadAction<GetSentInvitations>) => {
            return {
                ...state,
                sentInvitations: action.payload.sentInvitations,
                meta: action.payload.meta
            }
        }
    }
})

export const { setGetSentInvitations } = createGetSentInvitationsSlice.actions

export const createGetSentInvitationsSelector = (state: RootState): GetSentInvitations => state.createGetSentInvitationsReducer
export default createGetSentInvitationsSlice.reducer
