import { Auth } from 'aws-amplify'
import axios from 'axios'

const api = process.env.REACT_APP_API as string
export const inviteGroupMembersFunc = async (
  members: string
): Promise<any> => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const jwtToken = user.signInUserSession.idToken.jwtToken
    const session = await axios.get(`${api}/session`, {
      headers: {
        Authorization: jwtToken
      }
    })
    const userId = session.data.data.id
    const invData = await axios.post(
      `${api}/users/${userId as string}/invitations`,
      { invitations: members },
      {
        headers: {
          Authorization: jwtToken
        }
      }
    )
    console.log(invData)
    return invData
  } catch (error) {
    console.error('error: ', error)
  }
}
