import React, {
  type ReactElement,
  useState,
  useEffect,
  type Dispatch,
  type SetStateAction
} from 'react'
import { Button, Stack } from '@mui/material'
import { FileUploadOutlined, ChevronLeft } from '@mui/icons-material'
import { OnboardingSetupBox } from './setupBox'
import { useTranslation } from 'react-i18next'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  setIdVerification,
  createIdVerificationSelector
} from '../../user/settings/slices/idVerificationSlice'
import { hasValidImage } from '../../common/helper/hasValidImage'
import {
  getFileUrlTypeAndDisplaySized,
  getFileTypeAndDisplay
} from '../../common/helper/functions'
import {
  formatToReadableDateYMD,
  getTodayDate
} from '../../common/helper/convertToDate'
import { createUserAuthSelector } from '../../amplify/slices/authSlices'

interface IStepValue {
  nextStep: (arg: number) => void
  skipOnboarding: () => void
}
export const DocumentSetup: React.FC<IStepValue> = ({
  nextStep,
  skipOnboarding
}) => {
  const idVerification = useAppSelector(createIdVerificationSelector)
  const userData = useAppSelector(createUserAuthSelector).user
  const dispatch = useAppDispatch()
  const [frontIdPreview, setFrontIdPreview] = useState('')
  const [backIdPreview, setBackIdPreview] = useState('')
  const [passportPreview, setPassportPreview] = useState('')
  const [isTrue] = useState(true)
  const { t } = useTranslation(['dashboard'])

  const handleDocumentType = (dType: string): void => {
    dispatch(setIdVerification({ ...idVerification, document_type: dType }))
  }
  const handleExpirationDate = (event: any): void => {
    dispatch(
      setIdVerification({
        ...idVerification,
        expiration_date: event.target.value
      })
    )
  }
  const handleIssuedDate = (event: any): void => {
    dispatch(
      setIdVerification({ ...idVerification, issued_date: event.target.value })
    )
  }
  const handleIdNumber = (event: any): void => {
    dispatch(
      setIdVerification({ ...idVerification, idNumber: event.target.value })
    )
  }
  const handleFrontId = (event: any): void => {
    dispatch(
      setIdVerification({ ...idVerification, front_id: event.target.files[0] })
    )
    setFrontIdPreview(URL.createObjectURL(event.target.files[0]))
  }
  const handleBackId = (event: any): void => {
    dispatch(
      setIdVerification({ ...idVerification, back_id: event.target.files[0] })
    )
    setBackIdPreview(URL.createObjectURL(event.target.files[0]))
  }
  const handlePassport = (event: any): void => {
    dispatch(
      setIdVerification({ ...idVerification, passport: event.target.files[0] })
    )
    setPassportPreview(URL.createObjectURL(event.target.files[0]))
  }

  const removeFrontId = (): void => {
    setFrontIdPreview('')
    dispatch(setIdVerification({ ...idVerification, front_id: new Blob() }))
    isFormEmpty()
  }

  const removeBackId = (): void => {
    setBackIdPreview('')
    dispatch(setIdVerification({ ...idVerification, back_id: new Blob() }))
    isFormEmpty()
  }

  const handleSave = (): void => {
    dispatch(setIdVerification({ ...idVerification, submitted: isTrue }))
    nextStep(2)
  }

  const isFormEmpty = (): boolean => {
    const isIdVerificationValid =
      idVerification.document_type !== '' &&
      idVerification.expiration_date !== '' &&
      idVerification.issued_date !== '' &&
      idVerification.idNumber !== ''
    const isValidIds =
      hasValidImage(idVerification.front_id) !== null &&
      hasValidImage(idVerification.back_id) !== null
    if (idVerification.isEditMode) {
      return false
    } else if (
      isIdVerificationValid &&
      (isValidIds || hasValidImage(idVerification.passport) !== null)
    ) {
      return false
    }

    return true
  }

  const idShotType = (): string => {
    if (idVerification.document_type.toUpperCase() === 'ID') {
      return t('settings.national_id')
    } else if (
      idVerification.document_type.toUpperCase() === 'RESIDENCE_PERMIT'
    ) {
      return t('settings.residence_permit')
    } else {
      return t('settings.driving_licence')
    }
  }
  const documentSetupType = (): ReactElement => {
    if (
      idVerification.document_type.toUpperCase() === 'ID' ||
      idVerification.document_type.toUpperCase() === 'RESIDENCE_PERMIT' ||
      idVerification.document_type.toUpperCase() === 'DRIVING_LICENSE'
    ) {
      return (
        <>
          <Stack direction='column' className='mt-3'>
            <p>
              {t('settings.take_a_bright_photo')} {idShotType()}{' '}
              {t('settings.making_sure_all_corners')}
            </p>

            <Stack
              direction='row'
              className='w-full gap-4 !grid grid-cols-1 sm:grid-cols-2 mt-2'
            >
              <Stack
                direction='column'
                className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
              >
                {frontIdPreview === '' && idVerification.frontIdUrl === '' ? (
                  <>
                    <p className='text-th-black font-extra'>
                      {t('settings.front_of_document')}
                    </p>
                    <small className='text-secondary mb-2'>
                      {t('settings.accepted_files')}: jpeg, png{' '}
                      {t('general.common.and')} pdf
                    </small>
                    <Button variant='text' component='label' className=''>
                      <p className='py-2 px-5 font-extra text-primary-bold border border-secondary-light rounded !normal-case !bg-slate-200 flex items-center gap-1'>
                        <FileUploadOutlined /> {t('settings.upload_file')}
                      </p>
                      <input
                        type='file'
                        hidden
                        accept='image/jpeg,image/jpg,image/png,application/pdf'
                        onChange={(e: any) => {
                          handleFrontId(e)
                        }}
                      />
                    </Button>
                  </>
                ) : (
                  <Stack className='h-full gap-1 mt-2'>
                    {frontIdPreview !== ''
                      ? getFileTypeAndDisplay(
                          frontIdPreview,
                          idVerification.front_id,
                          idVerification.document_type
                        )
                      : getFileUrlTypeAndDisplaySized(
                          idVerification.frontIdUrl,
                          idVerification.document_type
                        )}
                    <Stack direction='row' className='w-fit mx-auto gap-4'>
                      <Button
                        variant='text'
                        component='label'
                        className='!bg-none hover:!bg-none'
                      >
                        <p className='font-semibold text-success !normal-case small'>
                          {t('settings.change_image')}
                        </p>
                        <input
                          type='file'
                          hidden
                          accept='image/jpeg,image/jpg,image/png,application/pdf'
                          onChange={(e: any) => {
                            handleFrontId(e)
                          }}
                        />
                      </Button>
                      <button
                        onClick={removeFrontId}
                        className='text-black-2 font-semibold small'
                      >
                        {t('general.common.remove')}
                      </button>
                    </Stack>
                  </Stack>
                )}
              </Stack>

              <Stack
                direction='column'
                className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
              >
                {backIdPreview === '' && idVerification.backIdUrl === '' ? (
                  <>
                    <p className='text-th-black font-extra'>
                      {t('settings.back_of_document')}
                    </p>
                    <small className='text-secondary mb-2'>
                      {t('settings.accepted_files')}: jpeg, png{' '}
                      {t('general.common.and')} pdf
                    </small>
                    <Button variant='text' component='label' className=''>
                      <p className='py-2 px-5 font-extra text-primary-bold border border-secondary-light rounded !normal-case !bg-slate-200 flex items-center gap-1'>
                        <FileUploadOutlined /> {t('settings.upload_file')}
                      </p>
                      <input
                        type='file'
                        hidden
                        accept='image/jpeg,image/jpg,image/png,application/pdf'
                        onChange={(e: any) => {
                          handleBackId(e)
                        }}
                      />
                    </Button>
                  </>
                ) : (
                  <Stack className='h-full gap-1 mt-2'>
                    {/* {getFileTypeAndDisplay(
                      backIdPreview === ''
                        ? idVerification.frontIdUrl
                        : backIdPreview,
                      idVerification.back_id,
                      idVerification.document_type
                    )} */}
                    {backIdPreview !== ''
                      ? getFileTypeAndDisplay(
                          backIdPreview,
                          idVerification.back_id,
                          idVerification.document_type
                        )
                      : getFileUrlTypeAndDisplaySized(
                          idVerification.backIdUrl,
                          idVerification.document_type
                        )}
                    <Stack direction='row' className='w-fit mx-auto gap-4'>
                      <Button
                        variant='text'
                        component='label'
                        className='!bg-none hover:!bg-none'
                      >
                        <p className='font-semibold text-success !normal-case small'>
                          {t('settings.change_image')}
                        </p>
                        <input
                          type='file'
                          hidden
                          accept='image/jpeg,image/jpg,image/png,application/pdf'
                          onChange={(e: any) => {
                            handleBackId(e)
                          }}
                        />
                      </Button>
                      <button
                        onClick={removeBackId}
                        className='text-black-2 font-semibold small'
                      >
                        {t('general.common.remove')}
                      </button>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack className='w-full gap-2 mt-3'>
              <p className='text-primary-bold font-semibold'>
                {t('settings.issued_date')} *
              </p>
              <input
                type='date'
                name='issued-date'
                placeholder='DD / MM / YY'
                value={formatToReadableDateYMD(idVerification.issued_date, '-')}
                max={getTodayDate()}
                onChange={(e: any) => {
                  handleIssuedDate(e)
                }}
              />
            </Stack>
            <Stack className='w-full gap-2 mt-3'>
              <p className='text-primary-bold font-semibold'>
                {t('settings.expiration_date')} *
              </p>
              <input
                type='date'
                name='expiration-date'
                min={getTodayDate()}
                placeholder='DD / MM / YY'
                value={formatToReadableDateYMD(
                  idVerification.expiration_date,
                  '-'
                )}
                onChange={(e: any) => {
                  handleExpirationDate(e)
                }}
              />
            </Stack>
          </Stack>
        </>
      )
    } else if (idVerification.document_type.toUpperCase() === 'PASSPORT') {
      return (
        <>
          <Stack direction='column' className='mt-3'>
            <p>{t('settings.take_a_bright_photo_passport')}</p>

            <Stack
              direction='row'
              className='w-full gap-4 !grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-2'
            >
              {passportPreview === '' && idVerification.frontIdUrl === '' ? (
                <span></span>
              ) : (
                <Stack
                  direction='column'
                  className='!flex sm:!hidden w-fit items-center mx-auto justify-center col-span-1 bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
                >
                  {passportPreview !== ''
                    ? getFileTypeAndDisplay(
                        passportPreview,
                        idVerification.passport,
                        idVerification.document_type
                      )
                    : getFileUrlTypeAndDisplaySized(
                        idVerification.frontIdUrl,
                        idVerification.document_type
                      )}
                </Stack>
              )}
              <Stack
                className={`col-span-1 ${
                  passportPreview === '' && idVerification.frontIdUrl === ''
                    ? 'lg:col-span-3'
                    : 'lg:col-span-2'
                } w-full`}
              >
                <Stack
                  direction='column'
                  className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4 mb-3'
                >
                  <p className='text-th-black font-extra'>
                    {t('settings.upload_passport')}
                  </p>
                  <small className='text-secondary mb-2'>
                    {t('settings.upload_passport_caption')}{' '}
                    {t('settings.accepted_files')}: jpeg, png{' '}
                    {t('general.common.and')} pdf
                  </small>
                  <Button variant='text' component='label' className=''>
                    <p className='py-2 px-5 font-extra text-primary-bold border border-secondary-light rounded !normal-case !bg-slate-200 flex items-center gap-1'>
                      <FileUploadOutlined /> {t('settings.upload_file')}
                    </p>
                    <input
                      type='file'
                      hidden
                      accept='image/jpeg,image/jpg,image/png,application/pdf'
                      onChange={(e: any) => {
                        handlePassport(e)
                      }}
                    />
                  </Button>
                </Stack>
                <Stack className='w-full gap-2 mt-3'>
                  <p className='text-primary-bold font-semibold'>
                    {t('settings.issued_date')} *
                  </p>
                  <input
                    type='date'
                    name='issued-date'
                    placeholder='DD / MM / YY'
                    value={formatToReadableDateYMD(
                      idVerification.issued_date,
                      '-'
                    )}
                    max={getTodayDate()}
                    onChange={(e: any) => {
                      handleIssuedDate(e)
                    }}
                  />
                </Stack>
                <Stack className='w-full gap-2 mt-3'>
                  <p className='text-primary-bold font-semibold'>
                    {t('settings.expiration_date')} *
                  </p>
                  <input
                    type='date'
                    name='expiration-date'
                    min={getTodayDate()}
                    placeholder='DD / MM / YY'
                    value={formatToReadableDateYMD(
                      idVerification.expiration_date,
                      '-'
                    )}
                    onChange={(e: any) => {
                      handleExpirationDate(e)
                    }}
                  />
                </Stack>
              </Stack>

              {passportPreview === '' && idVerification.frontIdUrl === '' ? (
                <span></span>
              ) : (
                <Stack
                  direction='column'
                  className='!hidden w-full h-auto items-center mx-auto justify-center sm:!flex bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
                >
                  {passportPreview !== ''
                    ? getFileTypeAndDisplay(
                        passportPreview,
                        idVerification.passport,
                        idVerification.document_type
                      )
                    : getFileUrlTypeAndDisplaySized(
                        idVerification.frontIdUrl,
                        idVerification.document_type
                      )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </>
      )
    } else {
      return (
        <Stack className='w-full text-center my-4'>
          <h5 className='text-secondary-light'>
            {t('settings.not_available')}
          </h5>
        </Stack>
      )
    }
  }

  useEffect(() => {
    const setPreview = (
      image: Blob | MediaSource,
      setPreviewFunction: Dispatch<SetStateAction<string>>
    ): void => {
      if (image instanceof Blob && image.size !== 0 && image.type !== '') {
        setPreviewFunction(URL.createObjectURL(image) ?? '')
      }
    }
    setPreview(idVerification.front_id, setFrontIdPreview)
    setPreview(idVerification.back_id, setBackIdPreview)
    setPreview(idVerification.passport, setPassportPreview)
  }, [])
  return (
    <OnboardingSetupBox>
      <Stack
        direction='row'
        className='w-full flex-wrap items-center rounded-t-2xl bg-primary-bold gap-3 py-4 px-5 md:px-14 lg:px-20 text-th-white'
      >
        <p>{t('onboarding.lets_setup')}</p>
        <p className='ml-auto'>
          {t('onboarding.step')} 1 {t('general.common.of')} 3
        </p>
      </Stack>

      <Stack direction='column' className='w-full my-5 px-5 md:px-14 lg:px-20'>
        <Stack
          direction='column'
          className='w-full border-b border-secondary-xlight py-1'
        >
          <h4>{t('onboarding.verify_your_identity')}</h4>
          <p className='text-secondary'>
            {t('onboarding.almost_there')},{' '}
            <span className='font-bold'>{userData?.firstName ?? '-'}</span>!{' '}
            {t('onboarding.require_gov_id')}
          </p>
        </Stack>

        <Stack direction='column' className='w-full mt-4'>
          <p className='font-semibold text-primary-bold'>
            {t('settings.choose_doc_type')}
          </p>
          <Stack
            direction='row'
            className='w-full py-2 justify-between gap-5 flex-wrap'
          >
            <Stack direction='row' className='items-center gap-3'>
              <input
                type='radio'
                onChange={() => {
                  handleDocumentType('DRIVING_LICENSE')
                }}
                checked={
                  idVerification.document_type === 'DRIVING_LICENSE'
                    ? isTrue
                    : !isTrue
                }
                name='document-type'
                value='DRIVING_LICENSE'
              />
              <label
                htmlFor='DRIVING_LICENSE'
                className={`p ${
                  idVerification.document_type === 'DRIVING_LICENSE'
                    ? 'text-th-black'
                    : 'text-secondary'
                }`}
              >
                {t('settings.driving_licence')}
              </label>
            </Stack>

            <Stack direction='row' className='items-center gap-3'>
              <input
                type='radio'
                onChange={() => {
                  handleDocumentType('PASSPORT')
                }}
                checked={
                  idVerification.document_type === 'PASSPORT' ? isTrue : !isTrue
                }
                name='document-type'
                value='PASSPORT'
              />
              <label
                htmlFor='PASSPORT'
                className={`p ${
                  idVerification.document_type === 'PASSPORT'
                    ? 'text-th-black'
                    : 'text-secondary'
                }`}
              >
                {t('settings.passport')}
              </label>
            </Stack>

            <Stack direction='row' className='items-center gap-3'>
              <input
                type='radio'
                onChange={() => {
                  handleDocumentType('ID')
                }}
                checked={
                  idVerification.document_type === 'ID' ? isTrue : !isTrue
                }
                name='document-type'
                value='ID'
              />
              <label
                htmlFor='ID'
                className={`p ${
                  idVerification.document_type === 'ID'
                    ? 'text-th-black'
                    : 'text-secondary'
                }`}
              >
                {t('settings.id_card')}
              </label>
            </Stack>

            <Stack direction='row' className='items-center gap-3'>
              <input
                type='radio'
                onChange={() => {
                  handleDocumentType('RESIDENCE_PERMIT')
                }}
                checked={
                  idVerification.document_type === 'RESIDENCE_PERMIT'
                    ? isTrue
                    : !isTrue
                }
                name='document-type'
                value='RESIDENCE_PERMIT'
              />
              <label
                htmlFor='RESIDENCE_PERMIT'
                className={`p ${
                  idVerification.document_type === 'RESIDENCE_PERMIT'
                    ? 'text-th-black'
                    : 'text-secondary'
                }`}
              >
                {t('settings.residence_permit')}
              </label>
            </Stack>
          </Stack>

          {documentSetupType()}
          <Stack className='w-full gap-2 mt-3'>
            <p className='text-primary-bold font-semibold'>
              {t('general.common.document_number')}*
            </p>
            <input
              value={idVerification.idNumber}
              onChange={(e: any) => {
                handleIdNumber(e)
              }}
              className='!w-full text-input'
              maxLength={100}
            />
          </Stack>
        </Stack>

        <Stack direction='row' className='w-full items-center mt-10 mb-2'>
          <Stack>
            <Button
              variant='text'
              className='!normal-case flex items-center text-secondary font-extra !text-base'
              disabled={isTrue}
            >
              <ChevronLeft /> {t('general.common.back')}
            </Button>
          </Stack>
          <Stack direction='row' className='items-center gap-5 ml-auto'>
            <button
              className='border-0 bg-none font-semibold hover:underline !hidden sm:!block'
              onClick={() => {
                skipOnboarding()
              }}
            >
              {t('onboarding.skip_for_now')}
            </button>
            <button
              className='btn btn-primary'
              onClick={() => {
                handleSave()
              }}
              disabled={isFormEmpty()}
            >
              {t('general.common.next')}
            </button>
          </Stack>
        </Stack>
        <Stack
          direction='row'
          className='!flex sm:!hidden items-center gap-3 mx-auto mt-2'
        >
          <button
            className='border-0 bg-none font-semibold hover:underline'
            onClick={() => {
              skipOnboarding()
            }}
          >
            {t('onboarding.skip_for_now')}
          </button>
        </Stack>
      </Stack>
    </OnboardingSetupBox>
  )
}
