import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-input-2/lib/style.css'
import './index.css'
import './App.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './redux/app/create_njangee_store/store'
import './i18n'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
const initialOptions = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
  currency: process.env.REACT_APP_PAYPAL_CLIENT_CURRENCY as string,
  intent: process.env.REACT_APP_PAYPAL_CLIENT_INTENT as string
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PayPalScriptProvider options={initialOptions}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PayPalScriptProvider>
    </Provider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
