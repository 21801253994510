import React, { useEffect, useRef, useState } from 'react'
import { CloseOutlined, NotificationsOffOutlined } from '@mui/icons-material'
import { Box, Drawer, IconButton, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { appGetRequest } from '../../common/helper/httpRequests'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import { createUserAuthSelector } from '../../amplify/slices/authSlices'
import {
  createGetAppNotificationsSelector,
  setAppNotifications
} from './slices/appNotificationsSlice'
import { IsLoading } from '../../registration/fragments/isLoading'
import { DisplayNotifications } from './notification/displayNotification'
import { CircularLoadingProgress } from '../../registration/fragments/circularProgress'

interface INotificationSider {
  isDrawerOpen: boolean
  handleDrawerClose: () => void
}
export const NotificationSider: React.FC<INotificationSider> = ({
  isDrawerOpen,
  handleDrawerClose
}) => {
  const [notificationsType, setNotificationsType] = useState('all')
  const userData = useAppSelector(createUserAuthSelector).user
  const notifications = useAppSelector(createGetAppNotificationsSelector)
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['dashboard'])
  const [isFetching, setIsFetching] = useState(false)
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const activeNotification = (notif: string): string => {
    return notificationsType === notif
      ? 'bg-primary-2xlight border border-primary-xlight text-primary'
      : 'bg-light border border-main text-th-black'
  }
  const getNotifications = async (pageNum: number): Promise<void> => {
    const notifs = await appGetRequest(
      `/notification/${userData?.id as string}?page=${pageNum}`
    )
    if (notifs?.status === 200) {
      const newNotifs = notifs?.data.data.filter(
        (notif: Record<string, any>) =>
          !notifications.data.some(
            (existingNotif: Record<string, any>) =>
              existingNotif.id === notif.id
          )
      )
      dispatch(
        setAppNotifications({
          ...notifications,
          data: [...notifications.data, ...newNotifs],
          meta: notifs?.data?.meta,
          isLoading: false
        })
      )
    } else {
      dispatch(
        setAppNotifications({
          ...notifications,
          isLoading: false
        })
      )
    }
  }
  const handleScroll = (): void => {
    if (scrollRef.current === null) {
      return
    }

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current

    if (scrollHeight - scrollTop <= clientHeight + 100) {
      if (
        notifications.meta.page < notifications.meta.totalPages &&
        !isFetching
      ) {
        setIsFetching(true) // Prevent multiple calls
        dispatch(
          setAppNotifications({
            ...notifications,
            meta: { ...notifications.meta, page: notifications.meta.page + 1 }
          })
        )
        // Load notifications for the next page
        void getNotifications(notifications.meta.page + 1).finally(() => {
          setIsFetching(false)
        })
      }
    }
  }

  useEffect(() => {
    void getNotifications(notifications.meta.page)
  }, [])

  return (
    <div className='mobile-left-menu'>
      <Drawer
        anchor='right'
        className='dash-drawer'
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      >
        <Box
          width={{
            xs: '100%',
            sm: '400px',
            lg: '560px'
          }}
          // height='100%'
          sx={{
            height: 'calc(100% - 50px)', // Adjust based on your layout
            overflowY: 'auto',
            border: '1px solid lightgray'
          }}
          ref={scrollRef}
          onScroll={handleScroll}
          className='bg-white h-full !mb-0 p-4 md:p-7'
          role=''
        >
          <Stack direction='row' className='w-full border-b border-main gap-5'>
            <h4>{t('general.common.notifications')}</h4>
            <IconButton
              className='!p-0 !my-0 !mt-0 !ml-auto'
              onClick={handleDrawerClose}
            >
              <CloseOutlined fontSize='medium' className='text-primary-light' />
            </IconButton>
          </Stack>

          {notifications.isLoading ? (
            <IsLoading />
          ) : (
            <>
              {' '}
              <Stack
                direction='row'
                className='w-full items-center flex-wrap xl:flex-nowrap mt-3 gap-5'
              >
                <Stack
                  direction='row'
                  className={`items-start rounded-full py-1 px-6 cursor-pointer ${activeNotification(
                    'all'
                  )}`}
                  onClick={() => {
                    setNotificationsType('all')
                  }}
                >
                  <p
                    className={`pr-1 ${
                      notificationsType === 'all' ? 'font-extra' : ''
                    }`}
                  >
                    {t('general.common.all')}{' '}
                  </p>
                  <small
                    className={`text-9 ${
                      notificationsType === 'all'
                        ? 'font-extra text-primary-light'
                        : ''
                    }`}
                  >
                    25
                  </small>
                </Stack>

                <Stack
                  direction='row'
                  className={`items-start rounded-full py-1 px-6 cursor-pointer ${activeNotification(
                    'requests'
                  )}`}
                  onClick={() => {
                    setNotificationsType('requests')
                  }}
                >
                  <p
                    className={`pr-1 ${
                      notificationsType === 'requests' ? 'font-extra' : ''
                    }`}
                  >
                    {t('general.common.requests')}{' '}
                  </p>
                  <small
                    className={`text-9 ${
                      notificationsType === 'requests'
                        ? 'font-extra text-primary-light'
                        : ''
                    }`}
                  >
                    2
                  </small>
                </Stack>

                <Stack
                  direction='row'
                  className={`items-start rounded-full py-1 px-6 cursor-pointer ${activeNotification(
                    'payments'
                  )}`}
                  onClick={() => {
                    setNotificationsType('payments')
                  }}
                >
                  <p
                    className={`pr-1 ${
                      notificationsType === 'payments' ? 'font-extra' : ''
                    }`}
                  >
                    {t('general.common.payments')}{' '}
                  </p>
                  <small
                    className={`text-9 ${
                      notificationsType === 'payments'
                        ? 'font-extra text-primary-light'
                        : ''
                    }`}
                  >
                    3
                  </small>
                </Stack>

                <Stack
                  direction='row'
                  className={`items-start rounded-full py-1 px-6 cursor-pointer ${activeNotification(
                    'reminder'
                  )}`}
                  onClick={() => {
                    setNotificationsType('reminder')
                  }}
                >
                  <p
                    className={`pr-1 ${
                      notificationsType === 'reminder' ? 'font-extra' : ''
                    }`}
                  >
                    {t('general.common.reminder')}{' '}
                  </p>
                  <small
                    className={`text-9 ${
                      notificationsType === 'reminder'
                        ? 'font-extra text-primary-light'
                        : ''
                    }`}
                  >
                    4
                  </small>
                </Stack>
              </Stack>
              <br />
              <Stack className='w-full py-5 gap-4'>
                {notifications.data.length > 0 ? (
                  notifications.data.map((notif: any) => (
                    <DisplayNotifications
                      key={notif?.id}
                      notificationData={notif}
                    />
                  ))
                ) : (
                  <Stack className='text-center mt-10'>
                    <NotificationsOffOutlined className='!text-[40px] md:!text-[65px] mx-auto' />
                    <h6>{t('settings.no_notification_yet')}</h6>
                  </Stack>
                )}{' '}
              </Stack>
            </>
          )}
          {isFetching && (
            <Stack className='w-full items-center'>
              <CircularLoadingProgress color='var(--primary-xlight)' />
            </Stack>
          )}
        </Box>
      </Drawer>
    </div>
  )
}
