import React, { useEffect, type ReactElement } from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'

import { Home } from './components/web'
import { UserAuthentication } from './components/amplify/auth'
import { OnboardingSteps } from './components/registration/onboarding/steps'
import { Dashboard } from './components/user/dashboard/index'
import { UserGroups } from './components/user/groups'
import { UserGroupDetails } from './components/user/groups/show'
import { PlayNjangee } from './components/user/groups/playNjangee'
import { UserPayments } from './components/user/payments'
import { UserSettings } from './components/user/settings'
import { UserEditGroup } from './components/user/groups/edit'
import { HowItWorks } from './components/web/howItWorks'
import { Contact } from './components/web/contact'
import { Page404 } from './components/web/404'
import { PrivacyPolicy } from './components/web/privacyPolicy'
import { TermsOfService } from './components/web/termsOfService'
import { About } from './components/web/about'
// import { Team } from './components/web/team'
import { Features } from './components/web/features'
import { UserPaymentDetails } from './components/user/payments/show'
import './components/amplify/i18nAmplify'
import { Registration } from './components/registration/register'
import { Login } from './components/registration/login'
import { Amplify, Auth } from 'aws-amplify'
import { ChooseRegistration } from './components/registration/chooseRegistration'
import { ForgotPassword } from './components/registration/forgotPassword'
import {
  useAppDispatch,
  useAppSelector
} from './redux/app/create_njangee_store/hooks'
import {
  setCountries,
  createCountriesSelector
} from './components/common/slices/countrySlice'
import axios from 'axios'
import { ConfirmRegistration } from './components/registration/confirmRegistration'
import { ToastContainer } from 'react-toastify'
import { setUserAuth } from './components/amplify/slices/authSlices'
import { UserInvitations } from './components/user/invitations'
import { useTranslation } from 'react-i18next'
import { getTwoLetterLanguageBrowserCode } from './components/common/helper/functions'
import { appPatchRequest } from './components/common/helper/httpRequests'

Amplify.configure({
  Auth: {
    region: `${process.env.REACT_APP_REGION as string}`,
    userPoolId: `${process.env.REACT_APP_USER_POOL_ID as string}`,
    userPoolWebClientId: `${process.env.REACT_APP_WEB_CLIENT_ID as string}`,
    signUpVerificationMethod: `${
      process.env.REACT_APP_SIGNUP_VERIFICATION_METHOD as string
    }`

    // oauth: {
    //   domain:
    //     'appnjangeecomb4da9a14-b4da9a14-dev.auth.eu-west-1.amazoncognito.com',
    //   scope: [
    //     'phone',
    //     'email',
    //     'profile',
    //     'openid',
    //     'aws.cognito.signin.user.admin'
    //   ],
    //   redirectSignIn:
    //     'http://localhost:3000/,https://appnjangeecomb4da9a14.amplifyapp.com/',
    //   redirectSignOut:
    //     'http://localhost:3000/,https://appnjangeecomb4da9a14.amplifyapp.com/',
    //   responseType: 'code'
    // }
  }
})

function App (): ReactElement {
  const countries = useAppSelector(createCountriesSelector)
  const dispatch = useAppDispatch()
  const api = process.env.REACT_APP_API as string
  const { i18n } = useTranslation()

  const onClickLanguageChange = (e: any): void => {
    const language = e
    i18n
      .changeLanguage(language)
      .then(() => {})
      .catch(() => {})
    localStorage.setItem('njangee_lang', language)
  }

  const updateLanguage = async (userId: string): Promise<any> => {
    const lang = await appPatchRequest(`/users/${userId}/update-lang`, {
      lang: getTwoLetterLanguageBrowserCode()
    })
    return lang
  }
  useEffect(() => {
    // if(getTwoLetterLanguageBrowserCode() !== i18n.language) {
    //   const updateLanguage = await
    // }
    const getAuthUser = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const jwtToken = user.signInUserSession.idToken.jwtToken as string
        const session = await axios.get(`${api}/session`, {
          headers: {
            Authorization: jwtToken
          }
        })

        let userData = session.data.data
        if (
          userData?.language !== getTwoLetterLanguageBrowserCode() &&
          localStorage.getItem('njangee_has_set_lang') !== null &&
          localStorage.getItem('njangee_has_set_lang') !== undefined &&
          localStorage.getItem('njangee_has_set_lang') !== 'false'
        ) {
          const lang = await updateLanguage(userData?.id)
          userData = lang.data.data
          onClickLanguageChange(userData?.language ?? 'en')
        }
        dispatch(setUserAuth({ ...userData, user: userData }))
      } catch (error) {}
    }
    void getAuthUser()

    axios
      .get(`${process.env.REACT_APP_API as string}/country/?limit=1000`)
      .then((res) => {
        dispatch(
          setCountries({
            ...countries,
            countries: res.data.data
          })
        )
      })
      .catch(() => {})
  }, [])
  return (
    <div className='App'>
      {/* Web Routes */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/how-it-works' element={<HowItWorks />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-service' element={<TermsOfService />} />
        <Route path='/about' element={<About />} />
        {/* <Route path='/team' element={<Team />} /> */}
        <Route path='/features' element={<Features />} />
        <Route path='/*' element={<Page404 />} />

        <Route path='/register' element={<Registration />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/confirm-user' element={<ConfirmRegistration />} />

        {/* User Dashboard Routes */}
        <Route path='/choose-registration' element={<ChooseRegistration />} />
        <Route
          path='/user-onboarding'
          element={
            <UserAuthentication>
              <OnboardingSteps />
            </UserAuthentication>
          }
        />
        <Route
          path='/user'
          element={
            <UserAuthentication>
              {' '}
              <Dashboard />{' '}
            </UserAuthentication>
          }
        />
        <Route
          path='/user-groups'
          element={
            <UserAuthentication>
              <UserGroups />
            </UserAuthentication>
          }
        />
        <Route
          path='/user-groups/:id/show'
          element={
            <UserAuthentication>
              <UserGroupDetails />
            </UserAuthentication>
          }
        />
        <Route
          path='/user-groups/:id/edit'
          element={
            <UserAuthentication>
              <UserEditGroup />
            </UserAuthentication>
          }
        />
        <Route
          path='/user-groups/play-njangee'
          element={
            <UserAuthentication>
              <PlayNjangee />
            </UserAuthentication>
          }
        />
        <Route
          path='/user-payments'
          element={
            <UserAuthentication>
              <UserPayments />
            </UserAuthentication>
          }
        />
        <Route
          path='/user-payments/:id/show'
          element={
            <UserAuthentication>
              <UserPaymentDetails />
            </UserAuthentication>
          }
        />
        <Route
          path='/user-settings'
          element={
            <UserAuthentication>
              <UserSettings />
            </UserAuthentication>
          }
        />
        <Route
          path='/user-invitations'
          element={
            <UserAuthentication>
              <UserInvitations />
            </UserAuthentication>
          }
        />
      </Routes>

      <ToastContainer position='top-right' />
    </div>
  )
}

export default App
