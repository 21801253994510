import React, { useEffect, type ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Switch
} from '@mui/material'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import {
  setNotifications,
  createNotificationsSelector
} from '../slices/notificationsSlice'
import { Auth } from 'aws-amplify'
import axios from 'axios'

export const NotificationSettings: React.FC = () => {
  const { t } = useTranslation(['dashboard'])
  const notificationTableData = useAppSelector(
    createNotificationsSelector
  ).notifications
  const dispatch = useAppDispatch()
  const [userId, setUserId] = useState(0)

  const tableRow = function (
    id: number,
    title: string,
    hint: string,
    // emailNotifOn: boolean,
    pushNotifOn: boolean,
    smsNotifOn: boolean
  ): ReactElement {
    return (
      <>
        <TableCell>
          <Stack className=''>
            <p className='font-extra'>{title}</p>
            <p className='text-secondary'>{hint}</p>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction='row' className='items-center gap-1'>
            <Switch
              checked
              disabled
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateNotificationState(id, e.target.checked, 'email')
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />{' '}
            <p className='text-secondary'>{t('settings.yes')}</p>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction='row' className='items-center gap-1'>
            <Switch
              checked={pushNotifOn}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateNotificationState(id, e.target.checked, 'push')
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />{' '}
            {pushNotifOn ? (
              <p className='text-secondary'>{t('settings.yes')}</p>
            ) : (
              <p className='text-secondary-light'>{t('settings.no')}</p>
            )}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction='row' className='items-center gap-1'>
            <Switch
              checked={smsNotifOn}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateNotificationState(id, e.target.checked, 'sms')
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />{' '}
            {smsNotifOn ? (
              <p className='text-secondary'>{t('settings.yes')}</p>
            ) : (
              <p className='text-secondary-light'>{t('settings.no')}</p>
            )}
          </Stack>
        </TableCell>
      </>
    )
  }

  const renderNotifs = (): ReactElement => {
    return (
      <>
        {notificationTableData?.map((notif: any) => (
          <TableRow
            key={notif.notificationSettingTypeId}
            className='!border-b-white'
          >
            {tableRow(
              notif?.notificationSettingTypeId,
              notif?.name,
              notif.descriptionTranslationString,
              notif.push,
              notif.sms
            )}
          </TableRow>
        ))}
      </>
    )
  }

  const updateNotificationState = (
    id: number,
    newState: boolean,
    type: string
  ): void => {
    const updatedNotifications = notificationTableData.map((notif: any) => {
      if (notif.notificationSettingTypeId === id) {
        if (type === 'email') {
          return { ...notif, email: newState }
        } else if (type === 'push') {
          return { ...notif, push: newState }
        } else {
          return { ...notif, sms: newState }
        }
      }
      return notif
    })

    dispatch(setNotifications({ notifications: updatedNotifications }))
    const newNotifData = updatedNotifications.find((notif: any) => {
      return notif.notificationSettingTypeId === id
    })
    void updateNotifDBValue(newNotifData, newState, type).then().catch()
  }

  const updateNotifDBValue = async (
    data: any,
    value: boolean,
    type: string
  ): Promise<void> => {
    try {
      // const typeToUpdate = type === 'email' ? 'email' : 'push'
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      await axios.patch(
        `${process.env.REACT_APP_API as string}/users/${userId}/settings`,
        {
          notificationSettingTypeId: data.notificationSettingTypeId,
          push: data.push,
          email: data.email,
          sms: data.sms
        },
        {
          headers: {
            Authorization: token
          }
        }
      )
    } catch (error) {}
  }

  useEffect(() => {
    const getNotifications = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const token = user.signInUserSession.idToken.jwtToken
        const session = await axios.get(
          `${process.env.REACT_APP_API as string}/session`,
          {
            headers: {
              Authorization: token
            }
          }
        )
        const userId = session.data.data.id
        setUserId(userId)
        const notifications = await axios.get(
          `${process.env.REACT_APP_API as string}/users/${
            userId as string
          }/settings`,
          {
            headers: {
              Authorization: token
            }
          }
        )
        dispatch(setNotifications({ notifications: notifications.data.data }))
      } catch (error) {}
    }

    void getNotifications()
  }, [dispatch])

  return (
    <Stack className='w-full px-4 md:px-12 my-4'>
      <Stack className='text-black-2'>
        <h6 className='uppercase text-black leading-5'>
          {t('settings.notification_settings')}
        </h6>
        <p>{t('settings.notification_settings_caption')}</p>
      </Stack>

      <Stack className='w-full my-5'>
        <Stack className='w-full md:w-11/12 bg-white border border-main rounded px-5 py-6'>
          <TableContainer className='!bg-white'>
            <Table sx={{ minWidth: '510px' }} className='!bg-white'>
              <TableHead>
                <TableRow className='border-b border-main'>
                  <TableCell>
                    <p className='text-secondary'>
                      {t('settings.get_email_push_notif')}
                    </p>
                  </TableCell>
                  <TableCell>
                    <Stack className='w-full'>
                      <p className='font-semibold'>
                        {t('settings.email_notif')}
                      </p>
                      <small className='text-secondary'>
                        {t('settings.email_notif_caption')}
                      </small>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack className='w-full'>
                      <p className='font-semibold'>
                        {t('settings.push_notif')}
                      </p>
                      <small className='text-secondary'>
                        {t('settings.push_notif_caption')}
                      </small>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack className='w-full'>
                      <p className='font-semibold'>{t('settings.sms_notif')}</p>
                      <small className='text-secondary'>
                        {t('settings.sms_notif_caption')}
                      </small>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderNotifs()}</TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </Stack>
  )
}
