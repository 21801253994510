import { configureStore } from '@reduxjs/toolkit'
import createGroupReducer from '../../../components/user/njangeeGroupModals/slices/newGroupSlices'
import showModalReducer from '../../../components/user/njangeeGroupModals/slices/showModalSlice'
import invitedMembersReducer from '../../../components/user/njangeeGroupModals/slices/invitedMembersSlice'
import createUserProfileReducer from '../../../components/user/settings/slices/editProfileSlice'
import createChangePasswordReducer from '../../../components/user/settings/slices/changePasswordSlice'
import createIdVerificationReducer from '../../../components/user/settings/slices/idVerificationSlice'
import createPaymentMethodsReducer from '../../../components/user/settings/slices/paymentMethodsSlice'
import createNotificationsReducer from '../../../components/user/settings/slices/notificationsSlice'
import createChooseRegistrationReducer from '../../../components/registration/slices/chooseRegistrationSlice'
import createUserAuthReducer from '../../../components/amplify/slices/authSlices'
import createLoggedInUserReducer from '../../../components/amplify/slices/loggedInUserInfo'
import createCountriesReducer from '../../../components/common/slices/countrySlice'
import createStatesReducer from '../../../components/common/slices/stateSlice'
import createCitiesReducer from '../../../components/common/slices/citySlice'
import createGetGroupsReducer from '../../../components/user/groups/slices/getGroupSlice'
import groupDetailReducer from '../../../components/user/groups/slices/groupDetail'
import createGetInvitationsReducer from '../../../components/user/invitations/slices/getInvitationsSlice'
import createDashboardStateReducer from '../../../components/user/dashboard/slices/dashboardSlice'
import createGetCreatedGroupsReducer from '../../../components/user/dashboard/slices/createdGroupsSlice'
import createGetJoinedGroupsReducer from '../../../components/user/dashboard/slices/joinedGroupsSlice'
import createGetSentInvitationsReducer from '../../../components/user/invitations/slices/getSentInvitationsSlice'
import createPlayNjangeeReducer from '../../../components/user/groups/slices/playNjangeeSlice'
import createGetPaymentsReducer from '../../../components/user/payments/slices/paymentsSlice'
import createGetAppNotificationsReducer from '../../../components/user/common/slices/appNotificationsSlice'

export const store = configureStore({
  reducer: {
    showModalReducer,
    createGroupReducer,
    invitedMembersReducer,
    createUserProfileReducer,
    createChangePasswordReducer,
    createIdVerificationReducer,
    createPaymentMethodsReducer,
    createNotificationsReducer,
    createChooseRegistrationReducer,
    createUserAuthReducer,
    createLoggedInUserReducer,
    createCountriesReducer,
    createStatesReducer,
    createCitiesReducer,
    createGetGroupsReducer,
    groupDetailReducer,
    createGetInvitationsReducer,
    createDashboardStateReducer,
    createGetCreatedGroupsReducer,
    createGetJoinedGroupsReducer,
    createGetSentInvitationsReducer,
    createPlayNjangeeReducer,
    createGetPaymentsReducer,
    createGetAppNotificationsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
