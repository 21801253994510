import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../redux/app/create_njangee_store/store'

export interface UserAuth {
  user: any
  isLoggingOut: boolean
}

const initialState: UserAuth = {
  user: {},
  isLoggingOut: false
}

export const createUserAuthSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAuth: (state, action: PayloadAction<UserAuth>) => {
      return {
        ...state,
        user: action.payload.user,
        isLoggingOut: action.payload.isLoggingOut
      }
    }
  }
})

export const { setUserAuth } = createUserAuthSlice.actions

export const createUserAuthSelector = (state: RootState): UserAuth =>
  state.createUserAuthReducer
export default createUserAuthSlice.reducer
