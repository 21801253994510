import React, { useState, type ReactElement } from 'react'
import {
  ClearOutlined,
  DoneOutlined,
  NotificationsOutlined
} from '@mui/icons-material'
import { Stack } from '@mui/material'
import { SanitizedNotificationContent } from '../cleanHtml/cleanNotifContent'
import { Link, useNavigate } from 'react-router-dom'
import { convertToMonthInWords } from '../../../common/helper/convertToDate'
import { useTranslation } from 'react-i18next'
import { appPatchRequest } from '../../../common/helper/httpRequests'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import {
  createGetAppNotificationsSelector,
  setAppNotifications
} from '../slices/appNotificationsSlice'
import { NotificationFunctions } from './functions'

interface IDisplayNotifications {
  notificationData: Record<string, any>
}

export const DisplayNotifications: React.FC<IDisplayNotifications> = ({
  notificationData
}) => {
  const { t } = useTranslation(['dashboard'])
  const notifications = useAppSelector(createGetAppNotificationsSelector)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isUnRead, setIsUnRead] = useState(notificationData?.readAt === null)
  const readNotification = async (): Promise<void> => {
    try {
      navigate(
        NotificationFunctions.getUrlGroupString(
          notificationData?.customContent,
          notificationData?.notificationTemplate?.notificationSettingType?.slug
        )
      )
      if (isUnRead) {
        const markAsRead = await appPatchRequest(
          `/notification/read/${notificationData?.id as number}`
        )

        const updatedNotifications = notifications.data.map((notif) =>
          notif.id === notificationData?.id
            ? { ...notif, ...markAsRead.data.data }
            : notif
        )

        dispatch(
          setAppNotifications({
            ...notifications,
            data: updatedNotifications,
            meta: {
              ...notifications.meta,
              unreadCount: notifications.meta.unreadCount - 1
            }
          })
        )
        setIsUnRead(false)
      }
    } catch (error) {}
  }

  const diplayNotif = (): ReactElement => {
    return (
      <Stack
        className={`w-full ${
          isUnRead ? 'bg-slate-100 py-3 px-2 rounded-md !cursor-pointer' : ''
        }`}
      >
        {notificationData?.notificationTemplate?.eventId ===
        'invitation_sent' ? (
          <Stack direction='row' className='w-full items-start gap-1 flex-wrap'>
            <Stack
              direction='row'
              className='w-full items-start gap-3 md:gap-5'
              onClick={() => {
                void readNotification()
              }}
            >
              <NotificationsOutlined className='!text-[30px] md:!text-[40px]' />
              {/* <img src={Image2} alt='' className='left-img-1' /> */}
              <Stack className=''>
                <SanitizedNotificationContent
                  content={
                    notificationData.deliveries?.filter(
                      (delivery: any) => delivery?.channel === 'INAPP'
                    )[0]?.content
                  }
                />
                {/* <Link to='/' className='text-button-primary'>
                view payment history
              </Link> */}
                <small className='text-secondary font-semibold mt-1'>
                  {convertToMonthInWords(
                    localStorage.getItem('njangee_lang') ?? 'en',
                    notificationData?.createdAt
                  )}
                </small>
              </Stack>
            </Stack>
            <Stack
              direction='row'
              className='items-center gap-3 w-fit ml-auto mb-3'
            >
              <Link
                to='/user-invitations'
                className='btn-accept-invitation btn-primary'
              >
                <DoneOutlined className='mr-1 text-overlined !w-4 !h-4' />{' '}
                {t('general.common.accept')}
              </Link>
              <Link to='/user-invitations' className='btn-reject-invitation'>
                <ClearOutlined className='mr-1 text-overlined !w-4 !h-4' />{' '}
                {t('general.common.reject')}
              </Link>
            </Stack>
          </Stack>
        ) : (
          <Stack
            key={notificationData?.id}
            direction='row'
            className='w-full items-start gap-3 md:gap-5'
            onClick={() => {
              void readNotification()
            }}
          >
            {/* <img src={Image1} alt='' className='left-img-1' />
             */}
            <NotificationsOutlined className='!text-[30px] md:!text-[40px]' />

            <Stack className=''>
              <SanitizedNotificationContent
                content={
                  notificationData.deliveries?.filter(
                    (delivery: any) => delivery?.channel === 'INAPP'
                  )[0]?.content
                }
              />
              {/* <Link to='/' className='text-button-primary'>
                view payment history
              </Link> */}
              <small className='text-secondary font-semibold mt-1'>
                {convertToMonthInWords(
                  localStorage.getItem('njangee_lang') ?? 'en',
                  notificationData?.createdAt
                )}
              </small>
            </Stack>
          </Stack>
        )}
      </Stack>
    )
  }

  return (
    <>
      {diplayNotif()}
      {/* <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
        <img src={Image4} alt='' className='left-img-1' />
        <Stack className=''>
          <p className='leading-5'>
            <span className='font-extra'>Ophelia Miller </span>
            Replied to your
            <span className='font-extra'> comment </span>
            on Buea Deaf Community Njangee group
          </p>
          <small className='text-secondary font-semibold mt-1'>
            15 hours ago
          </small>
        </Stack>
      </Stack>

      <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
        <img src={Image5} alt='' className='left-img-1' />
        <Stack className=''>
          <p className='leading-5'>
            <span className='font-extra'>Bernadine O&apos;Keefe </span>
            rejected your request to join the
            <span className='font-extra'> Soppo Union Women&apos;s Njangi</span>
          </p>
          <small className='text-secondary font-semibold mt-1'>
            May 30, 2023
          </small>
        </Stack>
      </Stack>

      <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
        <img src={Image1} alt='' className='left-img-1' />
        <Stack className=''>
          <p className='leading-5'>
            <span className='font-extra'>Jade Kuphal </span>
            accepted your request to join the
            <span className='font-extra'> Soppo Union Women&apos;s Njangi</span>
          </p>
          <small className='text-secondary font-semibold mt-1'>
            May 29, 2023
          </small>
        </Stack>
      </Stack>

      <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
        <img src={Image1} alt='' className='left-img-1' />
        <Stack className=''>
          <p className='leading-5'>
            <span className='font-extra'>Woodrow Howell </span>
            just contributed XAF 50,000 in
            <span className='font-extra'> Buea Deaf Community </span>
            njangee group. You are to receive XAF 750,000.
          </p>
          <small className='text-secondary font-semibold mt-1'>
            May 07, 2023
          </small>
        </Stack>
      </Stack>

      <Stack direction='row' className='w-full items-start gap-3 md:gap-5'>
        <img src={Image1} alt='' className='left-img-1' />
        <Stack className=''>
          <p className='leading-5'>
            <span className='font-extra'>Woodrow Howell </span>
            just contributed XAF 50,000 in
            <span className='font-extra'> Buea Deaf Community </span>
            njangee group. You are to receive XAF 750,000.
          </p>
          <small className='text-secondary font-semibold mt-1'>
            May 07, 2023
          </small>
        </Stack>
      </Stack> */}
    </>
  )
}
