// import IconVisaPayment from '../../../../images/dashboard_images/icon-payment-visa.png'
// import IconMasterCardPayment from '../../../../images/dashboard_images/icon-payment-mastercard.png'
import IconPaypalPayment from '../../../../images/dashboard_images/icon-paypal.png'
import IconMtnOrangeMoneyPayment from '../../../../images/dashboard_images/mtn_orange_logo.png'
// import IconOrangeMoneyPayment from '../../../../images/dashboard_images/img-payment-orangeMoney.png'

export const getPaymentMethodImage = (title: string): string => {
  //   if (title.toUpperCase() === 'Visa Card') {
  //     return IconVisaPayment
  //   } else if (title.toUpperCase() === 'Master Card') {
  //     return IconMasterCardPayment
  //   } else
  if (title.toUpperCase() === 'PAYPAL') {
    return IconPaypalPayment
    //   } else if (title.toUpperCase() === 'Orange Money') {
    //     return IconOrangeMoneyPayment
  } else {
    return IconMtnOrangeMoneyPayment
  }
}
