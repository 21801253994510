export const getKYCStatusColors = (status: string): string => {
  if (status === 'SUCCESS') {
    return 'text-success'
  } else if (status === 'PENDING') {
    return 'text-info'
  } else if (status === 'REJECTED') {
    return 'text-danger'
  } else {
    return 'text-primary'
  }
}

export const getKYCStatusBtnColors = (status: string): string => {
  if (status === 'SUCCESS') {
    return 'btn-success'
  } else if (status === 'PENDING') {
    return 'btn-info'
  } else if (status === 'REJECTED') {
    return 'btn-danger'
  } else {
    return 'btn-primary'
  }
}

export const getGroupStatusColors = (status: string): string => {
  const groupStatus = status.toUpperCase()
  if (groupStatus === 'ACTIVE') {
    return 'text-success'
  } else if (groupStatus === 'COMPLETED') {
    return 'text-primary'
  } else {
    return 'text-info'
  }
}
