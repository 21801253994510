import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface DashboardState {
  createdGroupDisplay: {
    initial: number
    final: number
  }
  joinedGroupDisplay: {
    initial: number
    final: number
  }
}

const initialState: DashboardState = {
  createdGroupDisplay: {
    initial: 0,
    final: 2
  },
  joinedGroupDisplay: {
    initial: 0,
    final: 2
  }
}

export const createDashboardStateSlice = createSlice({
  name: 'dashboard_state',
  initialState,
  reducers: {
    updateCreatedDisplay: (state, action: PayloadAction<DashboardState>) => {
      return {
        ...state,
        createdGroupDisplay: action.payload.createdGroupDisplay
      }
    },
    updateJoinedDisplay: (state, action: PayloadAction<DashboardState>) => {
      return {
        ...state,
        joinedGroupDisplay: action.payload.joinedGroupDisplay
      }
    }
  }
})

export const { updateCreatedDisplay, updateJoinedDisplay } =
  createDashboardStateSlice.actions

export const createDashboardStateSelector = (
  state: RootState
): DashboardState => state.createDashboardStateReducer
export default createDashboardStateSlice.reducer
