import React, { useState } from 'react'
import { IconButton, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { GroupsModalSkeleton } from '../../groups/fragments/groupsModalSkeleton'
import { CloseOutlined } from '@mui/icons-material'
import { appDeleteRequest } from '../../../common/helper/httpRequests'
import { CircularLoadingProgress } from '../../../registration/fragments/circularProgress'
import { getPaymentMethodImage } from '../functions/images'
interface IProps {
  handleClose: () => void
  singlePaymentMethod: Record<string, any>
  open: boolean
}

export const DeletePaymentMethod: React.FC<IProps> = ({
  singlePaymentMethod,
  open,
  handleClose
}) => {
  const { t } = useTranslation(['dashboard'])
  const [isLoading, setIsLoading] = useState(false)
  const handleDeletePaymentMethod = async (): Promise<void> => {
    setIsLoading(true)
    await appDeleteRequest(`/users/payment-settings/${singlePaymentMethod.id as string}`)
    setIsLoading(false)
    location.reload()
  }
  const paymentDetailsData = JSON.parse(singlePaymentMethod?.paymentDetails)
  return (
    <GroupsModalSkeleton
      open={open}
      handleClose={handleClose}
      xsModalWidth='95%'
      smModalWidth='65%'
      mdModalWidth='55%'
      lgModalWidth='40%'
    >
      <Stack
        direction='row'
        className='flex items-center w-full justify-start gap-3 bg-primary text-th-white py-3 px-4 sm:px-7 rounded-t border !max-h-[200px]'
      >
        <h6>{t('settings.delete_payment_method')}</h6>
        <IconButton onClick={handleClose} className='!ml-auto'>
          <CloseOutlined className='text-primary-light' />
        </IconButton>
      </Stack>

      <Stack className='px-5 mx-auto py-5 my-auto'>
        <h6 className=''>
          {t('settings.delete_payment_method_confirm_message')}?
        </h6>
        <br />
        <Stack direction='row' className='flex gap-4'>
          <img
            src={getPaymentMethodImage(
              singlePaymentMethod?.paymentGateway?.name
            )}
            alt=''
            className='w-7 md:w-10'
          />
          <Stack>
            <p>{singlePaymentMethod?.paymentGateway?.name}</p>
            <p className='font-bold'>
              {singlePaymentMethod?.paymentGateway?.name.toUpperCase() ===
              'MOMO'
                ? paymentDetailsData?.phoneNumber
                : paymentDetailsData?.email}{' '}
            </p>
            <small className='font-bold'>({paymentDetailsData?.name})</small>
          </Stack>
        </Stack>
      </Stack>

      <Stack className='mt-auto px-4 sm:px-7'>
        <Stack
          direction='row'
          className='w-fit ml-auto items-center gap-5 sm:gap-10'
        >
          <button
            onClick={handleClose}
            className='border-0 rounded-none w-fit h-fit text-black-2 font-semibold'
          >
            {t('general.common.cancel')}
          </button>
          <button
            className='btn btn-danger flex gap-2'
            disabled={isLoading}
            onClick={handleDeletePaymentMethod}
          >
            {isLoading && <CircularLoadingProgress />}
            {t('settings.yes_delete')}
          </button>
        </Stack>
      </Stack>
      <br />
    </GroupsModalSkeleton>
  )
}
