import React from 'react'
import DOMPurify from 'dompurify'
interface INotificationContent {
  content: string | null
}
export const SanitizedNotificationContent: React.FC<INotificationContent> = ({
  content
}) => {
  // Sanitize the content
  const sanitizedContent = content != null ? DOMPurify.sanitize(content) : ''

  return (
    <p
      className='leading-5'
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
    ></p>
  )
}
