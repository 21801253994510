export const getPageNumber = (): number => {
  const queryParams = new URLSearchParams(location.search)
  const initialPage = parseInt(queryParams.get('page') ?? '1', 10)
  return initialPage
}

export const getQueryWhereParam = (param: string): string | null => {
  const queryParams = new URLSearchParams(location.search)
  const paramValue = queryParams.get(param)
  return paramValue
}
