import React, { useEffect, useState, type ReactElement } from 'react'
import {
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UserDashBoardLayout } from '../common/dashboardUiLayout'
import { Link, useParams } from 'react-router-dom'
import BackIcon from '../../../images/icon_images/icon-back.svg'
import ArrowRight from '../../../images/icon_images/icon-arrow-right.svg'
import CompletePaymentIcon from '../../../images/icon_images/icon-check-green.svg'
import FailedPaymentIcon from '../../../images/icon_images/icon-failed-circle.svg'
import PendingPaymentIcon from '../../../images/icon_images/icon-hourglass-time-info.svg'
import { appGetRequest } from '../../common/helper/httpRequests'
import { IsLoading } from '../../registration/fragments/isLoading'
import { formatToDateAndTimeString } from '../../common/helper/convertToDate'
import { getPaymentMethod } from './function/functions'

export const UserPaymentDetails: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [paymentData, setPaymentData] = useState<any>()
  const params = useParams()
  const { t } = useTranslation(['dashboard'])

  const getPaymentData = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const payment = await appGetRequest(
        `/transactions/${params.id as string}/show`
      )
      if (payment?.data !== null && payment.data?.data !== undefined) {
        setPaymentData(payment.data.data)
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }
  const paymentStatusFunc = (paymentStatus: string): ReactElement => {
    if (paymentStatus?.toLowerCase() === 'successful') {
      return (
        <Stack
          direction='row'
          className='w-fit flex items-center justify-center py-1 px-4 rounded-full border border-success bg-primary-3xlight text-success gap-2'
        >
          <img src={CompletePaymentIcon} alt='' />
          <p>{t('payments.complete')}</p>
        </Stack>
      )
    } else if (paymentStatus?.toLowerCase() === 'failed') {
      return (
        <Stack
          direction='row'
          className='w-fit flex items-center justify-center py-1 px-4 rounded-full border border-danger bg-pink-xlight text-danger gap-2'
        >
          <img src={FailedPaymentIcon} alt='' />
          <p>{t('payments.failed')}</p>
        </Stack>
      )
    } else {
      return (
        <Stack
          direction='row'
          className='w-fit flex items-center justify-center py-1 px-4 rounded-full border border-info bg-pink-xlight text-info gap-2'
        >
          <img src={PendingPaymentIcon} alt='' />
          <p>{t('payments.pending')}</p>
        </Stack>
      )
    }
  }
  const tableData = (
    amount: string,
    paidBy: { name: string, tel: string },
    paymentStatus: string
  ): ReactElement => {
    return (
      <>
        <TableRow className='h-2'></TableRow>
        <TableRow className='!my-3 bg-white border-0'>
          <TableCell>
            <p className='font-semibold'>{amount}</p>
          </TableCell>
          <TableCell>
            <p className='font-extra'>{paidBy.name}</p>
            <p className='font-semibold text-secondary'>{paidBy.tel}</p>
          </TableCell>
          <TableCell className='font-semibold !text-base'>
            {paymentStatusFunc(paymentStatus)}
          </TableCell>
        </TableRow>
      </>
    )
  }

  useEffect(() => {
    void getPaymentData()
  }, [])
  return (
    <UserDashBoardLayout>
      {isLoading ? (
        <IsLoading />
      ) : (
        <Stack className='w-full payments-detail'>
          <Stack direction='column' className='w-full px-4 md:px-12'>
            <Stack
              direction='row'
              className='w-full items-center border-main border-b py-3 mb-2 gap-5 flex-wrap'
            >
              <Link
                to='/user-payments'
                className='font-semibold w-fit flex items-center'
              >
                <img alt='' src={BackIcon} className='w-fit h-fit pr-4' />{' '}
                {t('payments.back_to_index')}
              </Link>
              <Link
                to='/user-settings?payment-methods'
                className='text-button-primary ml-auto w-fit flex items-center'
              >
                {t('payments.manage_payment_methods')}{' '}
                <img alt='' src={ArrowRight} className='w-fit h-fit pl-4' />
              </Link>
            </Stack>

            <Stack
              direction='row'
              display='grid'
              className='w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mt-5'
            >
              <Stack className='w-full col-span-1 lg:col-span-2 gap-4'>
                <Stack
                  direction='row'
                  className='w-full items-start flex-wrap border border-main bg-white rounded py-4 px-6 gap-6'
                >
                  <p className='uppercase font-extra'>
                    {t('payments.paid_to')}:
                  </p>
                  <Stack direction='row' className='items-start gap-4'>
                    {paymentData?.group?.groupPhoto !== null ? (
                      <img
                        alt=''
                        src={paymentData?.group?.groupPhoto}
                        className='rounded-md w-16 h-16'
                      />
                    ) : (
                      <Stack className='rounded-md w-16 h-16 bg-primary-xlight font-extrabold text-center'>
                        <h5 className='my-auto text-primary'>
                          {paymentData?.group?.name.slice(0, 2).toUpperCase() ??
                            'GR'}
                        </h5>
                      </Stack>
                    )}
                    <Stack className='gap-5'>
                      <p className='font-semibold'>
                        {paymentData?.group?.name ?? '-'}
                      </p>
                      <p className='text-secondary font-semibold'>
                        {formatToDateAndTimeString(paymentData?.paidAt, '|')}
                      </p>
                      <p className='text-secondary font-semibold'>
                        {t('payments.manual_payment')}
                      </p>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  direction='row'
                  className='w-full flex-wrap border border-main bg-white rounded py-4 px-6 gap-6'
                >
                  <p className='uppercase font-extra'>
                    {t('payments.paid_by')}:
                  </p>
                  <Stack direction='row' className='items-center gap-4'>
                    {getPaymentMethod(
                      paymentData?.paymentGateway?.name,
                      paymentData?.transactionMetaData
                    )}
                  </Stack>
                </Stack>
              </Stack>

              <Stack className='col-span-1 lg:col-span-3 w-full lg:w-10/12'>
                <Stack className='w-full h-full'>
                  <TableContainer>
                    <Table sx={{ minWidth: '300px' }}>
                      <TableHead
                        sx={{
                          border: '2px solid var(--primary-xlight)',
                          backgroundColor: 'var(--primary-2xlight)'
                        }}
                      >
                        <TableRow className='uppercase'>
                          <TableCell
                            sx={{
                              borderRight: '2px solid var(--primary-xlight)'
                            }}
                            className='font-extra uppercase'
                          >
                            {t('general.common.amount')}
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: '2px solid var(--primary-xlight)'
                            }}
                            className='font-extra uppercase border-r-2'
                          >
                            {t('payments.paid_by')}
                          </TableCell>
                          <TableCell className='font-extra uppercase'>
                            {t('payments.payment_status')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className='h-full bg-white'>
                        {tableData(
                          `${paymentData?.group.currency as string} ${
                            paymentData?.amount as string
                          }`,
                          {
                            name: `${paymentData?.user.firstName as string} ${
                              paymentData?.user.lastName as string
                            }`,
                            tel: paymentData?.user.mobile ?? '-'
                          },
                          paymentData?.status
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
    </UserDashBoardLayout>
  )
}
