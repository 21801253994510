import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'
import { type AppMetaData } from '../../../common/globals/types'
import { APP_INITIAL_METADATA } from '../../../common/globals/initials'

export interface GetInvitations {
    invitations: any[]
    meta: AppMetaData
}

const initialState: GetInvitations = {
    invitations: [],
    meta: APP_INITIAL_METADATA
}

export const createGetInvitationsSlice = createSlice({
    name: 'get_invitations',
    initialState,
    reducers: {
        setGetInvitations: (state, action: PayloadAction<GetInvitations>) => {
            return {
                ...state,
                invitations: action.payload.invitations,
                meta: action.payload.meta
            }
        }
    }
})

export const { setGetInvitations } = createGetInvitationsSlice.actions

export const createGetInvitationsSelector = (state: RootState): GetInvitations => state.createGetInvitationsReducer
export default createGetInvitationsSlice.reducer
