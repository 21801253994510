import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface UserProfile {
  id: string
  first_name: string
  last_name: string
  email: string
  phone_number: string
  country: string
  gender: string
  state_region: string
  city: string
  post_code?: number
  address: string
  photo: Blob | MediaSource
  photoUrl: string
}

const initialState: UserProfile = {
  id: '',
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  country: '',
  gender: '',
  state_region: '',
  city: '',
  post_code: 0,
  address: '',
  photo: new Blob(),
  photoUrl: ''
}

export const createUserProfileSlice = createSlice({
  name: 'user_profile',
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<UserProfile>) => {
      return {
        ...state,
        id: action.payload.id,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        email: action.payload.email,
        phone_number: action.payload.phone_number,
        country: action.payload.country,
        gender: action.payload.gender,
        state_region: action.payload.state_region,
        city: action.payload.city,
        post_code: action.payload.post_code,
        address: action.payload.address,
        photo: action.payload.photo,
        photoUrl: action.payload.photoUrl
      }
    }
  }
})

export const { setUserProfile } = createUserProfileSlice.actions

export const createUserProfileSelector = (state: RootState): UserProfile =>
  state.createUserProfileReducer
export default createUserProfileSlice.reducer
