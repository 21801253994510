import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface PaymentMethods {
    id: string
    paymentCredentials: any[]
}

const initialState: PaymentMethods = {
    id: '0',
    paymentCredentials: [
        // {
        //     id: '1',
        //     paymentType: 'debit-card',
        //     title: 'Visa Card',
        //     payment_string: '**** **** **** 0256',
        //     isDefault: true,
        //     date: '08/29'
        // },
        // {
        //     id: '2',
        //     paymentType: 'debit-card',
        //     title: 'Master Card',
        //     payment_string: '**** **** **** 0256',
        //     isDefault: false,
        //     date: '08/29'
        // },
        // {
        //     id: '3',
        //     paymentType: 'paypal',
        //     title: 'Paypal',
        //     payment_string: 'sandralee@gmail.com',
        //     isDefault: false
        // },
        // {
        //     id: '4',
        //     paymentType: 'mobile-money',
        //     title: 'Mtn Mobile Money',
        //     payment_string: '+237 6 78 98 54 23',
        //     isDefault: false
        // },
        // {
        //     id: '5',
        //     paymentType: 'mobile-money',
        //     title: 'Orange Money',
        //     payment_string: '+237 6 98 89 58 47',
        //     isDefault: false
        // }
    ]
}

export const createPaymentMethodsSlice = createSlice({
    name: 'payment_methods',
    initialState,
    reducers: {
        setPaymentMethods: (state, action: PayloadAction<PaymentMethods>) => {
            return {
                ...state,
                id: action.payload.id,
                paymentCredentials: action.payload.paymentCredentials
            }
        }
    }
})

export const { setPaymentMethods } = createPaymentMethodsSlice.actions

export const createPaymentMethodsSelector = (state: RootState): PaymentMethods => state.createPaymentMethodsReducer
export default createPaymentMethodsSlice.reducer
