import React, { type ReactElement } from 'react'
import MasterCardLogo from '../../../../images/dashboard_images/icon-payment-mastercard.png'
import VisaCardLogo from '../../../../images/dashboard_images/icon-payment-visa.png'
import MTNMoMo from '../../../../images/dashboard_images/payment-mtn-logo-4@2x.png'
import OrangeMoney from '../../../../images/dashboard_images/img-payment-orangeMoney.png'
import PaypalIcon from '../../../../images/dashboard_images/icon-paypal.png'
import IconMtnOrangeMoneyPayment from '../../../../images/dashboard_images/mtn_orange_logo.png'

const paymentMethod = (
  paymentGateWay: string,
  transactionMetaData?: string
): string => {
  let decodedMetadata: any = {}
  if (transactionMetaData !== null && transactionMetaData !== undefined) {
    decodedMetadata = JSON.parse(transactionMetaData)
    if (paymentGateWay === 'MOMO') {
      return decodedMetadata.response?.payment_method
    } else if (paymentGateWay === 'PAYPAL') {
      return 'PAYPAL'
    } else {
      return '-'
    }
  }
  return '-'
}

export const getPaymentMethod = (
  paymentGateWay: string,
  transactionMetaData?: string
): ReactElement => {
  const paymentMethodString = paymentMethod(paymentGateWay, transactionMetaData)
  if (paymentMethodString === 'mastercard') {
    return (
      <>
        <img alt='' src={MasterCardLogo} className='h-7 w-7' /> Master Card
      </>
    )
  } else if (paymentMethodString === 'visacard') {
    return (
      <>
        <img alt='' src={VisaCardLogo} className='h-7 w-7' /> Visa Card
      </>
    )
  } else if (paymentMethodString === 'PAYPAL') {
    return (
      <>
        <img alt='' src={PaypalIcon} className='h-7 w-7' /> PayPal
      </>
    )
  } else if (paymentMethodString === 'MTNCM') {
    return (
      <>
        <img alt='' src={MTNMoMo} className='h-7 w-7' /> MTN Momo
      </>
    )
  } else if (paymentMethodString === 'OMCM') {
    return (
      <>
        <img alt='' src={OrangeMoney} className='h-7 w-7' /> Orange Money
      </>
    )
  } else {
    return (
      <>
        <img alt='' src={IconMtnOrangeMoneyPayment} className='h-7' /> MoMo
      </>
    )
  }
}
