import React, { useEffect, useRef, useState } from 'react'
import { Stack } from '@mui/material'
import { RegistrationBox } from './fragments/registrationBox'
import { PasswordField } from '@aws-amplify/ui-react'
import { useForm } from 'react-hook-form'
import { TextInputField } from '../user/common/textInputField'
// import {
//   useAppDispatch,
//   useAppSelector
// } from '../../redux/app/create_njangee_store/hooks'
// import { createChooseRegistrationSelector } from './slices/chooseRegistrationSlice'
import { CircularLoadingProgress } from './fragments/circularProgress'
import { Link, useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { Info } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber
} from '../user/common/helper/validCreds'
import { toast } from 'react-toastify'

export const Login: React.FC = () => {
  // const [dialCode, setDialCode] = useState('+1')
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const [showCircleProgress, setShowCircleProgress] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showConfirmSignUp, setShowConfirmSignUp] = useState(false)
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()
  const [confirmSignUpData, setConfirmSignUpData] = useState({
    userName: '',
    first_name: ''
  })
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  // const userRegistration = useAppSelector(createChooseRegistrationSelector)
  // const dispatch = useAppDispatch()
  // const handleRecaptchaChange = (token: string | null): void => {
  //   setRecaptchaToken(token)
  // }

  const formValidation = {
    username: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 5,
        matchPattern: (v: string) => {
          if (localStorage.getItem('njangee_signUpType') === 'phone_number') {
            return isValidPhoneNumber(v)
          } else {
            return isValidEmail(v)
          }
        }
      }
    },
    password: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 8,
        matchPattern: (v: string) => isValidPassword(v)
      }
    }
  }
  // const handleDialCode = (e: any): void => {
  //   setDialCode(e.target.value)
  // }

  const handleConfirmUser = (): void => {
    localStorage.setItem('njangee_confirm', 'confirm_from_login')
    localStorage.setItem('njangee_username', confirmSignUpData.userName)
    navigate('/confirm-user')
  }

  // const handleUsernameChoice = (option: string): void => {
  //   localStorage.setItem('njangee_signUpType', option)
  //   dispatch(
  //     setChooseRegistration({ ...userRegistration, sign_up_option: option })
  //   )
  // }

  // const usernameToUse = (userN: string, descText: string): ReactElement => {
  //   return (
  //     <button
  //       type='button'
  //       className='text-button-primary bg-none border-0 p-auto mr-auto cursor-pointer small'
  //       onClick={() => {
  //         handleUsernameChoice(userN)
  //       }}
  //     >
  //       {descText}
  //     </button>
  //   )
  // }

  // const signInWithGoogle = async (): Promise<void> => {
  //   try {
  //     await Auth.federatedSignIn({
  //       provider: CognitoHostedUIIdentityProvider.Google
  //     })
  //   } catch (error) {
  //   }
  // }

  const handleSignInSubmit = async (data: any): Promise<void> => {
    const captchaValue = recaptchaRef.current?.getValue() ?? null
    if (
      captchaValue === null ||
      captchaValue === undefined ||
      captchaValue === ''
    ) {
      toast.error(t('onboarding.please_verify_captcha'))
    } else {
      setShowCircleProgress(true)
      setShowErrorMessage(false)
      let username = ''
      // if (localStorage.getItem('njangee_signUpType') === 'phone_number') {
      //   username = `${dialCode.concat(data.username)}`
      // } else {
      username = data.username
      // }
      await Auth.signIn(username, data.password)
        .then((res) => {
          localStorage.removeItem('njangee_confirm')
          localStorage.removeItem('njangee_username')
          localStorage.removeItem('njangee_first_name')
          localStorage.removeItem('njangee_signUpType')
          setShowCircleProgress(false)
          localStorage.setItem(
            'njangee_jwt_token',
            res.signInUserSession.idToken.jwtToken
          )
          navigate('/user-onboarding')
        })
        .catch((error) => {
          setShowCircleProgress(false)
          setShowErrorMessage(true)
          const signUpType = localStorage.getItem('njangee_signUpType')
          switch (error.code) {
            case 'NotAuthorizedException':
              if (String(signUpType) === 'phone_number') {
                setErrorMessage(
                  `${String(t('registration.phone_number'))} or ${String(
                    t('registration.password')
                  )}`
                )
              } else {
                setErrorMessage(
                  `${String(t('registration.invalid_email'))} or ${String(
                    t('registration.password')
                  )}`
                )
              }
              break
            case 'NetworkError':
              setErrorMessage(t('registration.network_error'))
              break
            case 'UserNotConfirmedException':
              setErrorMessage(t('registration.user_not_confirmed'))
              setConfirmSignUpData({
                ...confirmSignUpData,
                userName: username,
                first_name: username
              })
              setShowConfirmSignUp(true)
              break
            default:
              setErrorMessage(t('registration.network_error'))
          }
        })
      // make form submission
    }
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        navigate('/user')
      })
      .catch(() => {
        return null
      })
  }, [])
  return (
    <RegistrationBox showNeedHelp={true}>
      <Stack className='px-6 sm:px-8 py-8'>
        <h4>{t('registration.login')}</h4>
        <p>{t('registration.login_caption')}</p>
        <br />
        <Stack className=''>
          <form onSubmit={handleSubmit(handleSignInSubmit)}>
            {/* {(localStorage.getItem('njangee_signUpType') === 'phone_number' || userRegistration.sign_up_option === 'phone_number')
                            ? <Stack className='gap-2 mb-4'>
                                <PhoneNumberField
                                    label={t('registration.phone_number')}
                                    defaultDialCode='+1'
                                    maxLength={100}
                                    onDialCodeChange={(e: any) => { handleDialCode(e) }}
                                    {...register('username', formValidation.username)}
                                    className={errors.username?.type === 'required' ? 'input-error' : ''} />
                                {
                                    errors.username?.type === 'required' && (
                                        <small className='text-danger'>{t('registration.phone_number_required')}</small>
                                    )
                                }
                                {
                                    errors.username?.type === 'minLength' && (
                                        <small className='text-danger'>{t('registration.phone_number_has_atleast_5_chars')}</small>
                                    )
                                }
                                {errors.username?.type === 'matchPattern' && (
                                    <small className='text-danger'>{t('registration.must_be_valid_phone_number')}</small>
                                )}
                                {usernameToUse('email', t('registration.use_phone_instead'))}
                            </Stack>
                            :  */}
            <Stack className='gap-2 mb-4'>
              <p className='font-semibold mb-2'>{t('registration.email')}</p>
              <TextInputField
                type='text'
                maxLength={255}
                anyValidation={register('username', formValidation.username)}
                addClass={
                  errors.username?.type === 'required' ? 'input-error' : ''
                }
              />
              {errors.username?.type === 'required' && (
                <small className='text-danger'>
                  {t('registration.email_required')}
                </small>
              )}
              {errors.username?.type === 'minLength' && (
                <small className='text-danger'>
                  {t('registration.email_has_atleast_5_characters')}
                </small>
              )}
              {errors.username?.type === 'matchPattern' && (
                <small className='text-danger'>
                  {t('registration.must_be_valid_email')}
                </small>
              )}
              {/* {usernameToUse('phone_number', t('registration.use_email_instead'))} */}
            </Stack>

            {/* } */}
            <Stack className='w-full'>
              <PasswordField
                {...register('password', formValidation.password)}
                maxLength={100}
                label={t('registration.password')}
                placeholder={t('registration.atleast_8_chars')}
                className={
                  errors.password?.type === 'required' ? 'input-error' : ''
                }
              />
              {errors.password?.type === 'required' && (
                <small className='text-danger'>
                  {t('registration.password_required')}
                </small>
              )}
              {errors.password?.type === 'minLength' && (
                <small className='text-danger'>
                  {t('registration.password_has_atleast_8_chars')}
                </small>
              )}
              {errors.password?.type === 'matchPattern' && (
                <small className='text-danger'>
                  {t('registration.password_has_atleast_8_chars')}
                </small>
              )}
            </Stack>
            <Stack className='w-full mt-2'>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ''}
              />
            </Stack>
            <Stack className='my-2'>
              <Link to='/forgot-password' className='font-semibold'>
                {t('registration.forgot_password')}?
              </Link>
            </Stack>

            {showErrorMessage && (
              <Stack className='w-full bg-red-500 bg-opacity-30 my-4 p-1'>
                <small className='text-danger mx-auto flex gap-3'>
                  {errorMessage} <Info fontSize='small' />
                </small>
                {showConfirmSignUp && (
                  <button
                    className='text-button-primary bg-none border-0 p-auto text-13'
                    onClick={handleConfirmUser}
                  >
                    {t('registration.confirm_sign_up')}
                  </button>
                )}
              </Stack>
            )}

            <Stack className='mt-6'>
              <button
                className='btn-full btn-primary flex gap-3 justify-center'
                disabled={showCircleProgress}
              >
                {showCircleProgress && <CircularLoadingProgress />}
                {t('registration.sign_in')}
              </button>
            </Stack>
            <br />
          </form>
          {/* <Stack className='mt-6'>
            <button
              className='btn-full btn-primary flex gap-3 justify-center'
              disabled={showCircleProgress}
              onClick={signInWithGoogle}
            >
              {showCircleProgress && <CircularLoadingProgress />}
              {t('registration.sign_in')}
            </button>
          </Stack> */}
        </Stack>
        <Stack className='mt-10'>
          <p>
            {t('registration.dont_have_an_acc')}?{' '}
            <span className='text-primary'>
              <Link to='/choose-registration'>{t('registration.sign_up')}</Link>
            </span>
          </p>
        </Stack>
      </Stack>
    </RegistrationBox>
  )
}
