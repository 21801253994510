import React, { useState } from 'react'
import { Stack } from '@mui/material'
import { CircularLoadingProgress } from './fragments/circularProgress'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import { PasswordField } from '@aws-amplify/ui-react'
import { useForm } from 'react-hook-form'
import { ChevronRightOutlined, Info } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { isValidPassword, isValidCode } from '../user/common/helper/validCreds'
import {
  useAppSelector,
  useAppDispatch
} from '../../redux/app/create_njangee_store/hooks'
import {
  createChooseRegistrationSelector,
  setChooseRegistration
} from './slices/chooseRegistrationSlice'
import { ToastMessageSuccess } from '../user/common/fragments/toastMsgSuccess'
import PinInput from 'react-pin-input'

export const ForgotPasswordCodeVerification: React.FC = () => {
  const [showConfirmCircleProgress, setShowConfirmCircleProgress] =
    useState(false)
  const [showPasswordMatch, setShowPasswordMatch] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showBackToHome, setShowBackToHome] = useState(false)
  const [showResendCircleProgress, setShowResendCircleProgress] =
    useState(false)
  const [showCodeSentSuccess, setShowCodeSentSuccess] = useState(false)
  const userRegistration = useAppSelector(createChooseRegistrationSelector)
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const formValidation = {
    code: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 6,
        matchPattern: (v: string) => isValidCode(v)
      }
    },
    password: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 8,
        matchPattern: (v: string) => isValidPassword(v)
      }
    },
    confirm_password: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 8,
        matchPattern: (v: string) => isValidPassword(v)
      }
    }
  }

  const handleShowPasswordMatch = (): void => {
    setShowPasswordMatch(false)
  }
  const handleResendCode = (): void => {
    setShowResendCircleProgress(true)
    setShowCodeSentSuccess(false)
    const username = localStorage.getItem('njangee_fgt_pass_uname') ?? ''
    Auth.forgotPassword(username)
      .then((res) => {
        localStorage.setItem('njangee_fgt_pass_uname', username)
        localStorage.setItem('njangee_username', username)
        dispatch(
          setChooseRegistration({ ...userRegistration, code_sent: true })
        )
        setShowResendCircleProgress(false)
        setShowCodeSentSuccess(true)
        setTimeout(() => {
          setShowCodeSentSuccess(false)
        }, 3000)
      })
      .catch(() => {
        setShowResendCircleProgress(false)
      })
  }

  const handleBackToHome = (): void => {
    localStorage.removeItem('njangee_fgt_pass_uname')
    navigate('/')
  }

  const handleConfirmUser = (): void => {
    localStorage.setItem('njangee_confirm', 'confirm_from_login')
    navigate('/confirm-user')
  }

  const handleForgotPasswordVerificationCode = (data: any): any => {
    if (data.honeypot === '') {
      setShowConfirmCircleProgress(true)
      setShowBackToHome(false)
      if (data.password === data.confirm_password) {
        Auth.forgotPasswordSubmit(
          `${localStorage.getItem('njangee_fgt_pass_uname') as string}`,
          data.code,
          data.password
        )
          .then((res) => {
            setShowConfirmCircleProgress(false)
            localStorage.removeItem('njangee_fgt_pass_uname')
            navigate('/login')
          })
          .catch((error) => {
            setShowConfirmCircleProgress(false)
            setShowErrorMessage(true)
            if (error.code === 'CodeMismatchException') {
              setErrorMessage(`${String(t('registration.invalid_code'))}`)
            } else if (error.code === 'ExpiredCodeException') {
              setShowBackToHome(true)
              setErrorMessage(`${String(t('registration.expired_code'))}`)
            } else if (error.code === 'LimitExceededException') {
              setShowBackToHome(true)
              setErrorMessage(`${String(t('registration.attempt_limit'))}`)
            } else {
              setErrorMessage(`${String(t('registration.network_error'))}`)
            }
          })
      } else {
        setShowPasswordMatch(true)
        setShowConfirmCircleProgress(false)
      }
    }
  }

  return (
    <>
      {
        <Stack className='my-auto px-6 sm:px-8 py-8'>
          <Stack>
            <h4>{t('registration.reset_your_password')}</h4>

            <p>
              {t('registration.received_reset_request')}, (
              <b>{localStorage.getItem('njangee_fgt_pass_uname')}</b>).{' '}
              {t('registration.to_get_a_new_pass')}
            </p>
            <Stack direction='row' className='gap-2 mt-2 items-center'>
              <small className='font-semibold'>{t('registration.note')}:</small>
              <small>
                <i>
                  {t('registration.if_youre_not')}{' '}
                  <b className='text-info'>
                    {t('registration.registered_or_verified')}
                  </b>
                  , {t('registration.you_wont_receive')}
                </i>
              </small>
            </Stack>
            <Stack direction='column' pt={2}>
              <button
                type='button'
                onClick={handleConfirmUser}
                className='small font-bold text-primary-bold cursor-pointer flex gap-1'
              >
                {showResendCircleProgress && <CircularLoadingProgress />}
                {t('registration.verify_user')}
                <ChevronRightOutlined fontSize='small' />
              </button>
            </Stack>
          </Stack>
          <br />
          <form onSubmit={handleSubmit(handleForgotPasswordVerificationCode)}>
            <Stack className='!hidden'>
              <label htmlFor='honeypot' className='p'>
                {t('registration.dont_fill_this_form_if_human')}:
              </label>
              <input type='text' id='honeypot' {...register('honeypot')} />
            </Stack>
            <Stack className='gap-2 mb-4'>
              <p className='font-semibold'>{t('registration.code')}</p>
              {/* <TextInputField type='text' anyValidation={register('code', formValidation.code)} placeholder={t('registration.enter_confirm_code')} addClass={`${errors.password?.type === 'required' ? 'input-error' : ''}`} /> */}
              <PinInput
                length={6}
                initialValue=''
                secret={false}
                // secretDelay={100}

                onChange={(value, index) => {
                  setValue('code', value)
                }}
                type='numeric'
                inputMode='number'
                style={{ padding: '10px' }}
                inputStyle={{
                  borderColor: 'var(--secondary)',
                  height: '50px',
                  width: '45px'
                }}
                inputFocusStyle={{ borderColor: 'var(--primary)' }}
                onComplete={(value, index) => {
                  setValue('code', value)
                }}
                autoSelect={true}
                // {...register('code', formValidation.password)}
              />
              {errors.code?.type === 'required' && (
                <small className='text-danger'>
                  {t('registration.code_required')}
                </small>
              )}
              {errors.code?.type === 'minLength' && (
                <small className='text-danger'>
                  {t('registration.code_has_atleast_6_chars')}
                </small>
              )}
              {errors.code?.type === 'matchPattern' && (
                <small className='text-danger'>
                  {t('registration.must_be_valid_code')}
                </small>
              )}
            </Stack>
            <Stack className='w-full mb-4'>
              <PasswordField
                {...register('password', formValidation.password)}
                maxLength={100}
                label={t('registration.new_password')}
                placeholder={t('registration.atleast_8_chars')}
                className={
                  errors.password?.type === 'required' ? 'input-error' : ''
                }
                onChange={handleShowPasswordMatch}
              />
              {errors.password?.type === 'required' && (
                <small className='text-danger'>
                  {t('registration.password_required')}
                </small>
              )}
              {errors.password?.type === 'minLength' && (
                <small className='text-danger'>
                  {t('registration.password_has_atleast_8_chars')}
                </small>
              )}
              {errors.password?.type === 'matchPattern' && (
                <small className='text-danger'>
                  {t('registration.password_has_atleast_8_chars')}
                </small>
              )}
              {showPasswordMatch && (
                <small className='text-danger'>
                  {t('registration.passwords_dont_match')}
                </small>
              )}
            </Stack>

            <Stack className='w-full'>
              <PasswordField
                {...register(
                  'confirm_password',
                  formValidation.confirm_password
                )}
                maxLength={100}
                label={t('registration.confirm_new_password')}
                placeholder={t('registration.atleast_8_chars')}
                className={
                  errors.confirm_password?.type === 'required'
                    ? 'input-error'
                    : ''
                }
              />
              {errors.password?.type === 'required' && (
                <small className='text-danger'>
                  {t('registration.password_required')}
                </small>
              )}
              {errors.password?.type === 'minLength' && (
                <small className='text-danger'>
                  {t('registration.password_has_atleast_8_chars')}
                </small>
              )}
              {errors.password?.type === 'matchPattern' && (
                <small className='text-danger'>
                  {t('registration.password_has_atleast_8_chars')}
                </small>
              )}
            </Stack>
            <br />
            {showErrorMessage && (
              <Stack className='w-full bg-red-500 bg-opacity-30 my-4 p-1'>
                <small className='text-danger mx-auto flex gap-3'>
                  {errorMessage} <Info fontSize='small' />
                </small>

                {showBackToHome && (
                  <button
                    onClick={handleBackToHome}
                    className='text-button-primary bg-none border-0 p-auto text-13 mx-auto'
                  >
                    {t('registration.back_to_home')}
                  </button>
                )}
              </Stack>
            )}
            <Stack className='w-full'>
              <button className='btn-full btn-primary flex gap-3 justify-center'>
                {showConfirmCircleProgress && <CircularLoadingProgress />}
                {t('registration.reset_password')}
              </button>
            </Stack>
          </form>
          <Stack direction='row' justifyContent='center' mt={1}>
            <small>{t('registration.didnt_receive_code')}? </small>
            <button
              type='button'
              onClick={handleResendCode}
              className='small font-bold text-primary ml-1 cursor-pointer flex gap-2'
            >
              {' '}
              {showResendCircleProgress && <CircularLoadingProgress />}{' '}
              {t('registration.resend_code')}.{' '}
            </button>
          </Stack>
          <ToastMessageSuccess
            showSuccessMessage={showCodeSentSuccess}
            successMessage='Code resent successfully'
          />
        </Stack>
      }
    </>
  )
}
