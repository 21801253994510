import React, { useState } from 'react'
import { Stack, IconButton, Menu, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ShowMoreDots from '../../../../images/icon_images/icon-more.svg'
import PlayNjangeeIcon from '../../../../images/icon_images/icon-play-njangee.svg'
import ViewIcon from '../../../../images/icon_images/icon-view.svg'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import { getGroupFrequencyString } from '../../common/helper/dateTimeFunctions'
import { createDashboardStateSelector } from '../slices/dashboardSlice'
import { createGetCreatedGroupsSelector } from '../slices/createdGroupsSlice'
import { toTitleCase } from '../../../common/helper/strings/toTitleCase'
import { getGroupStatusColors } from '../../common/helper/statusFunctions'
import {
  createPlayNjangeeSelector,
  setPlayNjangeeData
} from '../../groups/slices/playNjangeeSlice'

export const CreatedNjangiGroups: React.FC = () => {
  const [openDropdownId, setOpenDropdownId] = useState<null | string>(null)
  const groups = useAppSelector(createGetCreatedGroupsSelector)
  const playNjangeeState = useAppSelector(createPlayNjangeeSelector)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const createdGroupDisplay = useAppSelector(createDashboardStateSelector)
  // const openNjangeeGroup = Boolean(njangeeButtonDrop)
  const { t } = useTranslation(['dashboard'])
  const handleDropdownOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    groupId: string
  ): void => {
    setOpenDropdownId(groupId)
  }

  const handleDropdownClose = (): void => {
    setOpenDropdownId(null)
  }
  const handleGroupNavigation = (groupId: string): void => {
    navigate(`/user-groups/${groupId}/show`)
  }

  const handlePlayNjangee = (group: any): void => {
    dispatch(setPlayNjangeeData({ ...playNjangeeState, groupData: group }))
    navigate('/user-groups/play-njangee')
  }
  return (
    <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5'>
      {/* left */}
      {groups.groups
        ?.slice(
          createdGroupDisplay.createdGroupDisplay.initial,
          createdGroupDisplay.createdGroupDisplay.final
        )
        .map((group: any) => {
          const isOpen = openDropdownId === group?.id
          return (
            <Stack
              key={group?.id}
              direction='column'
              className='w-full single-group-box left-box'
            >
              <Stack direction='row' className='items-start mt-1'>
                {group?.groupPhoto !== null &&
                group?.groupPhoto !== '' &&
                group?.groupPhoto !== undefined ? (
                  <img
                    alt=''
                    src={group?.groupPhoto}
                    className='h-9 w-9 rounded mr-3'
                  />
                ) : (
                  <Stack className='h-9 w-10 rounded mr-3 bg-primary-xlight font-extrabold text-primary text-center'>
                    <h6 className='my-auto'>
                      {group?.name.slice(0, 2).toUpperCase()}
                    </h6>
                  </Stack>
                )}
                <Stack direction='column' className='!w-full'>
                  <Stack direction='row'>
                    <p className='font-bold pb-1'>{group?.name}</p>
                    <IconButton
                      id={`njangee-button-opt-${group?.id as string}`}
                      onClick={(event) => handleDropdownOpen(event, group?.id)}
                      aria-controls={
                        isOpen
                          ? `ngangee-drop-opt-${group?.id as string}`
                          : undefined
                      }
                      aria-haspopup='true'
                      aria-expanded={isOpen ? 'true' : undefined}
                      className='!w-auto !ml-auto !p-0'
                    >
                      <img alt='' src={ShowMoreDots} className='w-fit !p-0' />
                    </IconButton>
                    <Menu
                      id={`ngangee-drop-opt-${group?.id as string}`}
                      anchorEl={document.getElementById(
                        `njangee-button-opt-${group?.id as string}`
                      )}
                      open={isOpen}
                      MenuListProps={{
                        'aria-labelledby': `njangee-button-opt-${
                          group?.id as string
                        }`
                      }}
                      onClose={handleDropdownClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    >
                      <MenuItem
                        className='dropdown-item'
                        onClick={() => {
                          handleGroupNavigation(group?.id)
                        }}
                      >
                        <img alt='' src={ViewIcon} />
                        {t('general.common.view_details')}
                      </MenuItem>
                    </Menu>
                  </Stack>
                  <Stack
                    direction='row'
                    spacing={0.7}
                    alignItems='center'
                    className='flex-wrap'
                  >
                    <Stack direction='row' className='horizontal-images'>
                      {group.groupMembers.slice(0, 6).map((userPhoto: any) =>
                        userPhoto.user.photo === null ? (
                          <Stack
                            key={userPhoto?.id}
                            className='bg-primary-xlight font-extrabold text-center'
                          >
                            <small className='my-auto text-primary font-extrabold text-9'>
                              {userPhoto.user.firstName
                                .slice(0, 2)
                                .toUpperCase() ?? 'NA'}
                            </small>
                          </Stack>
                        ) : (
                          <img
                            key={userPhoto.user?.photo}
                            alt=''
                            src={userPhoto.user?.photo}
                            className='user-photo'
                          />
                        )
                      )}

                      {group.groupMembers.length > 6 && (
                        <Stack className='bg-secondary font-extrabold text-center'>
                          <small className='my-auto text-secondary'>
                            +{group.groupMembers.length - 6}
                          </small>
                        </Stack>
                      )}
                    </Stack>
                    <small className='text-secondary-light text-overline font-semibold'>
                      {group?.groupMembers.length ?? '0'} {t('groups.members')},
                    </small>
                  </Stack>
                </Stack>
              </Stack>

              <Stack className='w-full mt-3'>
                <span className='!w-full rounded-full bg-secondary-xlight h-1'>
                  <div
                    className='rounded-full bg-primary h-1'
                    style={{
                      width: `${
                        ((group?.currentRound - 1) /
                          group?.groupMembers?.length) *
                        100
                      }%`
                    }}
                  ></div>
                </span>
                <Stack direction='row'>
                  <small className='text-secondary-light text-overline font-semibold'>
                    {group?.currentRound - 1}
                  </small>
                  <small className='text-secondary-light text-overline font-semibold ml-auto'>
                    {group?.groupMembers?.length}
                  </small>
                </Stack>
              </Stack>

              <Stack className='my-4'>
                <p className='font-bold'>
                  {group?.currency} {group?.totalContributions}/
                  <span className='text-secondary-light'>
                    {group?.currency} {group?.totalContributionsToBeMade}
                    <span className='text-overline'> {t('groups.paid')}</span>
                  </span>
                </p>
              </Stack>

              <Stack
                direction='row'
                className='border-main border-b-w-1half pb-4 flex-wrap'
              >
                <small className=''>
                  {t('groups.contributions')}:
                  <span
                    className={`${getGroupStatusColors(
                      group?.status
                    )} font-semibold ml-1`}
                  >
                    {toTitleCase(group?.status)}
                  </span>
                </small>
                <small className='font-extrabold text-secondary ml-auto'>
                  {group?.currency} {group?.amount}/
                  <span className='small font-normal'>
                    {getGroupFrequencyString(group?.frequency)}
                  </span>
                </small>
              </Stack>

              {group?.nextContributionAndRecipient?.nextContribution !==
              null ? (
                <Stack className='mt-4'>
                  <p
                    role='button'
                    onClick={() => {
                      handlePlayNjangee(group)
                    }}
                    className='btn-with-icon btn-secondary flex'
                  >
                    <img alt='' src={PlayNjangeeIcon} className='mr-2' />{' '}
                    {t('general.common.play_njangee')}
                  </p>
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
          )
        })}

      {/* right  */}
      {/* <Stack direction='column' className='w-full single-group-box left-box'>
        <Stack direction='row' className='items-start mt-1'>
          <img alt='' src={BDGroup} className='h-fit w-fit mr-3' />
          <Stack direction='column' className='w-full'>
            <Stack direction='row'>
              <p className='font-bold pb-1'>Malingo Shops Smart Savings</p>
              <IconButton className='!w-auto !ml-auto !p-0'>
                <img alt='' src={ShowMoreDots} className='w-fit !p-0' />
              </IconButton>
            </Stack>
            <Stack
              direction='row'
              spacing={0.7}
              alignItems='center'
              className='flex-wrap'
            >
              <Stack direction='row' className='horizontal-images'>
                <img alt='' src={BDGroup} />
                <img alt='' src={MFGroup} />
                <img alt='' src={LDGroup} />
              </Stack>
              <small className='text-secondary-light text-overline font-semibold'>
                8 Members,
              </small>
              <small className='text-overline text-secondary font-semibold !ml-auto'>
                Ends On: <span className='text-th-black'>March 1st, 2024</span>
              </small>
            </Stack>
          </Stack>
        </Stack>

        <Stack className='w-full mt-3'>
          <span className='!w-full rounded-full bg-secondary-xlight h-1'>
            <div
              className='rounded-full bg-primary h-1'
              style={{ width: `${0}%` }}
            ></div>
          </span>
          <Stack direction='row'>
            <small className='text-secondary-light text-overline font-semibold'>
              0
            </small>
            <small className='text-secondary-light text-overline font-semibold ml-auto'>
              8
            </small>
          </Stack>
        </Stack>

        <Stack className='my-4'>
          <p className='font-bold'>XAF 0</p>
        </Stack>

        <Stack
          direction='row'
          className='border-main border-b-w-1half pb-4 flex-wrap'
        >
          <small className=''>
            Next Contribution:{' '}
            <span className='font-semibold'>Mar 2, 2023</span>
          </small>
          <small className='font-bold text-secondary ml-auto'>
            XAF 5000/
            <span className='small font-medium'>week</span>
          </small>
        </Stack>

        <Stack direction='row' alignItems='center ' className='mt-4'>
          <Link to='/user-groups/9/show' className='text-button-primary'>
            {t('general.common.view_details')}
          </Link>
        </Stack>
      </Stack> */}
    </div>
  )
}
