import React, { useState } from 'react'
import {
  Stack,
  FormControl,
  Box,
  Select,
  MenuItem,
  type SelectChangeEvent
} from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PlayNjangeeIcon from '../../../../images/icon_images/icon-play-njangee.svg'
import NextContributionIcon from '../../../../images/icon_images/icon-groups-nextCont.svg'
import GroupDetailContributionImg from '../../../../images/dashboard_images/groups-detail-contributions-img@2x.png'
import TotalContributionsIcon from '../../../../images/icon_images/icon-groups-total-contrib.svg'
import CompletedContributionsIcon from '../../../../images/icon_images/icon-groups-completedCont.svg'
import FailedContributionsIcon from '../../../../images/icon_images/icon-groups-failedContr.svg'
import TotalMembersIcon from '../../../../images/icon_images/icon-groups-totalMembers.svg'
import PendingContributionsIcon from '../../../../images/icon_images/icon-groups-pending-contrib.svg'
import { useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { groupDetailSelector } from '../slices/groupDetail'
import { convertToMonthInWords } from '../../../common/helper/convertToDate'
import { APP_LANGUAGE } from '../../../common/globals/contants'

export const GroupShowInsights: React.FC = () => {
  const [failedContributionsSelect, setFailedContributionsSelect] =
    useState('all')
  const groupDetail = useAppSelector(groupDetailSelector).group

  const { t } = useTranslation(['dashboard'])

  const handleFailedContribution = (event: SelectChangeEvent): void => {
    setFailedContributionsSelect(event.target.value)
  }
  return (
    <Stack
      direction='row'
      display='grid'
      className={`grid-cols-1 md:grid-cols-3 ${
        groupDetail?.nextContributionAndRecipient?.nextContribution !== null
          ? 'lg:grid-cols-4'
          : ''
      } gap-0 sm:gap-4`}
    >
      {groupDetail?.nextContributionAndRecipient?.nextContribution !== null ? (
        <Stack className='col-span-1 show-insight-box mx-auto w-fit mb-4 h-fit pt-4'>
          <Stack
            direction='row'
            className='w-full items-starts gap-4 flex-wrap xl:flex-nowrap px-4'
          >
            <img alt='' src={NextContributionIcon} className='' />
            <Stack>
              <h5 className='leading-7 mb-2'>
                {t('groups.next_contributions')}
              </h5>
              <p className='font-extra text-primary'>
                {convertToMonthInWords(
                  APP_LANGUAGE,
                  groupDetail?.nextContributionAndRecipient?.nextContribution
                    .date
                )}{' '}
                {/* <span className='text-black-2 font-extra'>( Today !)</span> */}
              </p>
            </Stack>
          </Stack>
          <Stack className=''>
            <img alt='' src={GroupDetailContributionImg} className='my-4' />
          </Stack>
          <Stack className='mt-auto'>
            <Link
              to='/user-groups/play-njangee'
              className='text-button-primary flex items-center mx-4 mb-4 mt-auto'
            >
              <img alt='' src={PlayNjangeeIcon} className='pr-2' />{' '}
              {t('general.common.play_njangee')}
            </Link>
          </Stack>
        </Stack>
      ) : (
        <span></span>
      )}

      <Stack
        direction='row'
        display='grid'
        className='col-span-1 md:col-span-2 lg:col-span-3 grid-cols-1 lg:grid-cols-3 gap-4'
      >
        <Stack
          className='show-insight-box pt-8 lg:pt-3 xl:pt-9'
          style={{ height: '160px' }}
        >
          <Stack direction='row' className='items-center gap-4 w-full px-4'>
            <img alt='' src={TotalContributionsIcon} />
            <Stack>
              <h5 className='leading-6'>
                {groupDetail?.currency ?? 'XAF'}{' '}
                {groupDetail?.contributionStats?.completedAmount ?? '0'}
              </h5>
              <p className='text-black-2'>{t('groups.total_contributions')}</p>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          className='show-insight-box pt-8 lg:pt-3 xl:pt-9'
          style={{ height: '160px' }}
        >
          <Stack direction='row' className='items-center gap-4 w-full px-4'>
            <img alt='' src={CompletedContributionsIcon} />
            <Stack>
              <h5 className='leading-6'>
                {groupDetail?.currency ?? 'XAF'}{' '}
                {groupDetail?.contributionStats?.completedAmount ?? '0'}
              </h5>
              <p className='text-black-2'>
                {t('groups.completed_contributions')}
              </p>
            </Stack>
          </Stack>
          <Stack direction='row' className='w-full mt-auto'>
            <Stack className='w-1/2 text-center py-2 px-1 border-r border-r-white bg-primary-2xlight rounded-bl-lg'>
              <small className='text-black-2 font-extra text-13 tracking-wide'>
                {t('groups.current_round')}:
                <span className='text-primary pl-1 font-extra'>
                  {groupDetail?.currentRound}
                </span>
              </small>
            </Stack>
            <Stack className='w-1/2 text-center py-2 px-1 border-l border-l-white bg-primary-2xlight rounded-br-lg'>
              <small className='text-black-2 font-extra text-13 tracking-wide'>
                {t('groups.members')}:
                <span className='text-primary-bold pl-1 font-extra'>
                  {groupDetail?.contributionStats?.totalCompleted ?? '0'}
                </span>
              </small>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          className='show-insight-box pt-8 lg:pt-3 xl:pt-9'
          style={{ height: '160px' }}
        >
          <Stack direction='row' className='items-center gap-4 w-full px-4'>
            <img alt='' src={PendingContributionsIcon} />
            <Stack>
              <h5 className='leading-6'>{groupDetail?.currency ?? 'XAF'} 0</h5>
              <p className='text-black-2'>
                {t('groups.pending_contributions')}
              </p>
            </Stack>
          </Stack>
          {/* <Stack direction='row' className='w-full mt-auto'> */}
          <Stack className='w-full mt-auto text-center py-2 px-2 border-t border-main  bg-light rounded-b-lg'>
            <small className='text-black-2 font-extra text-13 tracking-wide'>
              {t('groups.members')}:
              <span className='text-brown-light pl-1 font-extra'>0</span>
            </small>
          </Stack>
          {/* </Stack> */}
        </Stack>

        <Stack
          className='show-insight-box pt-8 lg:pt-3 xl:pt-9'
          style={{ height: '160px' }}
        >
          <Stack direction='row' className='items-center gap-4 w-full px-4'>
            <img alt='' src={FailedContributionsIcon} />
            <Stack>
              <h5 className='leading-6'>
                {groupDetail?.currency ?? 'XAF'}{' '}
                {groupDetail?.contributionStats?.failedAmount ?? '0'}
              </h5>
              <p className='text-danger'>{t('groups.failed_contributions')}</p>
            </Stack>
          </Stack>
          <Stack
            direction='row'
            className='w-full mt-auto flex-wrap py-2 px-4 border-t border-main bg-pink-xlight rounded-b-lg'
          >
            <small className='text-black-2 font-extra text-13 tracking-wide'>
              {t('groups.members')}:
              <span className='text-danger pl-1 font-extra'>
                {groupDetail?.contributionStats?.totalFailed ?? '0'}
              </span>
            </small>
            <Stack className='ml-auto'>
              <Box sx={{ minWidth: 85 }} borderRadius='9999px'>
                <FormControl
                  fullWidth
                  style={{ borderRadius: '9999px' }}
                  className='!border-0'
                >
                  {/* <InputLabel id='demo-simple-select-label' className='small'>Date Sorting</InputLabel> */}
                  <Select
                    // labelId='demo-simple-select-label'
                    id='date-sort-select'
                    value={failedContributionsSelect}
                    label=''
                    onChange={handleFailedContribution}
                    style={{ borderRadius: '9999px', height: '25px' }}
                    className='bg-primary-3xlight !border-0 small'
                  >
                    <MenuItem value='all' className='small'>
                      All
                    </MenuItem>
                    <MenuItem value='last-month' className='small'>
                      Last Month
                    </MenuItem>
                    <MenuItem value='last-year' className='small'>
                      Last Year
                    </MenuItem>
                    <MenuItem value='this-month' className='small'>
                      This Month
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          className='show-insight-box pt-8 lg:pt-3 xl:pt-9'
          style={{ height: '160px' }}
        >
          <Stack direction='row' className='items-center gap-4 w-full px-4'>
            <img alt='' src={TotalMembersIcon} />
            <Stack className=''>
              <h5 className='leading-6'>{groupDetail?.groupMembers?.length}</h5>
              <p className='text-black-2'>{t('groups.total_members')}</p>
            </Stack>
          </Stack>
          <Stack className='pl-24 mt-auto pb-3'>
            <Link to='?members' className='text-button-primary !mt-auto'>
              {t('general.common.see_all')}
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
