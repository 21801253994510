import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'
import { type AppMetaData } from '../../../common/globals/types'
import { APP_INITIAL_METADATA } from '../../../common/globals/initials'

export interface PlayNjangee {
  groupId: string | null
  cardNumber: number | null
  cardExpiryDate: Date | null
  cvc: number | null
  mobileMoneyNumber: string | null
  myGroups: { data: [], meta: AppMetaData }
  groupData: Record<string, any> | null
}

const initialState: PlayNjangee = {
  groupId: '',
  cardNumber: null,
  cardExpiryDate: new Date(),
  cvc: null,
  mobileMoneyNumber: '',
  myGroups: { data: [], meta: APP_INITIAL_METADATA },
  groupData: null
}

export const playNjangeeSlice = createSlice({
  name: 'play_njangee',
  initialState,
  reducers: {
    setPlayNjangeeData: (state, action: PayloadAction<PlayNjangee>) => {
      return {
        ...state,
        groupId: action.payload.groupId,
        groupData: action.payload.groupData,
        myGroups: action.payload.myGroups,
        cardNumber: action.payload.cardNumber,
        cardExpiryDate: action.payload.cardExpiryDate,
        mobileMoneyNumber: action.payload.mobileMoneyNumber,
        cvc: action.payload.cvc
      }
    }
  }
})

export const { setPlayNjangeeData } = playNjangeeSlice.actions
export const createPlayNjangeeSelector = (state: RootState): PlayNjangee =>
  state.createPlayNjangeeReducer
export default playNjangeeSlice.reducer
