import React, { useState } from 'react'
import { IconButton, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { GroupsModalSkeleton } from '../../groups/fragments/groupsModalSkeleton'
import { CloseOutlined } from '@mui/icons-material'
import IconPaypalPayment from '../../../../images/dashboard_images/icon-paypal.png'

import { useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { appPostRequest } from '../../../common/helper/httpRequests'
import { createUserAuthSelector } from '../../../amplify/slices/authSlices'
import { CircularLoadingProgress } from '../../../registration/fragments/circularProgress'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { isValidEmail } from '../../common/helper/validCreds'
interface IProps {
  handleClose: () => void
  open: boolean
  onPaymentAdded: () => void
}

export const AddPaypalDetails: React.FC<IProps> = ({
  handleClose,
  open,
  onPaymentAdded
}) => {
  const { t } = useTranslation(['dashboard'])
  const [isLoading, setIsLoading] = useState(false)
  const userData = useAppSelector(createUserAuthSelector)

  const formValidation = {
    name: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 4
      }
    },
    email: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 5,
        matchPattern: (v: string) => isValidEmail(v)
      }
    }
  }
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  // const handleUseAsDefaultPayment = (): void => {
  //   setSinglePaymentMethod({
  //     ...singlePaymentMethod,
  //     isDefault: !singlePaymentMethod.isDefault
  //   })
  // }
  const addNewPaymentMethod = async (data: any): Promise<void> => {
    const paymentDetails = {
      email: data.email,
      name: data.name
    }
    const pmData = {
      paymentGatewayId: 2,
      paymentDetails: JSON.stringify(paymentDetails),
      isDefault: data.isDefault
    }
    setIsLoading(true)
    const paymentInfo = await appPostRequest(
      `/users/payment-settings/${userData.user?.id as string}`,
      pmData
    )
    if (paymentInfo.status === 201) {
      onPaymentAdded()
      handleClose()
    } else {
      toast.error(t('settings.error_adding_payment_details'))
    }
    setIsLoading(false)
  }
  return (
    <GroupsModalSkeleton
      open={open}
      handleClose={handleClose}
      xsModalWidth='95%'
      smModalWidth='65%'
      mdModalWidth='55%'
      lgModalWidth='40%'
    >
      <form onSubmit={handleSubmit(addNewPaymentMethod)}>
        <Stack
          direction='row'
          className='flex items-center w-full justify-start gap-3 bg-primary text-th-white py-3 px-4 sm:px-7 rounded-t border'
        >
          <h6>{t('settings.add_payment_method')}</h6>
          <IconButton onClick={handleClose} className='!ml-auto'>
            <CloseOutlined className='text-primary-light' />
          </IconButton>
        </Stack>

        <Stack className='w-full px-4 sm:px-7 my-6 gap-4'>
          <Stack
            direction='row'
            className='w-full items-center rounded-md bg-light p-4 gap-3'
          >
            <img src={IconPaypalPayment} alt='' className='h-8 w-8' />
            <p className='font-semibold'>Paypal</p>
          </Stack>

          <Stack className='w-full gap-1'>
            <p className='font-semibold'>{t('general.common.email')}</p>

            <Stack className=''>
              <input
                {...register('email', formValidation.email)}
                placeholder='johndoe@gmail.com'
              />
            </Stack>
            {errors.email?.type === 'required' && (
              <small className='text-danger'>
                {t('registration.email_required')}
              </small>
            )}
            {errors.email?.type === 'minLength' && (
              <small className='text-danger'>
                {t('registration.email_has_atleast_5_characters')}
              </small>
            )}
            {errors.email?.type === 'matchPattern' && (
              <small className='text-danger'>
                {t('registration.must_be_valid_email')}
              </small>
            )}
          </Stack>
          <Stack className='w-full gap-1'>
            <p className='font-semibold'>{t('settings.paypal_name')}</p>
            {/* <Stack direction='row' className='w-full'>
            <Stack className='border border-secondary-xlight border-r-0 w-fit p-2 rounded rounded-r-none flex items-center justify-center'>
              <img src={CameroonFlag} alt='' className='h-7 w-12' />
            </Stack>
            <Stack className='w-full'>
              <input
                type='text'
                name='phone-number'
                placeholder='+237 6789512057'
                onChange={(e: any) => {
                  onChangeMobileNumber(e)
                }}
                className='border-l-0 !rounded-l-none'
              />
            </Stack>
          // </Stack> */}

            <Stack className=''>
              <input type='text' {...register('name', formValidation.name)} />
              {errors.name?.type === 'minLength' && (
                <small className='text-danger'>
                  {t('settings.paypal_name_must_contain_atleast_4_characters')}
                </small>
              )}
              {errors.name?.type === 'required' && (
                <small className='text-danger'>
                  {t('settings.paypal_name_is_required')}
                </small>
              )}
            </Stack>
          </Stack>
          <Stack direction='row' className='gap-3 items-center'>
            <input type='checkbox' {...register('isDefault')} />
            <label htmlFor='use-as-default'>
              {t('settings.use_as_default_payment')}
            </label>
          </Stack>
        </Stack>

        <Stack className='mt-auto px-4 sm:px-7'>
          <Stack
            direction='row'
            className='w-fit ml-auto items-center gap-5 sm:gap-10'
          >
            <button
              onClick={handleClose}
              className='border-0 rounded-none w-fit h-fit text-black-2 font-semibold'
            >
              {t('general.common.cancel')}
            </button>
            <button
              className='btn btn-primary flex gap-2'
              disabled={isLoading}
              // onClick={addNewPaymentMethod}
            >
              {isLoading && <CircularLoadingProgress />}
              {t('general.common.save_changes')}
            </button>
          </Stack>
        </Stack>
      </form>
      <br />
    </GroupsModalSkeleton>
  )
}
