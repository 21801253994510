import React, { type ReactElement } from 'react'
import {
  // Box,
  // FormControl,
  // IconButton,
  // InputLabel,
  // MenuItem,
  // Select,
  // type SelectChangeEvent,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material'
import { useTranslation } from 'react-i18next'
// import NavLeft from '../../../../images/icon_images/icon-nav-left-inactive.svg'
// import NavRight from '../../../../images/icon_images/icon-nav-right-active.svg'
import RecipientIcon from '../../../../images/icon_images/icon-recipient.svg'
import PaidIcon from '../../../../images/icon_images/icon-check-green.svg'
import PendingIcon from '../../../../images/icon_images/icon-hourglass-time-info.svg'
import { useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { groupDetailSelector } from '../slices/groupDetail'
import { formatToDateAndTimeString } from '../../../common/helper/convertToDate'

export const GroupShowContributions: React.FC = () => {
  // const [startDateFilter, setStartDateFilter] = useState('')
  // const [endDateFilter, setEndDateFilter] = useState('')
  // const [contributonStatusFilter, setContributonStatusFilter] = useState('')
  const { t } = useTranslation(['dashboard'])
  const groupDetail = useAppSelector(groupDetailSelector).group

  // const handleStartDateFilter = (event: SelectChangeEvent): void => {
  //   setStartDateFilter(event.target.value)
  // }
  // const handleEndDateFilter = (event: SelectChangeEvent): void => {
  //   setEndDateFilter(event.target.value)
  // }
  // const handleContributonStatusFilter = (event: SelectChangeEvent): void => {
  //   setContributonStatusFilter(event.target.value)
  // }

  const memberStatus = (
    isAdmin: boolean,
    isRecipient: boolean
  ): ReactElement => {
    if (isAdmin) {
      return (
        <Stack className='bg-light w-fit border border-secondary-xlight h-fit px-3 rounded-full text-black-2 font-semibold uppercase'>
          <p>Admin</p>
        </Stack>
      )
    }
    if (isRecipient) {
      return (
        <Stack
          direction='row'
          className='bg-primary-2xlight h-fit border border-primary-xlight px-2 sm:px-3 rounded-full font-semibold text-primary'
        >
          <span className='flex items-center gap-2'>
            <img alt='' src={RecipientIcon} /> {t('groups.recipient')}
          </span>
        </Stack>
      )
    } else {
      return <span></span>
    }
  }

  const tableData = (
    key: string,
    isAdmin: boolean,
    isRecipient: boolean,
    member: string,
    date: string,
    contributionStatus: string,
    amount: string
  ): React.ReactElement => {
    return (
      <React.Fragment key={key}>
        <TableRow key={`${key}-top-space`} className='!h-1'></TableRow>
        <TableRow
          key={`${key}-data`}
          className='!my-3 bg-white border border-secondary-xlight'
        >
          <TableCell className='w-5/12'>
            <Stack
              direction='row'
              className='items-center flex-wrap gap-4 font-extra'
            >
              {member}
              {memberStatus(isAdmin, isRecipient)}
            </Stack>
          </TableCell>
          <TableCell className='p text-base font-semibold'>
            {contributionStatus.toLowerCase() === 'paid' ? date : '-'}
          </TableCell>
          <TableCell className=''>
            {contributionStatus.toLocaleLowerCase() === 'paid' ? (
              <p className='flex items-center gap-2 font-semibold'>
                <img alt='' src={PaidIcon} />
                {contributionStatus}
              </p>
            ) : (
              <p className='flex items-center gap-2 font-semibold'>
                <img alt='' src={PendingIcon} />
                {contributionStatus}
              </p>
            )}
          </TableCell>
          <TableCell className='p font-semibold !text-base'>{amount}</TableCell>
        </TableRow>
        <TableRow key={`${key}-bottom-space`} className='!h-1'></TableRow>
      </React.Fragment>
    )
  }
  return (
    <>
      {/* <Stack
        direction='row'
        display='grid'
        className='w-full grid-cols-1 lg:grid-cols-3 gap-3 flex-wrap'
      >
        <Stack direction='row' className='w-full items-center col-span-1'>
          <h6 className='text-black-medium'>
            {t('groups.contribution_deadline')}:{' '}
            <span className='text-primary h6'>August 1, 2023</span>
          </h6>
        </Stack>
        <Stack
          direction='row'
          className='w-full items-center gap-3 col-span-2 mt-3 font-semibold flex-wrap'
        >
          <p>{t('groups.filter')}</p>

          <Stack className=''>
            <Box sx={{ minWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel
                  id='start-date-select-label'
                  className='font-semibold text-th-black '
                  style={{ top: '-7px' }}
                >
                  {t('groups.start_date')}
                </InputLabel>
                <Select
                  labelId='start-date-select-label'
                  id='start-date-select'
                  value={startDateFilter}
                  label='Start Date'
                  onChange={handleStartDateFilter}
                  style={{ borderRadius: '6px', height: '45px' }}
                  className='bg-white'
                >
                  <MenuItem value='' className='small'>--</MenuItem>
                  <MenuItem value='start-date-1' className=''>
                    Start Date 1
                  </MenuItem>
                  <MenuItem value='start-date-2' className=''>
                    Start Date 2
                  </MenuItem>
                  <MenuItem value='start-date-3' className=''>
                    Start Date 3
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack className=''>
            <Box sx={{ minWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel
                  id='end-date-select-label'
                  className='font-semibold text-th-black '
                  style={{ top: '-7px' }}
                >
                  {t('groups.end_date')}
                </InputLabel>
                <Select
                  labelId='end-date-select-label'
                  id='end-date-select'
                  value={endDateFilter}
                  label='End Date'
                  onChange={handleEndDateFilter}
                  style={{ borderRadius: '6px', height: '45px' }}
                  className='bg-white'
                >
                  <MenuItem value='' className='small'>--</MenuItem>
                  <MenuItem value='end-date-1' className=''>
                    End Date 1
                  </MenuItem>
                  <MenuItem value='end-date-2' className=''>
                    End Date 2
                  </MenuItem>
                  <MenuItem value='end-date-3' className=''>
                    End Date 3
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack className=''>
            <Box sx={{ minWidth: 195 }}>
              <FormControl fullWidth>
                <InputLabel
                  id='contribution-status-select-label'
                  className='font-semibold text-th-black '
                  style={{ top: '-7px' }}
                >
                  {t('groups.contribution_status')}
                </InputLabel>
                <Select
                  labelId='contribution-status-select-label'
                  id='contribution-status-select'
                  value={contributonStatusFilter}
                  label='Contribution Status'
                  onChange={handleContributonStatusFilter}
                  style={{ borderRadius: '6px', height: '45px' }}
                  className='bg-white'
                >
                  <MenuItem value='' className='small'>--</MenuItem>
                  <MenuItem value='contribution-status-1' className=''>
                    Contribution Status 1
                  </MenuItem>
                  <MenuItem value='contribution-status-2' className=''>
                    Contribution Status 2
                  </MenuItem>
                  <MenuItem value='contribution-status-3' className=''>
                    Contribution Status 3
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack direction='row' className='ml-auto items-center gap-5'>
            <p className='font-medium'>1 - 25 of 200</p>
            <Stack direction='row' className='gap-3'>
              <IconButton className='!p-0 !m-0'>
                <img alt='' src={NavLeft} />
              </IconButton>
              <IconButton className='!p-0 !m-0'>
                <img alt='' src={NavRight} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack> */}

      <br />
      <TableContainer>
        <Table sx={{ minWidth: '510px' }}>
          <TableHead
            sx={{
              border: '2px solid var(--primary-xlight)',
              backgroundColor: 'var(--primary-2xlight)'
            }}
          >
            <TableRow key='table-row-id' className='uppercase'>
              <TableCell
                sx={{ borderRight: '2px solid var(--primary-xlight)' }}
                className='w-5/12 font-extra'
              >
                {t('groups.member')}
              </TableCell>
              <TableCell
                sx={{ borderRight: '2px solid var(--primary-xlight)' }}
                className='font-extra border-r-2'
              >
                {t('groups.date')}
              </TableCell>
              <TableCell
                sx={{ borderRight: '2px solid var(--primary-xlight)' }}
                className='font-extra border-r-2'
              >
                {t('groups.contribution_status')}
              </TableCell>
              <TableCell className='font-extra'>{t('groups.amount')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className='!h-1'></TableRow>

            {groupDetail?.contributions?.map((contribution: any) =>
              tableData(
                contribution.id,
                groupDetail.userId === contribution.userId,
                false,
                `${contribution.user.firstName as string} ${
                  contribution.user.lastName as string
                }`,
                formatToDateAndTimeString(contribution.contributedAt),
                // toTitleCase(contribution.status),
                'PAID',
                `${groupDetail.currency as string} ${
                  contribution.amount as string
                }`
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
