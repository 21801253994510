import React, { type ReactElement, useState } from 'react'
import { Stack, IconButton } from '@mui/material'
import {
  DoneOutlined,
  ClearOutlined,
  Check,
  CloseOutlined,
  AccountCircle
} from '@mui/icons-material'
import ShowMoreDots from '../../../../images/icon_images/icon-more.svg'
import {
  createGetInvitationsSelector,
  setGetInvitations
} from '../../invitations/slices/getInvitationsSlice'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import { convertToMonthInWords } from '../../../common/helper/convertToDate'
import { createUserAuthSelector } from '../../../amplify/slices/authSlices'
import {
  appGetRequest,
  appPostRequest
} from '../../../common/helper/httpRequests'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { RejectInvitationModal } from '../../invitations/modals/rejectInvitation'
import { IsLoading } from '../../../registration/fragments/isLoading'
import { toTitleCase } from '../../../common/helper/strings/toTitleCase'
import { APP_LANGUAGE } from '../../../common/globals/contants'

export const RequestedInvitations: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setSetIsLoading] = useState(false)
  const userData = useAppSelector(createUserAuthSelector).user
  const [selectedInvitation, setSelectedInvitation] = useState(null)
  const { t } = useTranslation(['dashboard'])
  const dispatch = useAppDispatch()
  const requestedInvitations = useAppSelector(
    createGetInvitationsSelector
  ).invitations

  const handleDisplayModal = (invitation: any): void => {
    setSelectedInvitation(invitation)
    setShowModal(true)
  }

  const handleHideModal = (): void => {
    setShowModal(false)
  }

  const getInvitations = async (): Promise<void> => {
    try {
      const invData = await appGetRequest(
        `/users/${userData?.id as string}/invitations/received-by-email`
      )
      dispatch(
        setGetInvitations({
          ...requestedInvitations,
          invitations: invData.data.data,
          meta: invData.data.meta
        })
      )
    } catch (error) {}
  }

  const statusBadge = (status: string): ReactElement => {
    if (status.toUpperCase() === 'ACCEPTED') {
      return (
        <small className='invited-success-badge ml-auto'>
          <Check className='mr-1 text-overlined !w-3 !h-3' /> Invitation{' '}
          {toTitleCase(status)}
        </small>
      )
    } else {
      return (
        <small className='invited-failure-badge ml-auto'>
          <CloseOutlined className='mr-1 text-overlined !w-3 !h-3' /> Invitation{' '}
          {toTitleCase(status)}
        </small>
      )
    }
  }

  const updateInvitationStatus = async (
    data: Record<string, unknown>
  ): Promise<void> => {
    setSetIsLoading(true)
    try {
      const response = await appPostRequest(
        `/users/${userData?.id as string}/invitations/update`,
        data
      )
      if (response.status === 200) {
        await getInvitations()
        if (data.status === 'ACCEPTED') {
          toast.success(t('invitations.invitation_accepted'))
        }
        if (data.status === 'PENDING') {
          toast.success(t('invitations.invitation_resent'))
        }
        if (data.status === 'REJECTED') {
          toast.success(t('invitations.invitation_rejected'))
        }
      } else {
        if (data.status === 'ACCEPTED') {
          toast.error(t('invitations.error_accepting_invitation'))
        }
        if (data.status === 'PENDING') {
          toast.error(t('invitations.error_resending_invitation'))
        }
        if (data.status === 'REJECTED') {
          toast.error(t('invitations.error_rejecting_invitation'))
        }
      }
    } catch (error) {
    } finally {
      setShowModal(false)
      setSetIsLoading(false)
    }
  }
  return isLoading ? (
    <IsLoading />
  ) : (
    <div className='sent-invitation'>
      {requestedInvitations.slice(0, 5).map((invitation) => {
        return (
          <Stack key={invitation.id} direction='column' className='w-full'>
            <Stack
              direction='row'
              className='border-b-w-1half border-main w-full gap-3 items-start py-4 flex-wrap md:flex-nowrap'
            >
              {/* <img alt='' src={Invite1} className='invitation-img' /> */}
              {invitation.group.groupPhotoUrl !== null &&
              invitation.group.groupPhotoUrl !== '' ? (
                <img
                  alt=''
                  src={invitation.group.groupPhotoUrl}
                  className='invitation-img'
                />
              ) : (
                <AccountCircle className='!text-[50px]' />
              )}
              {/* <span></span> */}
              <Stack direction='column' className='w-full'>
                <Stack
                  direction='row'
                  className='border-b-w-1-dashed border-main w-full pb-4 !items-start flex-wrap'
                >
                  <p className='font-semibold pb-2 pr-2'>
                    {invitation.invitedBy.firstName}{' '}
                    {invitation.invitedBy.lastName ?? '-'}{' '}
                  </p>
                  {invitation.status !== 'PENDING' &&
                    statusBadge(invitation.status)}
                  {invitation.status === 'PENDING' && (
                    <Stack
                      direction='row'
                      className='ml-auto gap-3 items-start'
                    >
                      <button
                        className='btn-accept-invitation btn-primary'
                        type='button'
                        onClick={() => {
                          void updateInvitationStatus({
                            email: userData?.email,
                            status: 'ACCEPTED',
                            token: invitation.token
                          })
                        }}
                      >
                        <DoneOutlined className='mr-1 text-overlined !w-4 !h-4' />{' '}
                        {t('general.common.accept')}
                      </button>
                      <button
                        className='btn-reject-invitation'
                        onClick={() => {
                          handleDisplayModal(invitation)
                        }}
                      >
                        <ClearOutlined className='mr-1 text-overlined !w-4 !h-4' />{' '}
                        {t('general.common.reject')}
                      </button>
                      <RejectInvitationModal
                        groupInfo={selectedInvitation}
                        showModal={showModal}
                        handleReject={() => {
                          void updateInvitationStatus({
                            email: userData?.email,
                            status: 'REJECTED',
                            token: invitation?.token
                          })
                        }}
                        handleClose={handleHideModal}
                      />
                      <IconButton className='!w-auto !ml-auto !p-0'>
                        <img alt='' src={ShowMoreDots} className='w-fit !p-0' />
                      </IconButton>
                    </Stack>
                  )}
                </Stack>
                <Stack
                  direction='row'
                  className='ml-auto gap-4 lg:gap-6 pt-4 flex-wrap justify-end'
                >
                  <small className='flex items-center'>
                    <span className='text-primary-xlight font-bold'>
                      {t('groups.group')}:{' '}
                    </span>
                    {/* <img
                      alt=''
                      src={Invite2}
                      className='invite-group-img mx-1'
                    /> */}
                    {invitation.group.groupPhotoUrl !== null &&
                    invitation.group.groupPhotoUrl !== '' ? (
                      <img
                        alt=''
                        src={invitation.group.groupPhotoUrl}
                        className='invite-group-img mx-1'
                      />
                    ) : (
                      <AccountCircle className='h-5 w-5 rounded-full text-primary' />
                    )}
                    <span>
                      {invitation.invitedBy.firstName}{' '}
                      {invitation.invitedBy.lastName ?? '-'}{' '}
                    </span>
                  </small>
                  <small className='flex items-center'>
                    <span className='text-primary-xlight font-bold pr-1'>
                      {t('groups.group')}:{' '}
                    </span>
                    <span>{invitation.group.name}</span>
                  </small>
                  <small className='ml-auto text-secondary'>
                    {convertToMonthInWords(
                      APP_LANGUAGE,
                      invitation.updatedAt
                    )}
                  </small>
                </Stack>
              </Stack>
            </Stack>

            {/* <Stack direction='row' className='border-b-w-1half border-main w-full gap-3 items-start py-4 flex-wrap md:flex-nowrap'>
                    <img alt='' src={Invite2} className='invitation-img' />
                    <Stack direction='column' className='w-full'>
                        <Stack direction='row' className='border-b-w-1-dashed border-main w-full pb-4 !items-start flex-wrap'>
                            <p className='font-semibold pb-2 pr-2'>Jayme Marvin</p>
                            <Stack direction='row' className='ml-auto gap-3 items-start'>
                                <button className='btn-accept-invitation btn-primary'><DoneOutlined className='mr-1 text-overlined !w-4 !h-4' /> Accept</button>
                                <button className='btn-reject-invitation'><ClearOutlined className='mr-1 text-overlined !w-4 !h-4' /> Reject</button>
                                <IconButton className='!w-auto !ml-auto !p-0'>
                                    <img alt='' src={ShowMoreDots} className='w-fit !p-0' />
                                </IconButton>
                            </Stack>
                        </Stack>
                        <Stack direction='row' className='ml-auto gap-4 lg:gap-6 pt-4 flex-wrap justify-end'>
                            <small className='flex items-center'>
                                <span className='text-primary-xlight font-bold'>By: </span>
                                <img alt='' src={Invite2} className='invite-group-img mx-1' />
                                <span>Wilford Jacobson</span>
                            </small>
                            <small className='flex items-center'>
                                <span className='text-primary-xlight font-bold pr-1'>Group: </span>
                                <span>Back to School Njangee</span>
                            </small>
                            <small className='ml-auto text-secondary'>May 6, 2023</small>
                        </Stack>
                    </Stack>
                </Stack> */}
          </Stack>
        )
      })}
    </div>
  )
}
