import { formatDistanceToNow } from 'date-fns'

// export const convertStringToISODate = (date: string): string => {
//   const newDate = new Date(date)
//   const formattedDate = newDate.toISOString()
//   return formattedDate
// }

export const getTimeAgo = (dateValue: string): string => {
  const formattedDate = formatDistanceToNow(new Date(dateValue), {
    addSuffix: true
  })
  return formattedDate
}

export const getGroupFrequencyString = (frequency: string): string => {
  if (frequency === 'DAILY') {
    return 'day'
  } else if (frequency === 'WEEKLY') {
    return 'week'
  }
  if (frequency === 'BI_WEEKLY') {
    return '2 weeks'
  } else {
    return 'month'
  }
}
