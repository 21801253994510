import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState
} from 'react'
import { OnboardingSetupBox } from './setupBox'
import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CircularLoadingProgress } from '../fragments/circularProgress'
import { useForm } from 'react-hook-form'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  getFileTypeAndDisplay,
  getFileUrlTypeAndDisplaySized
} from '../../common/helper/functions'
import { ChevronLeft, FileUploadOutlined } from '@mui/icons-material'
import { convertStringToISODate } from '../../common/helper/convertToDate'
import { useNavigate } from 'react-router-dom'
import {
  createIdVerificationSelector,
  setIdVerification
} from '../../user/settings/slices/idVerificationSlice'
import { hasValidImage } from '../../common/helper/hasValidImage'
import { Auth } from 'aws-amplify'
import axios from 'axios'

interface IStepValue {
  nextStep: (arg: number) => void
}
export const ProofOfAddress: React.FC<IStepValue> = ({ nextStep }) => {
  const dispatch = useAppDispatch()
  const [showCircleProgress, setShowCircleProgress] = useState(false)
  const [proofOfAddress, setProofOfAddress] = useState('')
  const [proofOfFunds, setProofOfFunds] = useState('')
  const { t } = useTranslation(['dashboard'])
  const idVerification = useAppSelector(createIdVerificationSelector)
  const navigate = useNavigate()
  const api = process.env.REACT_APP_API as string
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const formValidation = {
    proof_of_address_doc_type: {
      required: !idVerification.isEditMode
    },
    proof_of_address: {
      required: !idVerification.isEditMode
    },
    proof_of_funds_doc_type: {
      required: !idVerification.isEditMode
    },
    proof_of_funds: {
      required: !idVerification.isEditMode
    }
  }

  const onChangeHandler = (value: string, e: any): void => {
    switch (value) {
      case 'proof_of_address_doc_type':
        dispatch(
          setIdVerification({
            ...idVerification,
            proof_of_address_doc_type: e.target.value as string
          })
        )
        break
      case 'proof_of_funds_doc_type':
        dispatch(
          setIdVerification({
            ...idVerification,
            proof_of_funds_doc_type: e.target.value as string
          })
        )
        break
      case 'proof-of-address':
        dispatch(
          setIdVerification({
            ...idVerification,
            proof_of_address: e.target.files[0]
          })
        )
        setProofOfAddress(URL.createObjectURL(e.target.files[0]))
        break
      case 'proof-of-funds':
        dispatch(
          setIdVerification({
            ...idVerification,
            proof_of_funds: e.target.files[0]
          })
        )
        setProofOfFunds(URL.createObjectURL(e.target.files[0]))
        break
    }
  }

  const isPassport = (): boolean => {
    if (idVerification.document_type.toUpperCase() === 'PASSPORT') {
      return true
    } else {
      return false
    }
  }

  const handleFormSubmit = async (): Promise<void> => {
    setShowCircleProgress(true)
    const frontImage = isPassport()
      ? idVerification.passport
      : idVerification.front_id
    const backImage = isPassport()
      ? null
      : hasValidImage(idVerification.back_id)
    const newData = {
      documentType: idVerification.document_type.toUpperCase(),
      documentNumber: idVerification.idNumber,
      issuedDate: convertStringToISODate(idVerification.issued_date),
      expiryDate: convertStringToISODate(idVerification.expiration_date),
      address: idVerification.address,
      frontPhoto: hasValidImage(frontImage),
      backPhoto: backImage,
      proofOfAddressDocType: idVerification.proof_of_address_doc_type,
      proofOfAddress: hasValidImage(idVerification.proof_of_address),
      proofOfFundsDocType: idVerification.proof_of_funds_doc_type,
      proofOfFunds: hasValidImage(idVerification.proof_of_funds),
      cityOfBirth: idVerification.cityOfBirth,
      stateOfBirth: idVerification.stateOfBirth,
      countryOfBirth: idVerification.countryOfBirth,
      currentCountry: idVerification.currentCountry,
      currentCity: idVerification.currentCity,
      gender: idVerification.gender
    }
    try {
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      const session = await axios.get(`${api}/session`, {
        headers: {
          Authorization: token
        }
      })
      const userId = session.data.data.id

      if (idVerification.isEditMode) {
        await axios.patch(
          `${process.env.REACT_APP_API as string}/kyc-records/user/${
            userId as string
          }`,
          newData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: token
            }
          }
        )
      } else {
        await axios.post(
          `${process.env.REACT_APP_API as string}/kyc-records/user/${
            userId as string
          }`,
          newData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: token
            }
          }
        )
      }
      navigate('/user')
    } catch (error) {
    } finally {
      setShowCircleProgress(false)
    }
  }

  useEffect(() => {
    const setPreview = (
      image: Blob | MediaSource,
      setPreviewFunction: Dispatch<SetStateAction<string>>
    ): void => {
      if (image instanceof Blob && image.size !== 0 && image.type !== '') {
        setPreviewFunction(URL.createObjectURL(image))
      }
    }
    setPreview(idVerification.proof_of_address, setProofOfAddress)
    setPreview(idVerification.proof_of_funds, setProofOfFunds)
  }, [])

  return (
    <OnboardingSetupBox>
      <Stack
        direction='row'
        className='w-full flex-wrap items-center rounded-t-2xl bg-primary-bold gap-3 py-4 px-5 sm:px-7 lg:px-10 text-th-white'
      >
        <p>{t('onboarding.lets_setup')}</p>
        <p className='ml-auto'>
          {t('settings.proof_of_address')} 3 {t('general.common.of')} 3
        </p>
      </Stack>
      <Stack
        direction='column'
        className='w-full h-full my-5 px-5 sm:px-10 lg:px-24'
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          {/* Proof of address */}
          <Stack direction='column' className='mt-3'>
            <h6 className='font-semibold mb-3'>
              {t('settings.proof_of_address')}
            </h6>
            <Stack className='w-full gap-2'>
              <p className='font-semibold'>{t('settings.document_type')}*</p>
              <select
                {...register(
                  'proof_of_address_doc_type',
                  formValidation.proof_of_address_doc_type
                )}
                value={`${idVerification.proof_of_address_doc_type}`}
                onChange={(e: any) => {
                  onChangeHandler('proof_of_address_doc_type', e)
                }}
              >
                <option value=''>{t('onboarding.select_document_type')}</option>
                <option value='Rents' className=''>
                  {t('onboarding.rents')}
                </option>
                <option value='Government issued document' className=''>
                  {t('onboarding.goverment_issued_doc')}
                </option>
                <option value='Payslips' className=''>
                  {t('onboarding.payslips')}
                </option>
                <option
                  value='Utility (electricity, gas, water, phone bills etc)'
                  className=''
                >
                  {t('onboarding.utility_doc')}
                </option>
              </select>
              {errors.proof_of_address_doc_type?.type === 'required' && (
                <small className='text-danger'>
                  {t('onboarding.this_document_type_is_required')}
                </small>
              )}
            </Stack>
            <Stack
              direction='row'
              className='w-full gap-4 !grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-2'
            >
              {proofOfAddress !== '' &&
                idVerification.proofOfAddressUrl !== '' && (
                  <Stack
                    direction='column'
                    className='!flex sm:!hidden w-fit items-center mx-auto justify-center col-span-1 bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
                  >
                    {proofOfAddress !== ''
                      ? getFileTypeAndDisplay(
                          proofOfAddress,
                          idVerification.proof_of_address,
                          'PASSPORT'
                        )
                      : getFileUrlTypeAndDisplaySized(
                          idVerification.proofOfAddressUrl,
                          'PASSPORT'
                        )}
                  </Stack>
                )}
              <Stack
                className={`col-span-1 ${
                  proofOfAddress === '' &&
                  idVerification.proofOfAddressUrl === ''
                    ? 'lg:col-span-3'
                    : 'lg:col-span-2'
                } w-full`}
              >
                <Stack
                  direction='column'
                  className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4 mb-3'
                >
                  <p className='text-th-black font-extra'>
                    {t('settings.upload_proof_of_address')}
                  </p>
                  <small className='text-secondary mb-2'>
                    {t('onboarding.upload_proof_of_address_caption')}
                    <br />
                    {t('settings.accepted_files')}: jpeg, png{' '}
                    {t('general.common.and')} pdf
                  </small>
                  <Button variant='text' component='label' className=''>
                    <p className='py-2 px-5 font-extra text-primary-bold border border-secondary-light rounded !normal-case !bg-slate-200 flex items-center gap-1'>
                      <FileUploadOutlined /> {t('settings.upload_file')}
                    </p>
                    <input
                      type='file'
                      hidden
                      {...register(
                        'proof_of_address',
                        formValidation.proof_of_address
                      )}
                      accept='image/jpeg,image/jpg,image/png,application/pdf'
                      onChange={(e: any) => {
                        onChangeHandler('proof-of-address', e)
                      }}
                    />
                  </Button>
                </Stack>
                {errors.proof_of_address?.type === 'required' && (
                  <small className='text-danger'>
                    {t('onboarding.please_upload_proof_of_address')}
                  </small>
                )}
              </Stack>

              {proofOfAddress === '' &&
              idVerification.proofOfAddressUrl === '' ? (
                <span></span>
              ) : (
                <Stack
                  direction='column'
                  className='!hidden w-full h-auto items-center mx-auto justify-center sm:!flex bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
                >
                  {proofOfAddress !== ''
                    ? getFileTypeAndDisplay(
                        proofOfAddress,
                        idVerification.proof_of_address,
                        'PASSPORT'
                      )
                    : getFileUrlTypeAndDisplaySized(
                        idVerification.proofOfAddressUrl,
                        'PASSPORT'
                      )}
                </Stack>
              )}
            </Stack>
          </Stack>
          <br />

          {/* Proof of funds */}

          <Stack direction='column' className='mt-3'>
            <h6 className='font-semibold mb-3'>
              {t('onboarding.proof_of_funds')}
            </h6>
            <Stack className='w-full gap-2'>
              <p className='font-semibold'>{t('settings.document_type')}*</p>
              <select
                {...register(
                  'proof_of_funds_doc_type',
                  formValidation.proof_of_funds_doc_type
                )}
                value={`${idVerification.proof_of_funds_doc_type}`}
                onChange={(e: any) => {
                  onChangeHandler('proof_of_funds_doc_type', e)
                }}
              >
                <option value=''>{t('onboarding.select_document_type')}</option>
                <option value='Rents' className=''>
                  {t('onboarding.rents')}
                </option>
                <option value='Government issued document' className=''>
                  {t('onboarding.goverment_issued_doc')}
                </option>
                <option value='Payslips' className=''>
                  {t('onboarding.payslips')}
                </option>
                <option
                  value='Utility (electricity, gas, water, phone bills etc)'
                  className=''
                >
                  {t('onboarding.utility_doc')}
                </option>
              </select>
              {errors.proof_of_funds_doc_type?.type === 'required' && (
                <small className='text-danger'>
                  {t('onboarding.this_document_type_is_required')}
                </small>
              )}
            </Stack>
            <Stack
              direction='row'
              className='w-full gap-4 !grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-2'
            >
              {proofOfFunds !== '' && (
                <Stack
                  direction='column'
                  className='!flex sm:!hidden w-fit items-center mx-auto justify-center col-span-1 bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
                >
                  {/* {proofOfFunds !== '' &&
                    getFileTypeAndDisplay(
                      proofOfFunds,
                      idVerification.proof_of_funds,
                      'PASSPORT'
                    )} */}
                  {proofOfFunds === '' &&
                  idVerification.proofOfFundsUrl === '' ? (
                    <span></span>
                  ) : (
                    <Stack
                      direction='column'
                      className='!flex sm:!hidden w-fit items-center mx-auto justify-center col-span-1 bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
                    >
                      {proofOfFunds !== ''
                        ? getFileTypeAndDisplay(
                            proofOfFunds,
                            idVerification.proof_of_funds,
                            'PASSPORT'
                          )
                        : getFileUrlTypeAndDisplaySized(
                            idVerification.proofOfFundsUrl,
                            'PASSPORT'
                          )}
                    </Stack>
                  )}
                </Stack>
              )}
              <Stack
                className={`col-span-1 ${
                  proofOfFunds === '' && idVerification.proofOfFundsUrl === ''
                    ? 'lg:col-span-3'
                    : 'lg:col-span-2'
                } w-full`}
              >
                <Stack
                  direction='column'
                  className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4 mb-3'
                >
                  <p className='text-th-black font-extra'>
                    {t('onboarding.upload_proof_of_funds')}
                  </p>
                  <small className='text-secondary mb-2'>
                    {t('onboarding.upload_proof_of_funds_caption')}
                    <br />
                    {t('settings.accepted_files')}: jpeg, png{' '}
                    {t('general.common.and')} pdf
                  </small>
                  <Button variant='text' component='label' className=''>
                    <p className='py-2 px-5 font-extra text-primary-bold border border-secondary-light rounded !normal-case !bg-slate-200 flex items-center gap-1'>
                      <FileUploadOutlined /> {t('settings.upload_file')}
                    </p>
                    <input
                      type='file'
                      hidden
                      {...register(
                        'proof_of_funds',
                        formValidation.proof_of_funds
                      )}
                      accept='image/jpeg,image/jpg,image/png,application/pdf'
                      onChange={(e: any) => {
                        onChangeHandler('proof-of-funds', e)
                      }}
                    />
                  </Button>
                </Stack>
                {errors.proof_of_funds?.type === 'required' && (
                  <small className='text-danger'>
                    {t('onboarding.please_upload_proof_of_funds')}
                  </small>
                )}
              </Stack>

              {proofOfFunds === '' && idVerification.proofOfFundsUrl === '' ? (
                <span></span>
              ) : (
                <Stack
                  direction='column'
                  className='!hidden w-full h-auto items-center mx-auto justify-center sm:!flex bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
                >
                  {proofOfFunds !== ''
                    ? getFileTypeAndDisplay(
                        proofOfFunds,
                        idVerification.proof_of_funds,
                        'PASSPORT'
                      )
                    : getFileUrlTypeAndDisplaySized(
                        idVerification.proofOfFundsUrl,
                        'PASSPORT'
                      )}
                </Stack>
              )}
            </Stack>
          </Stack>
          <Stack direction='row' className='w-full items-center mt-auto mb-2'>
            <Stack>
              <Button
                variant='text'
                type='button'
                className='!normal-case flex items-center text-primary font-extra !text-base'
                onClick={() => {
                  nextStep(2)
                }}
              >
                <ChevronLeft /> {t('general.common.back')}
              </Button>
            </Stack>
            <Stack direction='row' className='items-center gap-5 ml-auto'>
              {/* <button
                type='button'
                className='border-0 bg-none font-semibold hover:underline !hidden sm:!block'
                onClick={() => {
                  skipOnboarding()
                }}
              >
                {t('onboarding.skip_for_now')}
              </button> */}
              <button
                className='btn btn-primary flex gap-3 justify-center mt-5'
                disabled={showCircleProgress}
              >
                {showCircleProgress && <CircularLoadingProgress />}{' '}
                {t('general.common.submit')}
              </button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </OnboardingSetupBox>
  )
}
