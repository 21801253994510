import React, { type ReactElement, useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '@mui/material'
import {
  ChevronRightOutlined,
  ChevronLeftOutlined,
  AddCircleOutline
} from '@mui/icons-material'
import { UserDashBoardLayout } from '../common/dashboardUiLayout'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PayBgImage from '../../../images/dashboard_images/icons-playNjangee-img.png'
import MasterCardLogo from '../../../images/dashboard_images/icon-playNjagee-mastercard.png'
import VisaCardLogo from '../../../images/dashboard_images/icon-playNjagee-visa.png'
import MTNMoMoLogo from '../../../images/dashboard_images/icon-playNjagee-mtnmomo.png'
import OrangeMoneyLogo from '../../../images/dashboard_images/icon-playNjagee-orangeMoney.png'
import CheckCicleIcon from '../../../images/icon_images/icon-check-circle.svg'
import RecipientIcon from '../../../images/icon_images/icon-recipient.svg'
import NoNgangeeImg from '../../../images/dashboard_images/no_njangee_img.png'
import { PlayNjangeeConfirmPayment } from './fragments/playNjangeeConfrimPayment'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  createPlayNjangeeSelector,
  setPlayNjangeeData
} from './slices/playNjangeeSlice'
import { appGetRequest, appPostRequest } from '../../common/helper/httpRequests'
import { IsLoading } from '../../registration/fragments/isLoading'
import { createUserAuthSelector } from '../../amplify/slices/authSlices'
import { toast } from 'react-toastify'
import { PayPalButtons } from '@paypal/react-paypal-js'
import { formatToReadableDate } from '../../common/helper/convertToDate'

declare global {
  interface Window {
    CinetPay: any
  }
}
const CINETPAY_BASE_URL = 'https://api-checkout.cinetpay.com/v2'
export const PlayNjangee: React.FC = () => {
  const [activePayment, setActivePayment] = useState('mastercard')
  const playNjangeeState = useAppSelector(createPlayNjangeeSelector)
  const userData = useAppSelector(createUserAuthSelector).user
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0)
  const dispatch = useAppDispatch()
  const [openConfirmPayment, setOpenConfirmPayment] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()

  const handleSetActiveGroup = (goTo: string): void => {
    const nextGroupIndex =
      goTo === 'next' ? currentGroupIndex + 1 : currentGroupIndex - 1

    if (
      nextGroupIndex < 0 ||
      nextGroupIndex >= playNjangeeState.myGroups.data.length
    ) {
      return
    }
    setCurrentGroupIndex(nextGroupIndex)
    dispatch(
      setPlayNjangeeData({
        ...playNjangeeState,
        groupData: playNjangeeState.myGroups.data[nextGroupIndex]
      })
    )
  }

  const handleSelectActiveGroup = (e: any): void => {
    const selectedGroupId = e.target.value

    const selectedGroup = playNjangeeState.myGroups.data.find(
      (group: any) => group.id === selectedGroupId
    )

    if (selectedGroup !== undefined && selectedGroup !== null) {
      const selectedGroupIndex = playNjangeeState.myGroups.data.findIndex(
        (group: any) => group.id === selectedGroupId
      )

      setCurrentGroupIndex(selectedGroupIndex)
      dispatch(
        setPlayNjangeeData({
          ...playNjangeeState,
          groupData: selectedGroup
        })
      )
    }
  }

  const onChangePaymentMethod = (val: string): void => {
    setActivePayment(val)
  }
  const showCheck = (val: string): any => {
    return (
      activePayment === val && (
        <img
          alt=''
          src={CheckCicleIcon}
          className='w-fit h-fit absolute'
          style={{ bottom: '-15px' }}
        />
      )
    )
  }

  // const openConfirmPaymentF = (): void => {
  //   setOpenConfirmPayment(true)
  // }
  const closeConfirmPaymentF = (): void => {
    setOpenConfirmPayment(false)
  }

  const getGroups = async (): Promise<void> => {
    setIsLoading(true)

    try {
      const response = await appGetRequest('/groups/my-groups')
      const groups = response.data.data
      if (groups.length > 0) {
        if (
          playNjangeeState.groupData === null &&
          playNjangeeState.groupData === undefined
        ) {
          const initialGroupIndex =
            currentGroupIndex >= 0 ? currentGroupIndex : 0
          setCurrentGroupIndex(initialGroupIndex)
          dispatch(
            setPlayNjangeeData({
              ...playNjangeeState,
              myGroups: response?.data,
              groupData: groups[initialGroupIndex]
            })
          )
        } else {
          const selectedGroupIndex = groups.findIndex(
            (group: any) => group.id === playNjangeeState.groupData?.id
          )
          const groupIndex = selectedGroupIndex >= 0 ? selectedGroupIndex : 0
          setCurrentGroupIndex(groupIndex)

          dispatch(
            setPlayNjangeeData({
              ...playNjangeeState,
              myGroups: response?.data,
              groupData: groups[groupIndex]
            })
          )
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  const groupView = (groupDetails: any): ReactElement => {
    return (
      <Stack
        direction='column'
        className='col-span-1 lg:col-span-2 bg-primary w-full bg-image-full py-3 rounded-l-md'
      >
        <Stack direction='row' className='items-center gap-2 my-3 ml-auto'>
          <IconButton
            className='!p-0 !m-0'
            onClick={() => {
              handleSetActiveGroup('prev')
            }}
          >
            <ChevronLeftOutlined
              fontSize='large'
              className={
                currentGroupIndex === 0 ? 'text-primary-light' : 'text-light'
              }
            />
          </IconButton>
          <IconButton
            className='!p-0 !m-0'
            onClick={() => {
              handleSetActiveGroup('next')
            }}
          >
            <ChevronRightOutlined
              fontSize='large'
              className={`${
                playNjangeeState.myGroups.data[currentGroupIndex + 1] ===
                undefined
                  ? 'text-primary-light'
                  : 'text-light'
              }`}
            />
          </IconButton>
        </Stack>

        <Stack className='w-full pb-4 px-4 sm:px-5 lg:px-8'>
          <Stack
            direction='row'
            className='items-center flex-wrap xl:flex-nowrap gap-4 border-b border-b-white border-dashed pb-5'
          >
            {groupDetails?.groupPhoto !== null &&
            groupDetails?.groupPhoto !== undefined ? (
              <img
                alt=''
                src={groupDetails?.groupPhoto}
                className='!h-28 !w-28 sm:!h-32 sm:!w-32 lg:!h-44 lg:!w-44 rounded'
              />
            ) : (
              <Stack className='!h-28 !w-28 sm:!h-32 sm:!w-32 lg:!h-44 lg:!w-44 rounded mr-3 bg-primary-xlight font-extrabold text-center'>
                <p className='my-auto text-primary-medium font-extrabold text-[40px] md:text-[70px]'>
                  {groupDetails?.name.slice(0, 2).toUpperCase()}
                </p>
              </Stack>
            )}
            <Stack direction='column'>
              <h3 className='text-primary-xlight leading-tight'>
                {groupDetails?.name}
              </h3>
              <h5 className='text-th-white mt-2'>
                {groupDetails?.currency ?? 'XAF'} {groupDetails?.amount ?? '-'}
              </h5>
              <small className='text-th-white mt-2'>
                {t('groups.contribution_round')}:{' '}
                <span className='font-bold'>
                  {groupDetails?.currentRound ?? '-'}
                </span>
              </small>
              {groupDetails?.currentRound !== groupDetails?.nextRound && (
                <small className='text-th-white mt-2'>
                  {t('groups.next_contribution_round')}:{' '}
                  {groupDetails?.nextRound}
                  <span className='font-bold'></span>
                </small>
              )}

              <small className='text-th-white mt-2'>
                {t('groups.contribution_expiry_date')}:{' '}
                <span className='font-bold'>
                  {formatToReadableDate(
                    groupDetails?.nextContributionAndRecipient?.nextContribution
                      ?.date, '-'
                  ) ?? '-'}
                </span>
              </small>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction='row'
          className='items-start gap-5 px-4 sm:px-5 lg:px-8 h-full w-full'
          style={{
            backgroundImage: `url(${PayBgImage})`,
            backgroundPositionX: 'left',
            backgroundPositionY: 'bottom',
            backgroundSize: 'auto 80%',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Stack
            direction='row'
            className='bg-primary-2xlight h-fit border border-primary-xlight px-2 sm:px-3 rounded-full font-semibold text-primary'
          >
            <span className='flex items-center gap-2 text-12'>
              <img alt='' src={RecipientIcon} /> {t('groups.recipient')}
            </span>
          </Stack>
          <h6 className='font-semibold text-th-white'>
            {groupDetails?.nextContributionAndRecipient?.nextRecipient?.user
              ?.firstName ?? '-'}{' '}
            {groupDetails?.nextContributionAndRecipient?.nextRecipient?.user
              ?.lastName ?? '-'}
          </h6>
        </Stack>
      </Stack>
    )
  }

  const getAmount = (): string => {
    if (playNjangeeState.groupData?.currency === 'USD') {
      return playNjangeeState.groupData?.amount.toFixed(2)
    } else {
      return `${(playNjangeeState.groupData?.amount / 550).toFixed(2)}`
    }
  }

  const onCreateOrder = async (data: any, actions: any): Promise<string> => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: getAmount()
          }
        }
      ]
    })
  }

  const onApproveOrder = async (data: any, actions: any): Promise<any> => {
    try {
      await appPostRequest(`/transactions/${String(userData.id ?? '')}`, {
        groupId: playNjangeeState.groupData?.id ?? '',
        paymentGatewayId: 2,
        amount: playNjangeeState.groupData?.amount ?? '',
        status: 'SUCCESSFUL',
        currentRound: playNjangeeState.groupData?.nextRound,
        transactionMetaData: JSON.stringify(data)
      })
      toast.success(t('groups.play_njangee_success_toast'))
      navigate(
        `/user-groups/${String(playNjangeeState.groupData?.id ?? '')}/show`
      )
    } catch (error) {
      toast.error(t('groups.error_saving_payment_data'))
    }
    return actions.order.capture().then(() => {
      // const name = details.payer.name.given_name
    })
  }

  const handlePayment = async (): Promise<void> => {
    const transId = `trans_${new Date().getTime()}`

    // Trigger the payment process
    window.CinetPay.getCheckout({
      transaction_id: transId,
      amount: '101',
      currency: 'XAF',
      channels: 'ALL',
      description: 'Payment description',
      customer_name: 'Njangee',
      customer_surname: 'App',
      customer_email: 'etangkencliffandockt@gmail.com',
      customer_phone_number: '123456789'
    })

    window.CinetPay.waitResponse(async (response: any) => {
      if (response.status === 'ACCEPTED') {
        try {
          const metaData = JSON.stringify({
            transaction_id: transId,
            response
          })
          const data = {
            groupId: playNjangeeState.groupData?.id ?? '',
            paymentGatewayId: 1,
            amount: response.amount,
            currentRound: playNjangeeState.groupData?.nextRound,
            status: response.status === 'ACCEPTED' ? 'SUCCESSFUL' : 'FAILED',
            transactionMetaData: metaData
          }
          await appPostRequest(
            `transactions/${String(userData.id ?? '')}`,
            data
          )
          toast.success(t('groups.play_njangee_success_toast'))
          navigate(
            `/user-groups/${String(playNjangeeState.groupData?.id ?? '')}/show`
          )
        } catch (error) {
          toast.error(t('groups.error_saving_payment_data'))
        }
      } else {
        toast.error(t('groups.error_initiating_payment'))
      }
    })

    window.CinetPay.onError(() => {})
  }

  useEffect(() => {
    void getGroups()

    const script = document.createElement('script')
    script.src = process.env.REACT_APP_CINETPAY_BASE_URL ?? ''
    script.async = true

    script.onload = () => {
      window.CinetPay.setConfig({
        apikey: process.env.REACT_APP_CINETPAY_API_KEY ?? '',
        site_id: process.env.REACT_APP_CINETPAY_SITE_ID ?? '',
        notify_url: CINETPAY_BASE_URL
      })
    }

    document.body.appendChild(script)
  }, [])
  return (
    <UserDashBoardLayout>
      {isLoading ? (
        <IsLoading />
      ) : (
        <Stack className='play-njangee px-4 md:px-12 py-4'>
          <Stack direction='row' className='items-start flex-wrap gap-5 py-3'>
            <Stack>
              {' '}
              <h4>{t('general.common.play_njangee')}</h4>
            </Stack>
            <Stack className='ml-auto'>
              <Box sx={{ minWidth: 130 }}>
                <FormControl fullWidth>
                  <InputLabel
                    id='frequency-select-label'
                    className='font-semibold text-th-black '
                    style={{ top: '-7px' }}
                  >
                    Select Group
                  </InputLabel>
                  <Select
                    labelId='frequency-select-label'
                    id='frequency-select'
                    value={playNjangeeState.groupData?.id}
                    label='Frequency'
                    onChange={(e: any) => {
                      handleSelectActiveGroup(e)
                    }}
                    style={{ borderRadius: '6px', height: '45px' }}
                    className='bg-white'
                  >
                    {playNjangeeState.myGroups.data.length > 0 ? (
                      playNjangeeState.myGroups.data.map(
                        (group: any, index: number) => {
                          return (
                            <MenuItem
                              key={group?.id}
                              value={group?.id}
                              className=''
                            >
                              {group?.name}
                            </MenuItem>
                          )
                        }
                      )
                    ) : (
                      <MenuItem value='' className=''>
                        --
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
          </Stack>

          {playNjangeeState.myGroups.data.length === 0 ? (
            <Stack
              direction='column'
              className='min-h-[50vh] items-center justify-center w-full bg-primary-5xlight py-10'
            >
              <Stack>
                <img
                  src={NoNgangeeImg}
                  className='w-32 md:w-56 lg:w-72 mx-auto mb-6'
                  alt=''
                />
                <h4 className='text-center'>{t('groups.no_njangee')}</h4>
                <p className='text-black2 text-center'>
                  {t('groups.no_njangee_caption')}
                </p>
              </Stack>
              <button
                // onClick={onClickShowModal}
                className='btn btn-primary mt-8 bg-primary-2xlight'
              >
                {t('groups.view_all_upcoming_njangee')}
              </button>
            </Stack>
          ) : (
            <Stack
              direction='row'
              display='grid'
              className='grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 border border-secondary-xlight rounded-md'
            >
              {groupView(playNjangeeState.groupData)}

              <Stack className='col-span-1 lg:col-span-3 bg-white py-8 px-5 md:pl-8 md:pr-10 rounded-r-md'>
                <Stack
                  direction='row'
                  className='items-center gap-5 flex-wrap w-full'
                >
                  <h6>{t('groups.select_payment_method')}</h6>
                  <Link
                    to='/user-settings?payment-methods'
                    className='text-button-primary flex items-center ml-auto'
                  >
                    <AddCircleOutline
                      fontSize='small'
                      className='mr-1 text-primary-light'
                    />{' '}
                    {t('groups.add_new_payment_method')}
                  </Link>
                </Stack>

                <Stack
                  direction='row'
                  display='grid'
                  className='items-center grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-flow-dense gap-2 mt-9 w-full md:w-fit'
                >
                  <Stack
                    direction='column'
                    className={`items-center justify-center border border-primary-2xlight rounded-md py-3 px-3 sm:px-6 cursor-pointer relative ${
                      activePayment === 'mastercard'
                        ? 'active-payment-method'
                        : 'hover:bg-slate-50'
                    }`}
                    onClick={() => {
                      onChangePaymentMethod('mastercard')
                    }}
                  >
                    <img
                      alt=''
                      src={MasterCardLogo}
                      className='!w-16 !h-16 sm:!w-24 sm:!h-24'
                    />
                    <p className='text-black-2 font-semibold'>Mastercard</p>
                    {showCheck('mastercard')}
                  </Stack>

                  <Stack
                    direction='column'
                    className={`items-center justify-center border border-primary-2xlight rounded-md py-3 px-3 sm:px-6 cursor-pointer relative ${
                      activePayment === 'visacard'
                        ? 'active-payment-method'
                        : 'hover:bg-slate-50'
                    }`}
                    onClick={() => {
                      onChangePaymentMethod('visacard')
                    }}
                  >
                    <img
                      alt=''
                      src={VisaCardLogo}
                      className='!w-16 !h-16 sm:!w-24 sm:!h-24'
                    />
                    <p className='text-black-2 font-semibold'>Visa</p>
                    {showCheck('visacard')}
                  </Stack>

                  <Stack
                    direction='column'
                    className={`items-center justify-center border border-primary-2xlight rounded-md py-3 px-3 sm:px-6 cursor-pointer relative ${
                      activePayment === 'mtnmomo'
                        ? 'active-payment-method'
                        : 'hover:bg-slate-50'
                    }`}
                    onClick={() => {
                      onChangePaymentMethod('mtnmomo')
                    }}
                  >
                    <img
                      alt=''
                      src={MTNMoMoLogo}
                      className='!w-16 !h-16 sm:!w-24 sm:!h-24'
                    />
                    <p className='text-black-2 font-semibold'>MTN MoMo</p>
                    {showCheck('mtnmomo')}
                  </Stack>

                  <Stack
                    direction='column'
                    className={`items-center justify-center border border-primary-2xlight rounded-md py-3 px-3 sm:px-6 cursor-pointer relative ${
                      activePayment === 'orangemoney'
                        ? 'active-payment-method'
                        : 'hover:bg-slate-50'
                    }`}
                    onClick={() => {
                      onChangePaymentMethod('orangemoney')
                    }}
                  >
                    <img
                      alt=''
                      src={OrangeMoneyLogo}
                      className='!w-16 !h-16 sm:!w-24 sm:!h-24'
                    />
                    <p className='text-black-2 font-semibold'>Orange Money</p>
                    {showCheck('orangemoney')}
                  </Stack>
                </Stack>

                {/* {(activePayment === 'mastercard' ||
                  activePayment === 'visacard') && (
                  <Stack className='w-full mt-6 gap-5'>
                    <Stack className='w-full'>
                      <p className='font-semibold'>{t('groups.card_number')}</p>
                      <TextInputField
                        type='text'
                        addClass='bg-light'
                        placeholder='4125 2568 5263 3685'
                        name='card-number'
                      />
                    </Stack>
                    <Stack
                      direction='row'
                      display='grid'
                      className='grid-cols-1 md:grid-cols-2 gap-4'
                    >
                      <Stack className='w-full'>
                        <p className='font-semibold'>
                          {t('groups.expiration_date')}
                        </p>
                        <TextInputField
                          type='date'
                          addClass='bg-light'
                          placeholder='MM / YY'
                          name='expiration-date'
                        />
                      </Stack>
                      <Stack>
                        <Stack direction='row' className='items-center w-full'>
                          <p className='font-semibold'>CVC</p>
                          <InfoOutlined
                            fontSize='small'
                            className='text-primary-medium ml-auto'
                          />
                        </Stack>
                        <TextInputField
                          type='text'
                          addClass='bg-light'
                          placeholder='---'
                          name='card-number'
                        />
                      </Stack>
                    </Stack>
                    <Stack className='w-full mt-1'>
                      <Link to='/' className='text-button-primary w-fit'>
                        {t('groups.edit_payment_method')}
                      </Link>
                    </Stack>
                  </Stack>
                )} */}

                {/* {(activePayment === 'mtnmomo' ||
                  activePayment === 'orangemoney') && (
                  <Stack className='w-full mt-6 gap-5'>
                    <Stack className='w-full'>
                      <p className='font-semibold'>
                        {activePayment === 'mtnmomo' ? 'MTN' : 'Orange'}{' '}
                        {t('groups.mobile_money_number')}
                      </p>
                      <Stack direction='row' className='w-full'>
                        <Stack className='w-full'>
                          <Stack className=''>
                            <PhoneInput
                              country={'cm'}
                              enableSearch={true}

                              inputStyle={{ width: '100%' }}
                              placeholder='+237 678 3562 65'
                              onChange={(value) => {
                              }}
                            />
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                )} */}

                <Stack className='w-full mt-16'>
                  {activePayment === 'visacard' ||
                  activePayment === 'mastercard' ? (
                    <PayPalButtons
                      style={{ layout: 'vertical' }}
                      createOrder={async (data, actions) =>
                        await onCreateOrder(data, actions)
                      }
                      onApprove={async (data, actions) =>
                        await onApproveOrder(data, actions)
                      }
                    />
                  ) : (
                    <button
                      onClick={() => {
                        // openConfirmPaymentF()
                        if (
                          activePayment === 'mtnmomo' ||
                          activePayment === 'orangemoney'
                        ) {
                          void handlePayment()
                        }
                      }}
                      className='btn btn-primary ml-auto'
                    >
                      {activePayment === 'mtnmomo' ||
                      activePayment === 'orangemoney'
                        ? 'Pay With Momo'
                        : t('gr}oups.make_payment')}
                    </button>
                  )}
                </Stack>
                <PlayNjangeeConfirmPayment
                  open={openConfirmPayment}
                  handleClose={closeConfirmPaymentF}
                  paymentMethod={activePayment}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </UserDashBoardLayout>
  )
}
