const notificationSlugs: string[] = [
  'contribution',
  'distribution',
  'group',
  'group_admin'
]
export const NotificationFunctions = {
  getUrlGroupString (
    customContent: string | null | undefined,
    slug: string
  ): string {
    if (
      customContent === null ||
      customContent === undefined ||
      customContent === '' ||
      !notificationSlugs.includes(slug)
    ) {
      return '#'
    }

    try {
      const decodedContent = JSON.parse(customContent)
      return decodedContent?.groupId !== null &&
        decodedContent?.groupId !== undefined
        ? `/user-groups/${decodedContent.groupId as string}/show`
        : '#'
    } catch {
      return '#' // Fallback for invalid JSON
    }
  }
}
