import { Auth } from 'aws-amplify'
import axios from 'axios'

export const appApi = process.env.REACT_APP_API as string

export const appGetRequest = async (url: string): Promise<any> => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken

    const response = await axios.get(`${appApi}${url}`, {
      headers: {
        Authorization: token
      }
    })
    return response
  } catch (error) {
    return error
  }
}

export const appPostRequest = async (
  url: string,
  data: Record<string, unknown>
): Promise<any> => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken

    const response = await axios.post(`${appApi}${url}`, data, {
      headers: {
        Authorization: token
      }
    })
    return response
  } catch (error) {
    return error
  }
}
export const appPatchRequest = async (
  url: string,
  data?: Record<string, unknown> | null
): Promise<any> => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken

    const response = await axios.patch(`${appApi}${url}`, data, {
      headers: {
        Authorization: token
      }
    })
    return response
  } catch (error) {
    return error
  }
}

export const appDeleteRequest = async (url: string): Promise<any> => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken

    const response = await axios.delete(`${appApi}${url}`, {
      headers: {
        Authorization: token
      }
    })
    return response
  } catch (error) {
    return error
  }
}
