import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Toolbar,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Button
} from '@mui/material'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LogoSmall from '../../../images/logo/njangee-logo-white.png'
import PlayIcon from '../../../images/icon_images/icon-play.svg'
import AddIcon from '../../../images/icon_images/icon-add.svg'
import DropdownIcon from '../../../images/icon_images/icon-dropdown.svg'
import DashboardIcon from '../../../images/icon_images/icon-dashboard.svg'
import GroupsIcon from '../../../images/icon_images/icon-group.svg'
import PaymentIcon from '../../../images/icon_images/icon-payment.svg'
import InvitationsIcon from '../../../images/icon_images/icon-invitations.svg'
import { LanguageDropdown } from './fragments/language_dropDown'
import { NotificationAndSettings } from './fragments/notifAndSettings'
import { MobileHeader } from './fragments/mobileHeader'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  showModalF,
  showModalSelector
} from '../njangeeGroupModals/slices/showModalSlice'
import {
  setLoggedInUser,
  createLoggedInUserSelector
} from '../../amplify/slices/loggedInUserInfo'
import { NjangeeModalWizard } from '../njangeeGroupModals/modalWizard'
import { Auth } from 'aws-amplify'
import {
  createUserAuthSelector,
  setUserAuth
} from '../../amplify/slices/authSlices'

export const Header: React.FC = () => {
  const [addNewButtonDrop, setAddNewButtonDrop] = useState<null | HTMLElement>(
    null
  )
  const openAddNew = Boolean(addNewButtonDrop)
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()
  const loggedInUser = useAppSelector(createLoggedInUserSelector)
  const showModalState = useAppSelector(showModalSelector)
  const userData = useAppSelector(createUserAuthSelector)
  const dispatch = useAppDispatch()
  const onClickShowModal = (): void => {
    dispatch(showModalF({ ...showModalState, showModal: true }))
    setAddNewButtonDrop(null)
  }
  const showAddNewDropDown = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAddNewButtonDrop(event.currentTarget)
  }
  const closeAddNewDropDown = (): void => {
    setAddNewButtonDrop(null)
  }
  const signOut = async (): Promise<any> => {
    dispatch(setUserAuth({ ...userData, isLoggingOut: true }))
    console.log('Logging')
    try {
      await Auth.signOut()
      localStorage.setItem('njangee_signUpType', 'email')
      localStorage.removeItem('njangee_jwt_token')
      navigate('/login')
      dispatch(setUserAuth({ ...userData, isLoggingOut: false }))
      location.reload()
    } catch (error) {}
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        const attributes = res.attributes
        dispatch(
          setLoggedInUser({
            ...loggedInUser,
            id: attributes.sub,
            firstName: attributes.given_name,
            lastName: attributes.family_name,
            email: attributes.email
          })
        )
        localStorage.setItem(
          'njangee_jwt_token',
          res.signInUserSession.idToken.jwtToken
        )
      })
      .catch(() => {
        localStorage.removeItem('njangee_jwt_token')
      })
  }, [])
  return (
    <div className='user-dashboard-header'>
      <AppBar
        position='sticky'
        className='bg-primary app-bar !shadow-none desktop-nav !hidden lg:!flex'
      >
        <Toolbar className='h-full flex flex-wrap md:gap-8'>
          <Stack direction='row' alignItems='center' className='gap-8'>
            <Link to='/'>
              <IconButton
                size='large'
                edge='start'
                color='inherit'
                aria-label='logo'
              >
                <img alt='' src={LogoSmall} className='' />
              </IconButton>
            </Link>
            <Stack
              direction='row'
              spacing={2}
              className='flex items-center nav-menu'
            >
              <NavLink
                className={({ isActive }) =>
                  isActive ? 'flex items-center activeNav' : 'flex items-center'
                }
                to='/user'
              >
                <img
                  src={DashboardIcon}
                  alt=''
                  className='pr-2 nav-menu-icon'
                />{' '}
                {t('general.navigation.dashboard')}
              </NavLink>

              <NavLink
                className={({ isActive }) =>
                  isActive ? 'flex items-center activeNav' : 'flex items-center'
                }
                to='/user-groups'
              >
                <img src={GroupsIcon} alt='' className='pr-2 nav-menu-icon' />{' '}
                {t('general.navigation.groups')}
              </NavLink>

              <NavLink
                className={({ isActive }) =>
                  isActive ? 'flex items-center activeNav' : 'flex items-center'
                }
                to='/user-payments'
              >
                <img src={PaymentIcon} alt='' className='pr-2 nav-menu-icon' />{' '}
                {t('general.navigation.payments')}
              </NavLink>

              {/* <NavLink
                className={({ isActive }) =>
                  isActive ? 'flex items-center activeNav' : 'flex items-center'
                }
                to='/dsfjdkjk'
              >
                <img
                  src={AnnouncementsIcon}
                  alt=''
                  className='pr-2 nav-menu-icon'
                />{' '}
                {t('general.navigation.announcements')}
              </NavLink> */}

              <NavLink
                className={({ isActive }) =>
                  isActive ? 'flex items-center activeNav' : 'flex items-center'
                }
                to='/user-invitations'
              >
                <img
                  src={InvitationsIcon}
                  alt=''
                  className='pr-2 nav-menu-icon'
                />{' '}
                {t('dashboard.invitations')}
              </NavLink>
            </Stack>
          </Stack>

          <Stack
            direction='row'
            className='h-full nav-menu-2 lg:ml-16 !ml-auto '
          >
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? 'flex items-center nav-menu2-child activeNav2'
                  : 'flex items-center nav-menu2-child'
              }
              to='/user-groups/play-njangee'
            >
              <img alt='' src={PlayIcon} className='pr-3' />{' '}
              {t('general.navigation.play')}
            </NavLink>

            <div className='flex items-center nav-menu2-child'>
              <Button
                id='add-new-button'
                variant='text'
                onClick={showAddNewDropDown}
                aria-controls={openAddNew ? 'add-new-drop' : undefined}
                aria-haspopup='true'
                aria-expanded={openAddNew ? 'true' : undefined}
              >
                <img alt='' src={AddIcon} className='pr-3' />
                {t('general.navigation.add_new')}
                <img alt='' src={DropdownIcon} className='pl-2' />
              </Button>
              <Menu
                id='add-new-drop'
                anchorEl={addNewButtonDrop}
                open={openAddNew}
                MenuListProps={{
                  'aria-labelledby': 'add-new-button'
                }}
                onClose={closeAddNewDropDown}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <MenuItem onClick={onClickShowModal}>
                  <span className='small text-black-2 font-semibold py-1 flex items-center'>
                    {t('general.common.njangee_group')}
                  </span>
                </MenuItem>
                <MenuItem>
                  <Link
                    className='small text-black-2 font-semibold py-1 flex items-center'
                    to='/user-settings?payment-methods'
                  >
                    {t('general.common.payment_method')}
                  </Link>
                </MenuItem>
              </Menu>
            </div>
            <NjangeeModalWizard />
            <LanguageDropdown />
            <NotificationAndSettings signOut={signOut} />
          </Stack>
        </Toolbar>
      </AppBar>

      {/* <MobileLeftMenu /> */}
      <MobileHeader signOut={signOut} />
    </div>
  )
}
