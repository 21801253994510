import React, { useState } from 'react'
import {
  Stack,
  Box,
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent,
  InputLabel
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import PhoneIcon from '../../../../images/icon_images/icon-phone.svg'
import EmailIcon from '../../../../images/icon_images/icon-email.svg'
// import EditIcon from '../../../../images/icon_images/icon-edit-2.svg'
// import ReceivedIcon from '../../../../images/icon_images/icon-check-green.svg'
// import TodayIcon from '../../../../images/icon_images/icon-hourglass-time.svg'
import SendIcon from '../../../../images/icon_images/icon-send-primary.svg'
// import UpdcomingIcon from '../../../../images/icon_images/icon-hourglass-time-info.svg'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import { groupDetailSelector, setGroupDetail } from '../slices/groupDetail'
import { AccountCircle } from '@mui/icons-material'
import { appPatchRequest } from '../../../common/helper/httpRequests'
import { isNullOrEmpty } from '../../../common/helper/functions'
import { convertToMonthInWords } from '../../../common/helper/convertToDate'
import {
  showModalF,
  showModalSelector
} from '../../njangeeGroupModals/slices/showModalSlice'
import { toTitleCase } from '../../../common/helper/strings/toTitleCase'
import { APP_LANGUAGE } from '../../../common/globals/contants'
import { createUserAuthSelector } from '../../../amplify/slices/authSlices'

export const GroupShowMembers: React.FC = () => {
  const [sortingOrderFilter, setSortingOrderFilter] = useState('')
  const { t } = useTranslation(['dashboard'])
  const groupDetail = useAppSelector(groupDetailSelector)
  const userData = useAppSelector(createUserAuthSelector)
  const showModalState = useAppSelector(showModalSelector)
  const [members, setMembers] = useState(groupDetail?.group?.groupMembers)
  const dispatch = useAppDispatch()
  const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null)

  const handleDragStart = (index: number): void => {
    setDraggedItemIndex(index)
  }

  const handleDragOver = (event: React.DragEvent): void => {
    event.preventDefault()
  }

  const handleDrop = async (index: number): Promise<void> => {
    if (draggedItemIndex === null) return

    let updatedItems = [...members]
    let ordersToSave = []
    const [movedItem] = updatedItems.splice(draggedItemIndex, 1)
    updatedItems.splice(index, 0, movedItem)
    updatedItems = updatedItems.map((uMember: any, index: number) => {
      return { ...uMember, order: index + 1 }
    })

    setMembers(updatedItems)
    setDraggedItemIndex(null)
    ordersToSave = updatedItems.map((member: any) => {
      return { id: member.id, order: member.order, name: member.firstName }
    })
    const orderData = JSON.stringify(ordersToSave)
    const responseData = await appPatchRequest(
      `/groups/${groupDetail.group.id as string}/update-distribution-order`,
      { orderData }
    )
    if (
      responseData.status !== null &&
      responseData.status !== undefined &&
      responseData.status === 200
    ) {
      dispatch(
        setGroupDetail({ ...groupDetail, group: responseData.data.data })
      )
    }
  }

  const onClickShowModal = (): void => {
    dispatch(
      showModalF({
        ...showModalState,
        showModal: true,
        modalIndex: 4,
        skipCongrats: true
      })
    )
  }
  const handleSortingOrderFilter = (event: SelectChangeEvent): void => {
    setSortingOrderFilter(event.target.value)
  }
  // const njangeeStatus = (status: string, date: string): ReactElement => {
  //   if (status === 'Received') {
  //     return (
  //       <>
  //         <span className='text-success font-semibold flex items-center'>
  //           <img alt='' src={ReceivedIcon} className='pr-1' /> {status}{' '}
  //         </span>
  //         <span className='text-secondary gap-1'>
  //           {t('groups.on_date')} {date}
  //         </span>
  //       </>
  //     )
  //   } else if (status === 'Today') {
  //     return (
  //       <>
  //         <span className='text-primary font-semibold flex items-center'>
  //           <img alt='' src={TodayIcon} className='pr-1' /> {status}{' '}
  //         </span>
  //         <span className='text-secondary gap-1'>
  //           {t('groups.on_date')} {date}
  //         </span>
  //       </>
  //     )
  //   } else {
  //     return (
  //       <>
  //         <span className='text-info font-semibold flex items-center'>
  //           <img alt='' src={UpdcomingIcon} className='pr-1' /> {status}{' '}
  //         </span>
  //         <span className='text-secondary gap-1'>
  //           {t('groups.on_date')} {date}
  //         </span>
  //       </>
  //     )
  //   }
  // }

  // useEffect(() => {
  //   setMembers(groupDetail?.group?.groupMembers)
  // }, [members])

  return (
    <Stack className='w-full my-3'>
      <Stack direction='row' className='w-full gap-4 flex-wrap mb-6'>
        <Stack
          direction='row'
          className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 items-center'
        >
          <Stack
            className='bg-white pl-3 pr-1 border-main border border-r-0 rounded-md !rounded-r-none'
            style={{ height: '45px' }}
          >
            <p className='my-auto'>{t('groups.sort')}:</p>
          </Stack>
          <Box sx={{ width: '100%' }} className='mr-2'>
            <FormControl fullWidth className='!border-l-0 relative'>
              <InputLabel
                id='sort-order-select-label'
                className='font-semibold text-th-black '
                style={{ top: '-7px' }}
              >
                {t('groups.alphabetically')} A - Z
              </InputLabel>
              <Select
                labelId='sort-order-select-label'
                id='sort-order-select'
                value={sortingOrderFilter}
                label='Sort: Alphabetically A - Z'
                onChange={handleSortingOrderFilter}
                style={{ height: '45px' }}
                className='bg-white !border-l-none rounded-md !rounded-l-none'
              >
                <MenuItem value='sort-order-1' className=''>
                  Frequency 1
                </MenuItem>
                <MenuItem value='sort-order-2' className=''>
                  Frequency 2
                </MenuItem>
                <MenuItem value='sort-order-3' className=''>
                  Frequency 3
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
        {userData.user?.id === groupDetail?.group?.userId && (
          <Stack className='ml-auto'>
            <button
              type='button'
              onClick={onClickShowModal}
              className='btn-with-icon btn-secondary gap-2'
            >
              <img src={SendIcon} alt='' />
              {t('main_modal.invite_group_members')}
            </button>
          </Stack>
        )}
      </Stack>
      <Stack
        direction='row'
        display='grid'
        className='grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 w-full'
      >
        {members.map((member: any, index: number) => {
          return (
            <Stack
              key={member.id}
              className='w-full border border-main rounded bg-white'
              style={{ minHeight: '130px' }}
              draggable={groupDetail.group.contribution_type === 'CUSTOM'}
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={handleDragOver}
              onDrop={() => {
                void handleDrop(index)
              }}
            >
              <Stack
                direction='row'
                className='lg:flex-wrap xl:flex-nowrap py-3 px-3 items-start gap-3'
              >
                <Stack direction='column' className='items-center'>
                  <Stack className='w-fit h-fit'>
                    {member.photo !== null ? (
                      <img
                        alt=''
                        src={member.photo}
                        style={{
                          height: '60px !important',
                          width: '60px !important'
                        }}
                        className='rounded-full h-10 w-10'
                      />
                    ) : (
                      <AccountCircle className='!text-[60px]' />
                    )}
                  </Stack>
                  {groupDetail.group.userId === member.userId && (
                    <Stack className='bg-primary-light text-light uppercase px-2 rounded-full h-fit w-fit flex mt-1'>
                      <small className='text-10'>Admin</small>
                    </Stack>
                  )}
                </Stack>
                <Stack className='w-full'>
                  <Stack
                    direction='row'
                    className='w-full border-b border-main pb-1 relative border-dotted'
                  >
                    <span className='flex gap-2 items-center'>
                      <p className='font-extra'>
                        {member.firstName} {member.lastName ?? ''}
                      </p>
                      <div className='bg-primary h-4 w-4 rounded-full text-white text-center text-xs'>
                        {' '}
                        <small>{member.order}</small>
                      </div>
                    </span>
                    {/* <Link to='/' className='absolute right-0 top-0'>
                      <img alt='' src={EditIcon} />
                    </Link> */}
                  </Stack>
                  <Stack className='gap-1 py-2 pr-2'>
                    <small className='text-secondary flex  items-center'>
                      <img alt='' src={PhoneIcon} className='pr-1' />{' '}
                      {!isNullOrEmpty(member.mobile) ? member.mobile : '-'}
                    </small>
                    <div className='small text-secondary flex items-center'>
                      <img alt='' src={EmailIcon} className='pr-1' />{' '}
                      <small className='break-all'> {member.email}</small>
                    </div>
                    {/* </Stack> */}
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction='row'
                className='w-full bg-light mt-auto py-2 px-3 border-t border-main rounded-b'
              >
                <small className='flex items-center gap-1 flex-wrap'>
                  {toTitleCase(t('groups.date'))}:
                  <span className='text-secondary gap-1'>
                    {convertToMonthInWords(APP_LANGUAGE, member?.createdAt)}
                  </span>
                </small>
              </Stack>
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  )
}
