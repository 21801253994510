import React from 'react'
import {
  Box,
  CircularProgress,
  type CircularProgressProps,
  circularProgressClasses
} from '@mui/material'

interface ICircularLoadingProgress {
  props?: CircularProgressProps
  color?: string | null
}
export const CircularLoadingProgress: React.FC<ICircularLoadingProgress> = ({
  props,
  color
}) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant='determinate'
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
        }}
        size={25}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === 'light'
              ? `${color ?? '#1a90ff'}`
              : '#308fe8',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={25}
        thickness={4}
        {...props}
      />
    </Box>
  )
}
