import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UserDashBoardLayout } from '../common/dashboardUiLayout'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  showModalF,
  showModalSelector
} from '../njangeeGroupModals/slices/showModalSlice'
import { GroupsTopBoxes } from './groups_components/topBoxes'
import { DisplayAllGroups } from './groups_components/allGroups'
import React, { useState } from 'react'
import {
  createGetGroupsSelector,
  type IFilterGroupParams,
  setGroupFilters,
  setGroups
} from './slices/getGroupSlice'
import { appGetRequest } from '../../common/helper/httpRequests'
import { getPageNumber } from '../common/helper/routeFunctions'

export const UserGroups: React.FC = () => {
  const { t } = useTranslation(['dashboard'])
  const dispatch = useAppDispatch()
  const showModalState = useAppSelector(showModalSelector)
  const allGroups = useAppSelector(createGetGroupsSelector)
  const [isActiveNav, setIsActiveTab] = useState(allGroups.activeNav)
  // const [filterParams, setFilterParams] = useState<IFilterGroupParams>({
  //   ...allGroups.filters
  // })

  const onClickShowModal = (): void => {
    dispatch(showModalF({ ...showModalState, showModal: true }))
  }

  const getQueryParams = (
    paramsData: IFilterGroupParams
  ): Record<string, string> => {
    const params: Record<string, string> = { ...paramsData }
    params.page = paramsData.page?.toString() ?? getPageNumber().toString()
    if (paramsData.createdByMe !== '') {
      params.createdByMe = paramsData.createdByMe?.toString() ?? ''
    }

    if (paramsData.joinedByMe !== '') {
      params.joinedByMe = paramsData.joinedByMe?.toString() ?? ''
    }

    const filteredParams = Object.keys(params)
      .filter((key) => params[key as keyof IFilterGroupParams] !== '')
      .reduce<Record<string, string>>((obj, key) => {
        obj[key] = params[key as keyof IFilterGroupParams]
        return obj
      }, {})

    dispatch(setGroupFilters({ ...allGroups, filters: filteredParams }))
    return filteredParams
  }

  const handleActiveNav = async (nav: string): Promise<void> => {
    setIsActiveTab(nav)

    dispatch(
      setGroups({
        ...allGroups,
        isLoading: true,
        activeNav: nav
      })
    )
    let currentNav = { ...allGroups.filters }
    if (nav === 'createdByMe') {
      currentNav = {
        ...allGroups.filters,
        createdByMe: 'true',
        joinedByMe: ''
      }
    } else if (nav === 'joinedByMe') {
      currentNav = {
        ...allGroups.filters,
        createdByMe: '',
        joinedByMe: 'true'
      }
    } else {
      currentNav = {
        ...allGroups.filters,
        createdByMe: '',
        joinedByMe: ''
      }
    }
    void getGroups(currentNav)
  }

  const getGroups = async (allParams: IFilterGroupParams): Promise<void> => {
    let fetchedGroups = []
    const paramsData = getQueryParams(allParams)
    const searchParams = new URLSearchParams(paramsData)
    try {
      const groups = await appGetRequest(`/groups/?${searchParams.toString()}`)
      fetchedGroups = groups.data
      if (fetchedGroups !== null || fetchedGroups !== undefined) {
        dispatch(
          setGroups({
            ...allGroups,
            groups: fetchedGroups.data,
            meta: fetchedGroups.meta,
            isLoading: false
          })
        )
      }
    } catch (error) {
      dispatch(
        setGroups({
          ...allGroups,
          isLoading: false
        })
      )
    }
  }
  return (
    <div className='user-groups'>
      <UserDashBoardLayout>
        <Stack direction='column' width='100%' className='pb-4 w-full'>
          <Stack
            direction='column'
            style={{ minHeight: '80px' }}
            className='w-full bg-white px-4 md:px-12'
          >
            <Stack
              direction='row'
              className='w-full !h-full !items-center flex-wrap md:flex-nowrap gap-4 md:gap-20 pt-5'
              alignItems='center'
            >
              <h4 className='pb-2 w-full '>
                {t('general.common.njangee_groups')}
              </h4>

              <button
                onClick={onClickShowModal}
                className='btn btn-primary w-full mx-auto md:ml-auto '
              >
                {t('dashboard.create_new_njangi_group')}
              </button>
            </Stack>
            <Stack
              direction='row'
              className='justify-start gap-2 mt-2 overflow-y-hidden'
            >
              <Stack
                onClick={() => {
                  void handleActiveNav('')
                }}
                className={`inline-nav-section ${
                  isActiveNav === '' ? 'inline-active-nav' : ''
                }`}
              >
                <p className=''>
                  {t('groups.all_my_groups')}:{' '}
                  <span className='text-primary-xlight'>
                    {' '}
                    {isActiveNav === '' && (allGroups.meta?.totalGroups ?? '0')}
                  </span>
                </p>
              </Stack>
              <Stack
                onClick={() => {
                  void handleActiveNav('createdByMe')
                }}
                className={`inline-nav-section ${
                  isActiveNav === 'createdByMe' ? 'inline-active-nav' : ''
                }`}
              >
                <p className=''>
                  {t('groups.groups_created')}:{' '}
                  <span className='text-secondary-light'>
                    {' '}
                    {isActiveNav === 'createdByMe' &&
                      (allGroups.meta?.totalGroups ?? '0')}
                  </span>
                </p>
              </Stack>
              <Stack
                onClick={() => {
                  void handleActiveNav('joinedByMe')
                }}
                className={`inline-nav-section ${
                  isActiveNav === 'joinedByMe' ? 'inline-active-nav' : ''
                }`}
              >
                <p className=''>
                  {t('groups.groups_joined')}:{' '}
                  <span className='text-secondary-light'>
                    {' '}
                    {isActiveNav === 'joinedByMe' &&
                      (allGroups.meta?.totalGroups ?? '0')}
                  </span>
                </p>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction='column'
            width='100%'
            className='px-4 md:px-12 w-full '
          >
            <GroupsTopBoxes />
            <Stack direction='column' className='w-full'>
              <DisplayAllGroups />
            </Stack>
          </Stack>
        </Stack>
      </UserDashBoardLayout>
    </div>
  )
}
