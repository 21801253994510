import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'
import { type AppMetaData } from '../../../common/globals/types'
import { APP_INITIAL_METADATA } from '../../../common/globals/initials'

export interface IFilterGroupParams {
  page?: string
  limit?: string
  type?: string
  orderType?: string
  frequency?: string
  search?: string
  createdByMe?: string
  joinedByMe?: string
  sort?: string
}

export interface GetGroups {
  filters: IFilterGroupParams
  groups: any
  meta: AppMetaData
  isLoading: boolean
  activeNav: string
}

const initialState: GetGroups = {
  filters: {
    page: '1',
    limit: '10',
    type: '',
    orderType: '',
    frequency: '',
    search: '',
    createdByMe: '',
    joinedByMe: '',
    sort: ''
  },
  groups: [],
  meta: APP_INITIAL_METADATA,
  isLoading: true,
  activeNav: ''
}

export const createGetGroupsSlice = createSlice({
  name: 'get_groups',
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<GetGroups>) => {
      return {
        ...state,
        groups: action.payload.groups,
        meta: action.payload.meta,
        isLoading: action.payload.isLoading,
        activeNav: action.payload.activeNav
      }
    },
    setGroupFilters: (state, action: PayloadAction<GetGroups>) => {
      return {
        ...state,
        filters: action.payload.filters
      }
    }
  }
})

export const { setGroups, setGroupFilters } = createGetGroupsSlice.actions

export const createGetGroupsSelector = (state: RootState): GetGroups =>
  state.createGetGroupsReducer
export default createGetGroupsSlice.reducer
