import React, { type ReactNode } from 'react'
import { DashBoardFooter } from './footer'
import { Header } from './header'
import '@aws-amplify/ui-react/styles.css'
import { IsLoading } from '../../registration/fragments/isLoading'
import { useAppSelector } from '../../../redux/app/create_njangee_store/hooks'
import { createUserAuthSelector } from '../../amplify/slices/authSlices'

interface IDashboardLayout {
  children: ReactNode
}
export const UserDashBoardLayout: React.FC<IDashboardLayout> = ({
  children
}) => {
  const userData = useAppSelector(createUserAuthSelector)
  return (
    <div className='user-dashboard-layout'>
      <Header />
      {!userData.isLoggingOut ? (
        <div style={{ minHeight: '500px' }}>{children}</div>
      ) : (
        <IsLoading />
      )}
      <DashBoardFooter />
    </div>
  )
}
