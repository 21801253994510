import React, { useEffect, useState } from 'react'
import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { UserDashBoardLayout } from '../common/dashboardUiLayout'
import BackIcon from '../../../images/icon_images/icon-back.svg'
import { FileUploadOutlined, InfoOutlined } from '@mui/icons-material'
import SendIcon from '../../../images/icon_images/icon-send-primary.svg'
import { AmountInputType } from '../common/amountInputType'
import NoGroupProfilePictureImg from '../../../images/dashboard_images/setting-profile-avatar-placeholder_large.png'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from '../common/helper/validCreds'
import { Auth } from 'aws-amplify'
import { IsLoading } from '../../registration/fragments/isLoading'
import {
  convertDateToString,
  convertStringToISODate
} from '../../common/helper/convertToDate'
import { CircularLoadingProgress } from '../../registration/fragments/circularProgress'
import {
  showModalF,
  showModalSelector
} from '../njangeeGroupModals/slices/showModalSlice'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import { setGroupId } from '../njangeeGroupModals/slices/newGroupSlices'
import { hasValidImage } from '../../common/helper/hasValidImage'
import { toast } from 'react-toastify'
import { createUserAuthSelector } from '../../amplify/slices/authSlices'
import {
  invitedMembersSelector,
  setInvitedMembers
} from '../njangeeGroupModals/slices/invitedMembersSlice'

export const UserEditGroup: React.FC = () => {
  const [isGettingGroup, setIsGettingGroup] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const invitedMembers = useAppSelector(invitedMembersSelector)
  const showModalState = useAppSelector(showModalSelector)
  const dispatch = useAppDispatch()
  const userData = useAppSelector(createUserAuthSelector).user
  const [groupData, setGroupData] = useState<any>({
    amount: 0,
    startDate: '',
    orderType: '',
    frequency: '',
    name: '',
    type: '',
    description: '',
    groupPhoto: ''
  })
  const [groupProfilePicturePreview, setGroupProfilePicturePreview] =
    useState<any>()
  const [isTrue] = useState(true)
  const { t } = useTranslation(['dashboard'])
  const params = useParams()
  const { register, handleSubmit, setValue } = useForm()
  const navigate = useNavigate()

  const formValidation = {
    name: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 4
      }
    },
    amount: {
      required: true,
      validate: {
        // maxLength: (v: string) => v.length >= 10,
        matchPattern: (v: string) => isValidPhoneNumber(v)
      }
    },
    startDate: {
      required: true
    },
    type: {
      required: true
    },
    orderType: {
      required: false
    },
    frequency: {
      required: true
    }
  }

  const onChangeGroupType = (value: string): void => {
    setGroupData({
      ...groupData,
      type: value
    })
  }
  const onChangeContributionFrequency = (value: string): void => {
    setGroupData({
      ...groupData,
      frequency: value
    })
  }
  const onChangeContributionPerMember = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setGroupData({
      ...groupData,
      ...groupData,
      amount: event.target.value
    })
    setValue('amount', event.target.value)
  }
  const onChangeMembersOrder = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setGroupData({
      ...groupData,
      orderType: event.target.value
    })
  }
  const onChangeSessionStartDate = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setGroupData({
      ...groupData,
      startDate: event.target.value
    })
  }
  const handleGroupProfilePicture = (event: any): void => {
    setGroupProfilePicturePreview(URL.createObjectURL(event.target.files[0]))
    setGroupData({
      ...groupData,
      newPhoto: event.target.files[0]
    })
  }

  const handleName = (e: any): void => {
    setGroupData({
      ...groupData,
      name: e.target.value
    })
    setValue('name', e.target.value)
  }
  const handleDescription = (e: any): void => {
    setGroupData({
      ...groupData,
      description: e.target.value
    })
  }

  const handleEditGroup = async (data: any): Promise<void> => {
    const updatedData = {
      name: data.name,
      description: data.description,
      amount: parseInt(data.amount),
      type: data.type,
      frequency: data.frequency,
      startDate: convertStringToISODate(data.startDate),
      orderType: data.orderType,
      groupPhoto: hasValidImage(groupData.newPhoto)
    }

    setIsUpdating(true)
    try {
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      await axios.patch(
        `${process.env.REACT_APP_API as string}/groups/${
          groupData.id as string
        }`,
        updatedData,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      navigate('/user-groups')
    } catch (error) {
      toast.error('Error updating group.')
    } finally {
      setIsUpdating(false)
    }
  }

  const onClickShowModal = (): void => {
    dispatch(setInvitedMembers({ ...invitedMembers, inviteToGroup: true }))
    dispatch(
      showModalF({
        ...showModalState,
        showModal: true,
        modalIndex: 4,
        skipCongrats: true
      })
    )
  }

  useEffect(() => {
    const getGroup = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const token = user.signInUserSession.idToken.jwtToken
        const group = await axios.get(
          `${process.env.REACT_APP_API as string}/groups/${String(params.id)}/`,
          {
            headers: {
              Authorization: token
            }
          }
        )
        if (group.data.data?.userId !== userData?.id) {
          navigate(-1)
          toast.error('Unauthorized access!')
        }
        setGroupData(group.data.data)
        dispatch(setGroupId({ id: group.data.data.id }))
      } catch (error) {
      } finally {
        setIsGettingGroup(false)
      }
    }
    void getGroup()
  }, [])
  return isGettingGroup ? (
    <IsLoading />
  ) : (
    <UserDashBoardLayout>
      <Stack direction='column' className='w-full px-4 md:px-12 gap-4'>
        <Stack className='w-full border-main border-b py-3 mb-2'>
          <Link
            to='/user-groups'
            className='font-semibold w-fit flex items-center'
          >
            <img alt='' src={BackIcon} className='w-fit h-fit pr-4' /> Back to
            njangee group
          </Link>
        </Stack>
        <Stack className='mt-1'>
          <h5 className='w-auto'>{t('groups.edit_group_details')}</h5>
        </Stack>
        <form onSubmit={handleSubmit(handleEditGroup)}>
          <Stack
            direction='row'
            display='grid'
            className='w-full grid-cols-1 md:grid-cols-3 mb-5 gap-4'
          >
            <Stack className='col-span-1 md:col-span-2 w-full bg-white border border-main rounded p-4 md:p-6 order-2 md:order-1'>
              <Stack className='w-full md:w-11/12 lg:w-9/12 gap-4'>
                <Stack className='w-full gap-1'>
                  <p className='font-semibold'>{t('groups.group_name')}</p>
                  <input
                    type='text'
                    maxLength={100}
                    {...register('name')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleName(e)
                    }}
                    value={groupData?.name ?? ''}
                  />
                </Stack>
                <Stack className='w-full gap-1'>
                  <p className='font-semibold'>{t('groups.group_desc')}</p>
                  <textarea
                    rows={4}
                    maxLength={1000}
                    className='w-full'
                    value={groupData?.description ?? ''}
                    {...register('description')}
                    onChange={(e: any) => {
                      handleDescription(e)
                    }}
                  ></textarea>
                </Stack>

                <Stack className='w-full gap-1'>
                  <p className='font-semibold'>
                    {t('main_modal.each_member_contribution')}?
                  </p>
                  <AmountInputType
                    inputValue={groupData?.amount ?? ''}
                    anyValidation={register('amount', formValidation.amount)}
                    onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChangeContributionPerMember(e)
                    }}
                  />
                </Stack>

                <Stack direction='column' className='items-start w-full mt-1'>
                  <p className='font-semibold mb-2 flex items-center gap-2'>
                    {t('groups.group_type')}{' '}
                    <InfoOutlined className='!font-normal !text-base text-black-2' />
                  </p>
                  <Stack
                    direction='row'
                    className='items-center gap-4 md:gap-6 flex-wrap !justify-start'
                  >
                    <Stack
                      direction='row'
                      className='gap-2 items-center py-0 ml-0 sm:m-auto w-fit'
                    >
                      <input
                        type='radio'
                        {...register('type', formValidation.type)}
                        checked={
                          groupData?.type === 'CLOSED' ? isTrue : !isTrue
                        }
                        value='CLOSED'
                        onChange={() => {
                          onChangeGroupType('CLOSED')
                        }}
                      />
                      <label
                        className={`${
                          groupData?.type !== 'CLOSED'
                            ? 'text-secondary-light'
                            : ''
                        }`}
                      >
                        {t('groups.closed')}
                      </label>
                    </Stack>
                    <Stack
                      direction='row'
                      className='gap-2 items-center py-0 ml-0 sm:m-auto w-fit'
                    >
                      <input
                        type='radio'
                        {...register('type', formValidation.type)}
                        checked={groupData?.type === 'OPEN' ? isTrue : !isTrue}
                        value='OPEN'
                        onChange={() => {
                          onChangeGroupType('OPEN')
                        }}
                      />
                      <label
                        className={`${
                          groupData?.type !== 'OPEN'
                            ? 'text-secondary-light'
                            : ''
                        }`}
                      >
                        {t('groups.open')}
                      </label>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction='column' className='items-start w-full mt-1'>
                  <p className='font-semibold mb-2 flex items-center gap-2'>
                    {t('main_modal.frequency_of_contribution')}{' '}
                    <InfoOutlined className='!font-normal !text-base text-black-2' />
                  </p>
                  <Stack
                    direction='row'
                    className='items-center gap-4 md:gap-6 flex-wrap !justify-start'
                  >
                    <Stack
                      direction='row'
                      className='gap-2 items-center py-0 ml-0 sm:m-auto w-fit'
                    >
                      <input
                        type='radio'
                        {...register('frequency', formValidation.frequency)}
                        checked={
                          groupData?.frequency === 'MONTHLY' ? isTrue : !isTrue
                        }
                        value='MONTHLY'
                        onChange={() => {
                          onChangeContributionFrequency('MONTHLY')
                        }}
                      />
                      <label
                        className={`${
                          groupData?.frequency !== 'MONTHLY'
                            ? 'text-secondary-light'
                            : ''
                        }`}
                      >
                        {t('main_modal.monthly')}
                      </label>
                    </Stack>
                    <Stack
                      direction='row'
                      className='gap-2 items-center py-0 ml-0 sm:m-auto w-fit'
                    >
                      <input
                        type='radio'
                        {...register('frequency', formValidation.frequency)}
                        checked={
                          groupData?.frequency === 'BI_WEEKLY'
                            ? isTrue
                            : !isTrue
                        }
                        value='BI_WEEKLY'
                        onChange={() => {
                          onChangeContributionFrequency('BI_WEEKLY')
                        }}
                      />
                      <label
                        className={`${
                          groupData?.frequency !== 'BI_WEEKLY'
                            ? 'text-secondary-light'
                            : ''
                        }`}
                      >
                        {t('main_modal.bi_weekly')}
                      </label>
                    </Stack>
                    <Stack
                      direction='row'
                      className='gap-2 items-center py-0 ml-0 sm:m-auto w-fit'
                    >
                      <input
                        type='radio'
                        {...register('frequency', formValidation.frequency)}
                        checked={
                          groupData?.frequency === 'WEEKLY' ? isTrue : !isTrue
                        }
                        value='WEEKLY'
                        onChange={() => {
                          onChangeContributionFrequency('WEEKLY')
                        }}
                      />
                      <label
                        className={`${
                          groupData?.frequency !== 'WEEKLY'
                            ? 'text-secondary-light'
                            : ''
                        }`}
                      >
                        {t('main_modal.weekly')}
                      </label>
                    </Stack>
                    <Stack
                      direction='row'
                      className='gap-2 items-center py-0 ml-0 sm:m-auto w-fit'
                    >
                      <input
                        type='radio'
                        {...register('frequency', formValidation.frequency)}
                        checked={
                          typeof groupData.frequency === 'string' &&
                          groupData.frequency === 'DAILY'
                            ? isTrue
                            : !isTrue
                        }
                        value='DAILY'
                        onChange={() => {
                          onChangeContributionFrequency('DAILY')
                        }}
                      />
                      <label
                        className={`${
                          groupData?.frequency !== 'DAILY'
                            ? 'text-secondary-light'
                            : ''
                        }`}
                      >
                        {t('main_modal.daily')}
                      </label>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction='column' className='items-start w-full mt-1'>
                  <p className='font-semibold mb-1'>
                    {t('main_modal.what_order_should_members_pick')}?
                  </p>
                  <Stack direction='row' className='w-full items-center gap-2'>
                    <select
                      {...register('orderType', formValidation.orderType)}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        onChangeMembersOrder(e)
                      }}
                      value={groupData?.orderType ?? ''}
                      className='w-full'
                    >
                      <option value=''>{t('main_modal.select_order')}</option>
                      <option value='FIFO'>First in First out</option>
                      <option value='LIFO'>Last in First out</option>
                      <option value='RANDOM'>Random</option>

                      <option value='CUSTOM'>Custom</option>
                    </select>
                    <InfoOutlined className='!font-normal !text-base text-black-2' />
                  </Stack>
                </Stack>

                <Stack direction='column' className='items-start w-full mt-1'>
                  <p className='font-semibold mb-1'>
                    {t('main_modal.start_date_session')}
                  </p>
                  <Stack direction='row' className='items-center w-full gap-2'>
                    <input
                      type='date'
                      value={convertDateToString(groupData?.startDate) ?? ''}
                      {...register('startDate', formValidation.startDate)}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onChangeSessionStartDate(e)
                      }}
                      className='w-full'
                    />
                    <InfoOutlined className='!font-normal !text-base text-black-2' />
                  </Stack>
                </Stack>

                <Stack direction='row' className='items-center flex-wrap gap-5'>
                  <p className='font-semibold'>
                    {t('groups.group_members')}:{' '}
                    {groupData?.groupMembers.length ?? '0'}
                  </p>
                  <button
                    type='button'
                    onClick={onClickShowModal}
                    className='btn-with-icon btn-secondary gap-2'
                  >
                    <img src={SendIcon} alt='' />
                    {t('main_modal.invite_group_members')}
                  </button>
                </Stack>

                <Stack direction='row' className='items-center flex-wrap gap-5'>
                  <button
                    type='submit'
                    disabled={isUpdating}
                    className='btn btn-primary flex gap-2'
                  >
                    {isUpdating && <CircularLoadingProgress />}
                    {t('general.common.save_changes')}
                  </button>
                </Stack>
              </Stack>
            </Stack>

            <Stack className='col-span-1 w-full bg-white border border-main rounded p-4 md:p-6 order-1 md:order-2'>
              <Stack className='w-full lg:w-10/12'>
                <p className='font-semibold'>{t('groups.group_profile_pic')}</p>
                <br />
                <img
                  src={
                    groupProfilePicturePreview !== undefined
                      ? groupProfilePicturePreview
                      : groupData?.groupPhoto !== '' &&
                        groupData?.groupPhoto !== null &&
                        groupData?.groupPhoto !== undefined
                      ? groupData?.groupPhoto
                      : NoGroupProfilePictureImg
                  }
                  alt=''
                  className='!h-64 !w-64'
                />
                <Button
                  variant='text'
                  component='label'
                  className='text-center w-fit lg:w-64 !p-0 !mt-3'
                >
                  <Stack className='mx-0 py-2 px-6 md:px-4 lg:px-6 w-full text-primary border border-primary rounded'>
                    <p className='!normal-case font-semibold mx-auto !bg-whte flex items-center gap-1'>
                      <FileUploadOutlined /> {t('groups.change_photo')}
                    </p>
                    <input
                      type='file'
                      hidden
                      accept='image/jpeg,image/jpg,image/png,application/pdf'
                      onChange={(e: any) => {
                        handleGroupProfilePicture(e)
                      }}
                    />
                  </Stack>
                </Button>
              </Stack>
              <br />
            </Stack>
          </Stack>
        </form>
      </Stack>
    </UserDashBoardLayout>
  )
}
