import React, { type ReactElement } from 'react'
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EditOutlined, UploadFile } from '@mui/icons-material'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import {
  setIdVerification,
  createIdVerificationSelector
} from '../slices/idVerificationSlice'
import { getKYCStatusColors } from '../../common/helper/statusFunctions'
import { getFileUrlTypeAndDisplay } from '../../../common/helper/functions'
import {
  convertDateToString,
  formatToReadableDate
} from '../../../common/helper/convertToDate'
import { useNavigate } from 'react-router-dom'

export const IdPassportVerification: React.FC = () => {
  const idVerification = useAppSelector(createIdVerificationSelector)
  const dispatch = useAppDispatch()
  // const [showCircleProgress, setShowCircleProgress] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation(['dashboard'])

  const handleEditKYCRedirect = (): void => {
    dispatch(setIdVerification({ ...idVerification, isEditMode: true }))
    navigate('/user-onboarding')
  }

  const showDocuments = (): ReactElement => {
    return (
      <Stack className='w-full md:w-11/12 border border-main bg-white rounded px-4 md:px-8 lg:px-12 py-6'>
        <Stack
          direction='row'
          className='items-start flex-wrap w-full gap-5 mb-3'
        >
          <Stack>
            <h6>{t('settings.submit_documents')}</h6>
            <p className='text-secondary'>
              {t('settings.submit_documents_caption')}
            </p>
          </Stack>
          <h6 className='ml-auto'>
            {t('general.common.status')}:{' '}
            <span
              className={`${getKYCStatusColors(
                idVerification.status
              )} font-extra`}
            >
              {idVerification.status}
            </span>
          </h6>
        </Stack>

        <Stack className='border-b border-main w-full'>
          <Stack
            direction='row'
            className='w-full flex-wrap  md:w-10/12 lg:w-7/12 py-3 items-center gap-5 md:gap-8 lg:gap-12'
          >
            <Stack direction='row' className='items-center gap-5 md:gap-7'>
              <p className='font-semibold'>{t('settings.document_type')}:</p>
              <p className='font-extra'>{idVerification.document_type}</p>
            </Stack>

            <Stack direction='row' className='items-center gap-5 md:gap-7'>
              <p className='font-semibold'>{t('settings.expiration_date')}:</p>
              <p className='font-extra'>
                {formatToReadableDate(idVerification.expiration_date, '-')}
              </p>
            </Stack>
          </Stack>
        </Stack>

        <Stack
          direction='row'
          className='w-full md:w-10/12 lg:w-7/12 gap-4 !grid grid-cols-1 sm:grid-cols-2 my-5'
        >
          {idVerification.document_type === 'PASSPORT' ? (
            <Stack
              direction='column'
              className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-6'
            >
              {getFileUrlTypeAndDisplay(
                idVerification.frontIdUrl,
                idVerification.document_type
              )}
            </Stack>
          ) : (
            <Stack
              direction='column'
              className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-6'
            >
              {getFileUrlTypeAndDisplay(
                idVerification.frontIdUrl,
                idVerification.document_type
              )}
            </Stack>
          )}
          {idVerification.document_type !== 'PASSPORT' && (
            <Stack
              direction='column'
              className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-6'
            >
              {getFileUrlTypeAndDisplay(
                idVerification.backIdUrl,
                idVerification.document_type
              )}
            </Stack>
          )}
        </Stack>
        <Stack direction='row'>
          {idVerification.status === 'REJECTED' && (
            <button
              className='btn-with-icon btn-primary gap-2 ml-auto'
              onClick={() => {
                // setNoKYCNew(true)
                // setIsEdit(true)
                handleEditKYCRedirect()
              }}
            >
              <EditOutlined fontSize='small' /> {t('settings.editKYC')}
            </button>
          )}
        </Stack>
        <br />
        {idVerification.reviews.length > 0 &&
          idVerification.status === 'REJECTED' && (
            <Stack>
              <h5>{t('settings.review')}</h5>
              <TableContainer component={Paper} className='overflow-y-scroll'>
                <Table sx={{ minWidth: 500 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <span className='font-bold text-lg'>
                          {t('settings.review')}
                        </span>
                      </TableCell>
                      <TableCell>
                        <span className='font-bold text-lg'>
                          {t('settings.docs_to_upload')}
                        </span>
                      </TableCell>
                      <TableCell align='right'>
                        <span className='font-bold text-lg'>
                          {t('general.common.date')}
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {idVerification.reviews.map((review: any) => (
                      <TableRow
                        key={review?.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell component='th' scope='row'>
                          {review?.review !== null &&
                          review?.review !== undefined &&
                          review?.review !== ''
                            ? review?.review
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {review?.kycReviewDocumentRequirements?.length ===
                          0 ? (
                            '-'
                          ) : (
                            <Stack>
                              <ul className='!list-disc'>
                                {review?.kycReviewDocumentRequirements?.map(
                                  (reviewDoc: any) => (
                                    <li key={reviewDoc?.id}>
                                      {reviewDoc?.documentType}
                                    </li>
                                  )
                                )}
                              </ul>
                              <br />
                              {review?.kycReviewDocumentRequirements.length >
                                0 && (
                                <button
                                  onClick={handleEditKYCRedirect}
                                  className='btn-with-icon btn-primary !py-2 gap-2'
                                >
                                  <UploadFile fontSize='small' /> Upload
                                  Documents
                                </button>
                              )}
                            </Stack>
                          )}
                        </TableCell>
                        <TableCell align='right'>
                          {convertDateToString(review?.createdAt)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          )}
      </Stack>
    )
    // }
  }
  return <>{showDocuments()}</>
}
