import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'
import { type AppMetaData } from '../../../common/globals/types'
import { APP_INITIAL_METADATA } from '../../../common/globals/initials'

export interface GetAppNotifications {
  data: Array<Record<string, any>>
  meta: AppMetaData
  isLoading: boolean
}

const initialState: GetAppNotifications = {
  data: [],
  meta: APP_INITIAL_METADATA,
  isLoading: true
}

export const createGetAppNotificationsSlice = createSlice({
  name: 'get_app_notifications',
  initialState,
  reducers: {
    setAppNotifications: (
      state,
      action: PayloadAction<GetAppNotifications>
    ) => {
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
        isLoading: action.payload.isLoading
      }
    }
  }
})

export const { setAppNotifications } = createGetAppNotificationsSlice.actions

export const createGetAppNotificationsSelector = (
  state: RootState
): GetAppNotifications => state.createGetAppNotificationsReducer
export default createGetAppNotificationsSlice.reducer
