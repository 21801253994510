import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Stack, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'

import PaymentImage from '../../../../images/icon_images/icon-dashboard-payment.svg'
import InviteFriendsImage from '../../../../images/icon_images/icon-dashboard-inviteFriends.svg'
import CompleteProfileImage from '../../../../images/icon_images/icon-dashboard-complete-profile.svg'
import CloseBoxIcon from '../../../../images/icon_images/icon-close.svg'
import BgSetupPayment from '../../../../images/dashboard_images/dashboard-bg-1.png'
import BgInviteFriends from '../../../../images/dashboard_images/dashboard-bg-2.png'
import BgCompleteProfie from '../../../../images/dashboard_images/dashboard-bg-3.png'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import {
  showModalF,
  showModalSelector
} from '../../njangeeGroupModals/slices/showModalSlice'
import {
  invitedMembersSelector,
  setInvitedMembers
} from '../../njangeeGroupModals/slices/invitedMembersSlice'

export const TopRowBoxes: React.FC = () => {
  const [showInviteFriends, setShowInviteFriends] = useState<boolean>(true)
  const [showCompleteProfile, setShowCompleteProfile] = useState<boolean>(true)
  const showModalState = useAppSelector(showModalSelector)
  const invitedMembers = useAppSelector(invitedMembersSelector)
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['dashboard'])
  const addShowClass = (): string => {
    if (!showInviteFriends || !showCompleteProfile) {
      if (!showInviteFriends && !showCompleteProfile) {
        return 'md:grid-cols-1 lg:grid-cols-1'
      }
      return 'md:grid-cols-2 lg:grid-cols-2'
    } else {
      return 'md:grid-cols-2 lg:grid-cols-3'
    }
  }
  const inviteFunc = (): void => {
    setShowInviteFriends(false)
    addShowClass()
  }
  const completeFunc = (): void => {
    setShowCompleteProfile(false)
    addShowClass()
  }

  const onClickShowInvitationModal = (): void => {
    dispatch(
      showModalF({
        ...showModalState,
        modalIndex: 4,
        showModal: true,
        skipCongrats: true
      })
    )
    dispatch(setInvitedMembers({ ...invitedMembers, inviteToGroup: false }))
  }
  return (
    <div
      className={`grid grid-cols-1 ${addShowClass()} gap-3 md:gap-4 mt-9 !w-full`}
    >
      <Stack
        direction='row'
        className='top-box bg-white px-4 lg:px-5 gap-5 !w-full'
        style={{ backgroundImage: `url(${BgSetupPayment})` }}
      >
        <img alt='' src={PaymentImage} className='h-fit w-fit left-img' />
        <Stack>
          <h6>{t('dashboard.setup_payment')}</h6>
          <p className='pt-1 mb-4'>{t('dashboard.setup_payment_caption')}</p>
          <Link
            to='/user-settings?payment-methods'
            className='text-button-primary'
          >
            {t('dashboard.setup_payment_link')}
          </Link>
        </Stack>
      </Stack>

      {showInviteFriends && (
        <Stack
          direction='row'
          className='top-box bg-white px-4 lg:px-5 gap-5 relative'
          style={{ backgroundImage: `url(${BgInviteFriends})` }}
        >
          <img
            alt=''
            src={InviteFriendsImage}
            className='h-fit w-fit left-img'
          />
          <Stack>
            <h6>{t('dashboard.invite_more_friends')}</h6>
            <p className='pt-1 mb-4'>
              {t('dashboard.invite_more_friends_caption')}
            </p>
            <p
              role='button'
              onClick={onClickShowInvitationModal}
              className='text-button-primary'
            >
              {t('dashboard.invite_more_friends_link')}
            </p>
          </Stack>

          <IconButton
            className='!absolute right-2 top-1'
            onClick={() => {
              inviteFunc()
            }}
          >
            <img alt='' src={CloseBoxIcon} className='h-3 w-3' />
          </IconButton>
        </Stack>
      )}

      {showCompleteProfile && (
        <Stack
          direction='row'
          className='top-box bg-white px-4 lg:px-5 gap-5 relative'
          style={{ backgroundImage: `url(${BgCompleteProfie})` }}
        >
          <img
            alt=''
            src={CompleteProfileImage}
            className='h-fit w-fit left-img'
          />
          <Stack>
            <h6>{t('dashboard.complete_profile')}</h6>
            <p className='pt-1 mb-4'>
              {t('dashboard.complete_profile_caption')}
            </p>
            <Link
              to='/user-settings?edit-profile'
              className='text-button-primary'
            >
              {t('dashboard.complete_profile_link')}
            </Link>
          </Stack>
          <IconButton
            className='!absolute right-2 top-1'
            onClick={() => {
              completeFunc()
            }}
          >
            <img alt='' src={CloseBoxIcon} className='h-3 w-3' />
          </IconButton>
        </Stack>
      )}
    </div>
  )
}
