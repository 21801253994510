import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Stack, IconButton, Button, Menu, MenuItem } from '@mui/material'
import DropdownIcon from '../../../../images/icon_images/icon-dropdown.svg'
import HelpIcon from '../../../../images/icon_images/icon-help.svg'
import NotificationIcon from '../../../../images/icon_images/icon-notifications.svg'
import SettingsIcon from '../../../../images/icon_images/icon-settings.svg'
import SearchIcon from '../../../../images/icon_images/icon-search.svg'
import AddIcon from '../../../../images/icon_images/icon-add.svg'
import EditProfileIcon from '../../../../images/icon_images/icon-edit-profile.svg'
import InviteFriendsIcon from '../../../../images/icon_images/icon-invite-friend.svg'
import PaymentMethodsIcon from '../../../../images/icon_images/icon-paymentMethods.svg'
import DropNotificationsIcon from '../../../../images/icon_images/icon-notification-outline-gray.svg'
import SignOutIcon from '../../../../images/icon_images/icon-signout.svg'
import { NotificationSider } from '../notificationSider'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import {
  showModalF,
  showModalSelector
} from '../../njangeeGroupModals/slices/showModalSlice'
import { createUserAuthSelector } from '../../../amplify/slices/authSlices'
import { AccountCircle } from '@mui/icons-material'
import { createGetAppNotificationsSelector } from '../slices/appNotificationsSlice'

interface INotificationAndSettings {
  signOut: any
}

export const NotificationAndSettings: React.FC<INotificationAndSettings> = ({
  signOut
}) => {
  const [profileButtonDrop, setProfileButtonDrop] =
    useState<null | HTMLElement>(null)
  const [openNotificatonDrawer, setOpenNotificationDrawer] = useState(false)
  const openProfile = Boolean(profileButtonDrop)
  const [addNewButtonDrop, setAddNewButtonDrop] = useState<null | HTMLElement>(
    null
  )
  const openAddNew = Boolean(addNewButtonDrop)
  const { t } = useTranslation(['dashboard'])
  const loggedUser = useAppSelector(createUserAuthSelector).user
  const showModalState = useAppSelector(showModalSelector)
  const dispatchModal = useAppDispatch()
  const notifications = useAppSelector(createGetAppNotificationsSelector)

  const onClickShowModal = (): void => {
    dispatchModal(showModalF({ ...showModalState, showModal: true }))
  }

  const showAddNewDropDown = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAddNewButtonDrop(event.currentTarget)
  }
  const closeAddNewDropDown = (): void => {
    setAddNewButtonDrop(null)
  }
  const showProfileDropDown = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setProfileButtonDrop(event.currentTarget)
  }
  const closeProfileDropDown = (): void => {
    setProfileButtonDrop(null)
  }
  const handleOpenNotificationDrawer = (): void => {
    setOpenNotificationDrawer(true)
  }
  const handleCloseNotificationDrawer = (): void => {
    setOpenNotificationDrawer(false)
  }
  return (
    <div className='ml-auto flex items-center'>
      <Stack
        direction='row'
        alignItems='center'
        className='nav-menu2-child items-center !border-0 !hidden lg:!flex'
      >
        <IconButton>
          <img alt='' src={HelpIcon} />
        </IconButton>
        <Stack className='relative'>
          <IconButton onClick={handleOpenNotificationDrawer}>
            <img alt='' src={NotificationIcon} />
          </IconButton>
          {notifications.meta?.unreadCount !== null &&
          notifications.meta?.unreadCount > 0 ? (
            <Stack className='absolute right-0 h-5 w-5 rounded-full bg-red-600'>
              <small className='font-extrabold mx-auto my-auto text-9'>
                {notifications.meta?.unreadCount > 99
                  ? '99+'
                  : notifications.meta?.unreadCount}
              </small>
            </Stack>
          ) : (
            <span></span>
          )}
        </Stack>
        <IconButton>
          <Link to='/user-settings'>
            <img alt='' src={SettingsIcon} />
          </Link>
        </IconButton>
        <NotificationSider
          isDrawerOpen={openNotificatonDrawer}
          handleDrawerClose={handleCloseNotificationDrawer}
        />
        <Stack direction='row' className='items-center !hidden md:!flex'>
          <Button
            id='profile-button'
            variant='text'
            onClick={showProfileDropDown}
            aria-controls={openProfile ? 'profile-drop' : undefined}
            aria-haspopup='true'
            aria-expanded={openProfile ? 'true' : undefined}
          >
            {loggedUser?.photo !== '' ? (
              <img
                src={loggedUser?.photo}
                className='nav-right-profile-image !p-0 !h-11 !w-11 border-2 border-white'
                alt=''
              />
            ) : (
              <AccountCircle fontSize='large' className='text-white' />
            )}
            <img alt='' src={DropdownIcon} className='pl-2' />
          </Button>
        </Stack>
      </Stack>

      <Stack
        direction='row'
        alignItems='center'
        className='nav-menu2-child mobile-prof !pl-3 !pr-0 items-center gap-4 md:gap-6 !border-0 !flex lg:!hidden'
      >
        <Link to='/'>
          <img alt='' src={SearchIcon} className='h-4' />
        </Link>
        <div className='!hidden md:!flex items-center nav-menu2-child !px-0 '>
          <Button
            id='add-new-button'
            variant='text'
            onClick={showAddNewDropDown}
            aria-controls={openAddNew ? 'add-new-drop' : undefined}
            aria-haspopup='true'
            aria-expanded={openAddNew ? 'true' : undefined}
          >
            <img alt='' src={AddIcon} className='pr-3' />
            {t('general.navigation.add_new')}
            <img alt='' src={DropdownIcon} className='pl-2' />
          </Button>
          <Menu
            id='add-new-drop'
            anchorEl={addNewButtonDrop}
            open={openAddNew}
            MenuListProps={{
              'aria-labelledby': 'add-new-button'
            }}
            onClose={closeAddNewDropDown}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem onClick={onClickShowModal}>
              <span className='small text-black-2 font-semibold py-1 flex items-center'>
                {t('general.common.njangee_group')}
              </span>
            </MenuItem>
            <MenuItem>
              <Link
                className='small text-black-2 font-semibold py-1 flex items-center'
                to='/user-settings?payment-methods'
              >
                {t('general.common.payment_method')}
              </Link>
            </MenuItem>
          </Menu>
        </div>
        <Stack className='relative'>
          <IconButton
            className='!p-0 !m-0'
            onClick={handleOpenNotificationDrawer}
          >
            <img alt='' src={NotificationIcon} className='h-6' />
          </IconButton>{' '}
          {notifications.meta?.unreadCount !== null &&
          notifications.meta?.unreadCount > 0 ? (
            <Stack className='absolute right-0 top-0 h-4 px-1 rounded-full bg-red-600'>
              <small className='font-extrabold mx-auto my-auto text-[5px]'>
                {notifications.meta?.unreadCount > 99
                  ? '99+'
                  : notifications.meta?.unreadCount}
              </small>
            </Stack>
          ) : (
            <span></span>
          )}
        </Stack>
        <Stack direction='row' className='items-center !px-0 !mx-0'>
          <IconButton
            id='profile-button'
            onClick={showProfileDropDown}
            aria-controls={openProfile ? 'profile-drop' : undefined}
            aria-haspopup='true'
            aria-expanded={openProfile ? 'true' : undefined}
          >
            {loggedUser?.photo !== '' ? (
              <img
                src={loggedUser?.photo}
                className='nav-right-profile-image !p-0 !h-8 !w-8 border-2 border-white'
                alt=''
              />
            ) : (
              <AccountCircle fontSize='large' className='text-white' />
            )}
            {/* <img alt='' src={ProfileImage} className='nav-right-profile-image !p-0 !h-8 !w-8' /> */}
          </IconButton>
          <Menu
            id='profile-drop'
            anchorEl={profileButtonDrop}
            open={openProfile}
            MenuListProps={{
              'aria-labelledby': 'profile-button'
            }}
            onClose={closeProfileDropDown}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            className='gap-5'
          >
            <MenuItem>
              <Link
                className='small text-th-black font-semibold py-1 flex items-center gap-5'
                to='/user-settings?edit-profile'
              >
                <img src={EditProfileIcon} alt='' />
                {t('general.common.edit_profile')}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className='small text-th-black font-semibold py-1 flex items-center gap-4'
                to='/'
              >
                <img src={InviteFriendsIcon} alt='' />
                {t('general.common.invite_friends')}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className='small text-th-black font-semibold py-1 flex items-center gap-4'
                to='/user-settings?payment-methods'
              >
                <img src={PaymentMethodsIcon} alt='' />
                {t('general.common.payment_methods')}
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                className='small text-th-black font-semibold py-1 flex items-center gap-6'
                to='/user-settings?notifications'
              >
                <img src={DropNotificationsIcon} alt='' />
                {t('general.common.notifications')}
              </Link>
            </MenuItem>
            <MenuItem onClick={signOut}>
              <span className='small text-th-black font-semibold py-1 flex items-center gap-4'>
                <img src={SignOutIcon} alt='' />
                {t('general.common.sign_out')}
              </span>
            </MenuItem>
          </Menu>
        </Stack>
      </Stack>
    </div>
  )
}
