import React, { useState } from 'react'
import { IconButton, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { GroupsModalSkeleton } from '../../groups/fragments/groupsModalSkeleton'
import { CloseOutlined } from '@mui/icons-material'
import MtnMobileMoneyImg from '../../../../images/dashboard_images/payment-mtn-logo.png'
import OrangeMobileMoneyImg from '../../../../images/dashboard_images/img-payment-orangeMoney.png'
import { useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { appPostRequest } from '../../../common/helper/httpRequests'
import { createUserAuthSelector } from '../../../amplify/slices/authSlices'
import { CircularLoadingProgress } from '../../../registration/fragments/circularProgress'
import PhoneInput from 'react-phone-input-2'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
interface IProps {
  handleClose: () => void
  open: boolean
  momoType: string
  onPaymentAdded: () => void
}

export const AddMobileMoneyDetails: React.FC<IProps> = ({
  handleClose,
  open,
  momoType,
  onPaymentAdded
}) => {
  const { t } = useTranslation(['dashboard'])
  const [isLoading, setIsLoading] = useState(false)
  const userData = useAppSelector(createUserAuthSelector)
  // const onChangeMobileNumber = (e: any): void => {
  //   setSinglePaymentMethod({
  //     ...singlePaymentMethod,
  //     mobileNumber: e.target.value,
  //     id: Date.now().toString(),
  //     title: momoType === 'MTN' ? 'Mtn Mobile Money' : 'Orange Money'
  //   })
  // }
  const formValidation = {
    name: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 4
      }
    },
    phone_number: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 9
      }
    }
  }
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()
  // const handleUseAsDefaultPayment = (): void => {
  //   setSinglePaymentMethod({
  //     ...singlePaymentMethod,
  //     isDefault: !singlePaymentMethod.isDefault
  //   })
  // }
  const addNewPaymentMethod = async (data: any): Promise<void> => {
    const paymentDetails = {
      phoneNumber: data.phone_number,
      name: data.name
    }
    const pmData = {
      paymentGatewayId: 1,
      paymentDetails: JSON.stringify(paymentDetails),
      isDefault: data.isDefault
    }
    setIsLoading(true)
    const paymentInfo = await appPostRequest(
      `/users/payment-settings/${userData.user?.id as string}`,
      pmData
    )

    if (paymentInfo.status === 201) {
      onPaymentAdded()
      handleClose()
    } else if (paymentInfo.response.data.message === '409') {
      toast.error(t('settings.this_momo_number_exist'))
    } else {
      toast.error(t('settings.error_adding_payment_details'))
    }
    setIsLoading(false)
  }
  return (
    <GroupsModalSkeleton
      open={open}
      handleClose={handleClose}
      xsModalWidth='95%'
      smModalWidth='65%'
      mdModalWidth='55%'
      lgModalWidth='40%'
    >
      <form onSubmit={handleSubmit(addNewPaymentMethod)}>
        <Stack
          direction='row'
          className='flex items-center w-full justify-start gap-3 bg-primary text-th-white py-3 px-4 sm:px-7 rounded-t border'
        >
          <h6>{t('settings.add_payment_method')}</h6>
          <IconButton onClick={handleClose} className='!ml-auto'>
            <CloseOutlined className='text-primary-light' />
          </IconButton>
        </Stack>

        <Stack className='w-full px-4 sm:px-7 my-6 gap-4'>
          <Stack
            direction='row'
            className='w-full items-center rounded-md bg-light p-4 gap-3'
          >
            <img
              src={
                momoType === 'MTN' ? MtnMobileMoneyImg : OrangeMobileMoneyImg
              }
              alt=''
              className='h-8 w-8'
            />
            <p className='font-semibold'>
              {momoType === 'MTN' ? 'MTN Mobile Money' : 'Orange Money'}
            </p>
          </Stack>

          <Stack className='w-full gap-1'>
            <p className='font-semibold'>
              {momoType === 'MTN' ? 'MTN' : 'Orange'}{' '}
              {t('settings.mobile_money_number')}
            </p>
            {/* <Stack direction='row' className='w-full'>
            <Stack className='border border-secondary-xlight border-r-0 w-fit p-2 rounded rounded-r-none flex items-center justify-center'>
              <img src={CameroonFlag} alt='' className='h-7 w-12' />
            </Stack>
            <Stack className='w-full'>
              <input
                type='text'
                name='phone-number'
                placeholder='+237 6789512057'
                onChange={(e: any) => {
                  onChangeMobileNumber(e)
                }}
                className='border-l-0 !rounded-l-none'
              />
            </Stack>
          // </Stack> */}

            <Stack className=''>
              <PhoneInput
                country={'cm'}
                enableSearch={true}
                // countryCodeEditable={true}
                // disableInitialCountryGuess
                {...register('phone_number', formValidation.phone_number)}
                inputStyle={{ width: '100%' }}
                placeholder='+237 678 3562 65'
                onChange={(value) => {
                  // handleCountryCode(value)
                  setValue('phone_number', value)
                }}
              />
            </Stack>
            {errors.phone_number?.type === 'minLength' && (
              <small className='text-danger'>
                {t('settings.momo_number_must_contain_atleast_9_characters')}
              </small>
            )}
            {errors.phone_number?.type === 'required' && (
              <small className='text-danger'>
                {t('settings.momo_number_is_required')}
              </small>
            )}
          </Stack>
          <Stack className='w-full gap-1'>
            <p className='font-semibold'>{t('settings.momo_name')}</p>
            {/* <Stack direction='row' className='w-full'>
            <Stack className='border border-secondary-xlight border-r-0 w-fit p-2 rounded rounded-r-none flex items-center justify-center'>
              <img src={CameroonFlag} alt='' className='h-7 w-12' />
            </Stack>
            <Stack className='w-full'>
              <input
                type='text'
                name='phone-number'
                placeholder='+237 6789512057'
                onChange={(e: any) => {
                  onChangeMobileNumber(e)
                }}
                className='border-l-0 !rounded-l-none'
              />
            </Stack>
          // </Stack> */}

            <Stack className=''>
              <input type='text' {...register('name', formValidation.name)} />
              {errors.name?.type === 'minLength' && (
                <small className='text-danger'>
                  {t('settings.momo_name_must_contain_atleast_4_characters')}
                </small>
              )}
              {errors.name?.type === 'required' && (
                <small className='text-danger'>
                  {t('settings.momo_name_is_required')}
                </small>
              )}
            </Stack>
          </Stack>
          <Stack direction='row' className='gap-3 items-center'>
            <input type='checkbox' {...register('isDefault')} />
            <label htmlFor='use-as-default'>
              {t('settings.use_as_default_payment')}
            </label>
          </Stack>
        </Stack>

        <Stack className='mt-auto px-4 sm:px-7'>
          <Stack
            direction='row'
            className='w-fit ml-auto items-center gap-5 sm:gap-10'
          >
            <button
              onClick={handleClose}
              className='border-0 rounded-none w-fit h-fit text-black-2 font-semibold'
            >
              {t('general.common.cancel')}
            </button>
            <button
              className='btn btn-primary flex gap-2'
              disabled={isLoading}
              // onClick={addNewPaymentMethod}
            >
              {isLoading && <CircularLoadingProgress />}
              {t('general.common.save_changes')}
            </button>
          </Stack>
        </Stack>
      </form>
      <br />
    </GroupsModalSkeleton>
  )
}
