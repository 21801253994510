import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'
import { type AppMetaData } from '../../../common/globals/types'
import { APP_INITIAL_METADATA } from '../../../common/globals/initials'

export interface GetPayments {
  payments: any
  meta: AppMetaData
  isLoading: boolean
  filters?: {
    page?: string
    limit?: string
    from?: string
    to?: string
    status?: string
    type?: string
    search?: string
  }
}

const initialState: GetPayments = {
  payments: [],
  meta: APP_INITIAL_METADATA,
  isLoading: true,
  filters: {
    page: '1',
    limit: '10',
    from: '',
    to: '',
    status: '',
    type: '',
    search: ''
  }
}

export const createGetPaymentsSlice = createSlice({
  name: 'get_payments',
  initialState,
  reducers: {
    setPayments: (state, action: PayloadAction<GetPayments>) => {
      return {
        ...state,
        payments: action.payload.payments,
        meta: action.payload.meta,
        isLoading: action.payload.isLoading
      }
    },
    setFilters: (state, action: PayloadAction<GetPayments>) => {
      return {
        ...state,
        filter: action.payload.filters
      }
    }
  }
})

export const { setPayments, setFilters } = createGetPaymentsSlice.actions

export const createGetPaymentsSelector = (state: RootState): GetPayments =>
  state.createGetPaymentsReducer
export default createGetPaymentsSlice.reducer
