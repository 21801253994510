import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'
interface Review {
  id: number
  review: string
  kycReviewDocumentRequirements: []
  createdAt: string
}

declare global {
  export interface IdVerification {
    id: number
    document_type: string
    front_id: Blob | MediaSource
    back_id: Blob | MediaSource
    passport: Blob | MediaSource
    frontIdUrl: string
    idNumber: string
    backIdUrl: string
    expiration_date: string
    issued_date: string
    status: string
    submitted: boolean
    reviews: Review[]
    isEditMode: boolean
    proof_of_address_doc_type: string
    proof_of_address: Blob | MediaSource
    proofOfAddressUrl: string
    proof_of_funds_doc_type: string
    proof_of_funds: Blob | MediaSource
    proofOfFundsUrl: string
    countryOfBirth: string
    cityOfBirth: string
    gender: string
    stateOfBirth: string
    currentCity: string
    currentCountry: string
    post_code?: number
    address: string
  }
}

const initialState: IdVerification = {
  id: 0,
  document_type: 'ID',
  front_id: new Blob(),
  back_id: new Blob(),
  passport: new Blob(),
  frontIdUrl: '',
  backIdUrl: '',
  idNumber: '',
  expiration_date: '',
  issued_date: '',
  status: '',
  submitted: false,
  reviews: [],
  isEditMode: false,
  proof_of_address_doc_type: '',
  proof_of_address: new Blob(),
  proofOfAddressUrl: '',
  proof_of_funds_doc_type: '',
  proof_of_funds: new Blob(),
  proofOfFundsUrl: '',
  countryOfBirth: '',
  stateOfBirth: '',
  cityOfBirth: '',
  currentCity: '',
  gender: '',
  currentCountry: '',
  post_code: 0,
  address: ''
}

export const createIdVerificationSlice = createSlice({
  name: 'id_verification',
  initialState,
  reducers: {
    setIdVerification: (state, action: PayloadAction<IdVerification>) => {
      return {
        ...state,
        id: action.payload.id,
        document_type: action.payload.document_type,
        front_id: action.payload.front_id,
        back_id: action.payload.back_id,
        passport: action.payload.passport,
        frontIdUrl: action.payload.frontIdUrl,
        backIdUrl: action.payload.backIdUrl,
        idNumber: action.payload.idNumber,
        expiration_date: action.payload.expiration_date,
        issued_date: action.payload.issued_date,
        status: action.payload.status,
        submitted: action.payload.submitted,
        reviews: action.payload.reviews,
        isEditMode: action.payload.isEditMode,
        proof_of_address_doc_type: action.payload.proof_of_address_doc_type,
        proof_of_address: action.payload.proof_of_address,
        proofOfAddressUrl: action.payload.proofOfAddressUrl,
        proof_of_funds_doc_type: action.payload.proof_of_funds_doc_type,
        proof_of_funds: action.payload.proof_of_funds,
        proofOfFundsUrl: action.payload.proofOfFundsUrl,
        countryOfBirth: action.payload.countryOfBirth,
        cityOfBirth: action.payload.cityOfBirth,
        stateOfBirth: action.payload.stateOfBirth,
        gender: action.payload.gender,
        currentCountry: action.payload.currentCountry,
        currentCity: action.payload.currentCity,
        post_code: action.payload.post_code,
        address: action.payload.address
      }
    }
  }
})

export const { setIdVerification } = createIdVerificationSlice.actions

export const createIdVerificationSelector = (
  state: RootState
): IdVerification => state.createIdVerificationReducer
export default createIdVerificationSlice.reducer
