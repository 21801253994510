import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface InvitedMembers {
  invitedMembers: object[]
  inviteToGroup: boolean
}

const initialState: InvitedMembers = {
  invitedMembers: [],
  inviteToGroup: true
}

export const invitedMembersSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setInvitedMembers: (state, action: PayloadAction<InvitedMembers>) => {
      return {
        ...state,
        invitedMembers: action.payload.invitedMembers,
        inviteToGroup: action.payload.inviteToGroup
      }
    }
  }
})

export const { setInvitedMembers } = invitedMembersSlice.actions

export const invitedMembersSelector = (state: RootState): InvitedMembers =>
  state.invitedMembersReducer
export default invitedMembersSlice.reducer
