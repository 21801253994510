import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DocumentSetup } from './documentSetup'
import { HomeAddressSetUp } from './homeAddressSetUp'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  showModalF,
  showModalSelector
} from '../../user/njangeeGroupModals/slices/showModalSlice'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { IsLoading } from '../fragments/isLoading'
import { ProofOfAddress } from './proofOfAddress'
import {
  createIdVerificationSelector,
  setIdVerification
} from '../../user/settings/slices/idVerificationSlice'
import { updateKYCData } from '../../user/settings/functions/kyc'

export const OnboardingSteps: React.FC = () => {
  const [onboardingStep, setOnboardingStep] = useState(1)
  const showModalState = useAppSelector(showModalSelector)
  const idVerification = useAppSelector(createIdVerificationSelector)
  const [loadingKYC, setLoadingKYC] = useState(true)
  const dispatch = useAppDispatch()
  const nextStep = (onboardingNumber: number): void => {
    setOnboardingStep(onboardingNumber)
  }
  const navigate = useNavigate()
  const dispatchModal = useAppDispatch()

  const onClickShowModal = (): void => {
    dispatchModal(
      showModalF({ ...showModalState, modalIndex: 0, showModal: true })
    )
  }
  const skipOnboarding = (): void => {
    onClickShowModal()
    navigate('/user')
  }

  useEffect(() => {
    setLoadingKYC(true)
    const getUserData = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const api = process.env.REACT_APP_API as string
        const jwtToken = user.signInUserSession.idToken.jwtToken as string

        const session = await axios.get(`${api}/session`, {
          headers: {
            Authorization: jwtToken
          }
        })
        const userId = session.data.data.id

        const response = await axios.get(
          `${api}/kyc-records/user/${userId as string}`,
          {
            headers: {
              Authorization: jwtToken
            }
          }
        )
        const kycData = response.data.data
        dispatch(setIdVerification(updateKYCData(idVerification, kycData)))
        if (
          !idVerification.isEditMode &&
          (kycData?.status === 'PENDING' || kycData?.status === 'SUCCESS')
        ) {
          onClickShowModal()
          navigate('/user')
        }
      } catch (error) {
      } finally {
        setLoadingKYC(false)
      }
    }

    void getUserData()
  }, [])
  return loadingKYC ? (
    <IsLoading />
  ) : (
    <>
      {onboardingStep === 1 && (
        <DocumentSetup skipOnboarding={skipOnboarding} nextStep={nextStep} />
      )}
      {onboardingStep === 2 && (
        <HomeAddressSetUp skipOnboarding={skipOnboarding} nextStep={nextStep} />
      )}
      {onboardingStep === 3 && <ProofOfAddress nextStep={nextStep} />}
    </>
  )
}
