import { convertStringToISODate } from '../../../common/helper/convertToDate'

export const updateKYCData = (
  objectState: IdVerification,
  dbData: Record<string, any>
): IdVerification => {
  if (dbData === undefined) {
    return objectState
  }
  return {
    ...objectState,
    document_type: dbData?.documentType,
    idNumber: dbData?.documentNumber,
    issued_date: convertStringToISODate(dbData?.issuedDate),
    expiration_date: convertStringToISODate(dbData?.expiryDate),
    frontIdUrl: dbData?.frontPhoto ?? '',
    backIdUrl: dbData?.backPhoto ?? '',
    id: dbData?.id,
    status: dbData?.status ?? '',
    reviews: dbData?.kycRecordReviews,
    countryOfBirth: dbData?.countryOfBirth ?? '',
    stateOfBirth: dbData?.stateOfBirth ?? '',
    cityOfBirth: dbData?.cityOfBirth ?? '',
    currentCountry: dbData?.currentCountry ?? '',
    currentCity: dbData?.currentCity ?? '',
    address: dbData?.address ?? '',
    gender: dbData?.gender ?? '',
    proof_of_address_doc_type: dbData?.proofOfAddressDocType,
    proof_of_funds_doc_type: dbData?.proofOfFundsDocType,
    proofOfAddressUrl: dbData?.proofOfAddress ?? '',
    proofOfFundsUrl: dbData?.proofOfFunds ?? ''
  }
}
